import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faCheckSquare,
  faCoffee,
  faBell,
  faCog,
  faSignOutAlt,
  faQuestion,
  faTimes,
  faParagraph,
  faCompass,
  faAdjust,
  faUser,
  faUmbrella,
  faAngry,
  faLaughWink,
  faSortUp,
  faSortDown,
  faKey,
  faMap,
  faStreetView,
  faPlus,
  faMinus,
  faExpand,
  faLink,
  faTree,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faTrash,
  faCheck,
  faForward,
  faBackward,
  faArrowLeft,
  faArrowRight,
  faFileAlt,
  faSearchLocation,
  faChevronLeft,
  faGripLinesVertical,
  faRedoAlt,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faBell,
  faCog,
  faSignOutAlt,
  faQuestion,
  faTimes,
  faParagraph,
  faCompass,
  faAdjust,
  faUser,
  faUmbrella,
  faAngry,
  faLaughWink,
  faSortUp,
  faSortDown,
  faKey,
  faMap,
  faStreetView,
  faPlus,
  faMinus,
  faExpand,
  faLink,
  faTree,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faTrash,
  faCheck,
  faForward,
  faBackward,
  faArrowLeft,
  faArrowRight,
  faFileAlt,
  faSearchLocation,
  faChevronLeft,
  faGripLinesVertical,
  faRedoAlt,
  faUndoAlt,
);
