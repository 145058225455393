import { SendToField } from '../components/modals'

export const validationActions = ({ onUndo, isUndoAvailable, tree, updateTree, removeTree, sendToField, onSkip = () => {}, numberOfTrees = 0, dismissModal, presentModal, saveDisabled, deleteHidden, disabled } = {}) => ([
  onUndo && {
    icon: 'undo-alt',
    id: 'undo',
    disabled: !isUndoAvailable,
    action: onUndo,
  },
  onUndo && 'separator',
  !deleteHidden && {
    icon: 'trash',
    id: 'trash',
    disabled: !numberOfTrees || disabled,
    action: () => removeTree(tree),
    color: 'red',
  },
  {
    icon: 'check',
    id: 'check',
    disabled: !numberOfTrees || saveDisabled || disabled,
    action: () => updateTree(tree),
    color: 'green',
  },
  {
    icon: 'file-alt',
    label: 'Send to field',
    id: 'sendtofield',
    disabled: !numberOfTrees || disabled,
    color: 'primary',
    action: () => presentModal({
      title: 'Send to field', 
      content: <SendToField key={`tree-send-to-field-modal-${tree.id}`} dismissModal={dismissModal} onSendToField={sendToField} tree={tree} /> 
    }),
  },
  {
    icon: 'arrow-left',
    id: 'previous',
    disabled: !numberOfTrees || disabled,
    action: onSkip(-1),
  },
  {
    icon: 'arrow-right',
    id: 'next',
    disabled: !numberOfTrees || disabled,
    action: onSkip(1),
  },
]);