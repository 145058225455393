import React, { useState, memo, useEffect, useRef } from 'react';
import Icon from './Icon';
import { useConfig } from '../providers/config';

const generateRow = (config, onClick, wrapper) => (entry, index) => {
  const columns = config.columns || [];
  const isSelected = (config.selection && config.selection(entry) || false);

  const _handleClick = () => onClick && onClick(entry, index)
  return (
    <Row key={entry.id} wrapper={wrapper} index={index} selected={isSelected} onClick={_handleClick}>
      {
        columns.map(column => <Cell { ...column } column={column} entry={entry} key={`${entry.id}-${column.key}`} />)
      }
    </Row>
  )
}

export const sorter = (data, sortDir, column, getValueByResolver) => {
  if (column && data) {
    const numberSorter = (a, b) => parseInt(a) - parseInt(b);
    const stringSorter = (a, b) => String(a).localeCompare(String(b));

    const sorter = (a, b) => {
      const valueA = getValueByResolver(a, column.resolver);
      const valueB = getValueByResolver(b, column.resolver);

      const areBothNumbers = (!isNaN(parseInt(valueA)) && !isNaN(parseInt(valueB)));
      const selectedSorter = areBothNumbers ? numberSorter : stringSorter;

      return selectedSorter(valueA, valueB) * sortDir;
    };

    return [...data].sort(sorter)
  } else {
    return data || [];
  }
}

const Table = ({ data, config = {}, onClick = null, preventAutoFocus }) => {
  const [sort, setSort] = useState({ })
  const columns = config.columns || [];
  const init = useRef(false);
  const wrapper = useRef(null);

  const _handleSort = (column) => {
    if (sort[column] === -1) return setSort({ });
    if (sort[column] === 1) return setSort({ [column]: -1 });
    setSort({ [column]: 1 });
  }

  const sortKey = Object.keys(sort)[0];
  const sortDir = sort[sortKey];
  const column = columns.find(column => column.key === sortKey);

  const localData = data.filter(config.filter || (() => true));

  useEffect(() => {
    if (localData.length !== 1 || !init.current) return init.current = true;;

    if (!preventAutoFocus) onClick?.(localData[0]);
  }, [localData.length, localData[0]]);

  useEffect(() => {
    init.current = true;
  },[])

  return (
    <div className="table-wrapper" ref={wrapper}>
      <table cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            {
              columns.map((column, i) => <Title {...column} onSort={_handleSort} sort={sort} key={column.key} id={column.key} />)
            }
          </tr>
        </thead>
        <tbody style={{ position: 'relative' }}>
          {
            (localData.length > 0 || data.length === 0) ? 
            sorter(localData, sortDir, column, Object.byResolver).map(generateRow(config, onClick, wrapper)) : 
            <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%'}}>No results...</span> 
          }
        </tbody>
      </table>
    </div>
  )
}

const Row = memo(({ children, selected, onClick, index, wrapper }) => {
  useEffect(() => {
    if (selected) wrapper.current.scrollTop = index * 49; //alert(`Scrolling to: ${index}`)
  }, [selected]);
  return (
    <tr onClick={onClick || (() => {})} className={`${selected ? 'selected' : ''} ${onClick ? 'clickable' : ''}`}>
      { children }
    </tr>
  )
})

const Status = ({ status = '' }) => {
  const { getConfig } = useConfig();
  const localStatus = getConfig(`statuses.${status}`);

  return (
    <div className="status-wrapper">
      <div className={`status-icon ${status}`} style={{ background: getConfig(`colors.${localStatus?.color}`) }} />
      <span className="label">{ localStatus?.label }</span>
    </div>
  )
}

const IconButton = ({ icon, onClick = () => {}, entry }) => {
  const _handleClick = () => onClick(entry);
  return (
    <button onClick={_handleClick}>
      <Icon icon={icon} />
    </button>
  )
}

const dateFormatter = (date = null) => {
  if (!date) return '-';
  date = new Date(date);

  return `${date.getDate()}./${date.getMonth() + 1}./${date.getFullYear()}.`;
}

const formatter = (format = 'TEXT', value = '', column = {}, entry) => {
  switch (format) {
    case 'TITLE':
      return <span className="title">{ String(value) }</span>
    case 'DATE':
      return <span className="date">{ dateFormatter(value) }</span>
    case 'STATUS':
      return <Status status={value} />
    case 'ICONBUTTON':
      return <IconButton { ...column} entry={entry} />
    case 'CODE':
      return <pre className="code">{ String(value) }</pre>
    case 'PERCENTAGE':
      return <span className="value percentage">{ (Math.round(value * 1000) / 10).toFixed(1) }%</span>
    default:
      return <span className="value text">{ String(value) }</span>
  }
}

const Cell = memo(({ entry, resolver = () => null, format, column }) => {
  const value = Object.byResolver(entry, resolver);
  return (
    <td>{ formatter(format, value, column, entry) }</td>
  )
})

const Title = memo(({ title, onSort, id, sort, sortable }) => {
  const _handleAction = () => sortable && onSort(id)
  return (
    <th onClick={_handleAction}>
      <div className="title-wrapper">
        { title }
        <div className={`sort-wrapper`}>
          <Icon icon={sort[id] ? (sort[id] > 0 ? 'sort-up' : 'sort-down') : null} />
        </div>
      </div>
    </th>
  )
})

export default memo(Table);