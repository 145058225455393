import React, { createContext, useContext, useState } from 'react';

const STORAGE_KEY = '@api_key_v2';

export const Context = createContext({ });

// Access to context
export const useUser = () => useContext(Context);
export const withUser = (Component) => props => <Context.Consumer>{user => <Component { ...props } user={user} />}</Context.Consumer>;

export const Provider = (props) => {
  const [config, setConfig] = useState({ token: localStorage.getItem(STORAGE_KEY) || null });

  const _handleSetApitoken = (token) => {
    setConfig({ ...config, token });
    if (token) localStorage.setItem(STORAGE_KEY, token)
    else localStorage.removeItem(STORAGE_KEY)
  }

  return (
    <Context.Provider value={{ ...config, setApiToken: _handleSetApitoken }}>
      { props.children }
    </Context.Provider>
  )
};