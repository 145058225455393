import React from 'react';

const Popup = ({ children, title, direction = 'bottom' }) => {
  return (
    <div className={`popup-wrapper ${direction}`}>
      { children }
      {
        title && (
          <div className="popup">
            {
              title.split(/\[BR\]/g).map((line, i) => <span key={i} className='line'>{ line }</span>)
            }
          </div>
        )
      }
    </div>
  )
}

export default Popup;
