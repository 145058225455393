import React from 'react';
import { Header, VerticalTabs } from '../components';
import {
  Switch,
  Route
} from 'react-router';
import GridHOC from './GridHOC';

const Skeleton = ({ children, tabs = [], header = {} }) => {
  return (
    <div className="skeleton-outer-wrapper">
      <Header { ...header } />
      <div className={`skeleton-wrapper ${tabs.length > 0 ? 'tabs-visible' : 'tabs-hidden'}`}>
        { tabs.length > 1 && <VerticalTabs tabs={tabs}/> }
        <div className="skeleton-content">
          {
            tabs.filter(tab => tab && (tab.path && tab.component)).map(route => <Route path={route.path} exact={[...(route.path || '')].pop() === '/'} component={route.component} />)
          }
          {
            children
          }
        </div>
      </div>
    </div>
  );
}

export default Skeleton;