import React, { useState, useEffect, useRef } from 'react';
import { SemanticInfoSelector, ProgressControls, SemanticEditor, Finished } from '../../components';
import { validationActions } from '../../core/progressActions';
import * as THREE from 'three';
import LASLoader from '../../utils/LASLoader';
import { useModal } from '../../providers/modal';
import { useTrees } from '../../providers/data';
import { useTheme } from '../../providers/theme';
import { Grid } from '../../layout';
import { useConfig } from '../../providers/config';
import proj4 from 'proj4';
import { useActionStack } from '../../core';

// Loader
const loadingManager = new THREE.LoadingManager();
const loader = new LASLoader(loadingManager);
const generateUrl = (id) => window.location.pathname.split('/').slice(0, 4).join('/') + '/' + id;

const round = (val) => Math.round(val * 100) / 100;

const calculateGirth = girth => 2 * Math.PI * Math.sqrt((Math.pow(girth.rX, 2) + Math.pow(girth.rY, 2)) / 2);

const parseGirth = (val) => {
  const radius = (val|| 2) / (2 * Math.PI);
  return { dX: 0, dY: 0, rX: radius, rY: radius, rotation: 0, diameter: null };
}

const SemanticsValidation = ({ trees, loaded, match, startPostValidation, selection, updateTree, setCurrentTreeId }) => {
  const { getConfig } = useConfig();
  // Default states
  const [finished, setFinished] = useState(false);
  const [height, setHeight] = useState(3);
  const heightPrev = useRef(null);
  const heightTimeout = useRef(null);
  const heightHandler = val => {
    if (!heightPrev.current) heightPrev.current = val;
    clearTimeout(heightTimeout.current);
    heightTimeout.current = setTimeout(() => {
      onAction('height', null, heightPrev.current);
      heightPrev.current = val;
    }, 240);
    setHeight(val);
  }
  const [canopyHeight, setCanopyHeight] = useState(1);
  const canopyHeightPrev = useRef(null);
  const canopyHeightTimeout = useRef(null);
  const canopyHeightHandler = val => {
    if (!canopyHeightPrev.current) canopyHeightPrev.current = val;
    clearTimeout(canopyHeightTimeout.current);
    canopyHeightTimeout.current = setTimeout(() => {
      onAction('canopyHeight', null, canopyHeightPrev.current);
      canopyHeightPrev.current = val;
    }, 240);
    setCanopyHeight(val);
  }
  const [trunkHeight, setTrunkHeight] = useState(0.7);
  const trunkHeightPrev = useRef(null);
  const trunkHeightTimeout = useRef(null);
  const trunkHeightHandler = val => {
    if (!trunkHeightPrev.current) trunkHeightPrev.current = val;
    clearTimeout(trunkHeightTimeout.current);
    trunkHeightTimeout.current = setTimeout(() => {
      onAction('trunkHeight', null, trunkHeightPrev.current);
      trunkHeightPrev.current = val;
    }, 240);
    setTrunkHeight(val);
  }
  const [girth1, setGirth1] = useState({ dX: 0, dY: 0, rX: 1, rY: 2, rotation: 0, diameter: null });
  const girth1Prev = useRef(null);
  const girth1Timeout = useRef(null);
  const girth1Handler = val => {
    if (!girth1Prev.current) girth1Prev.current = val;
    clearTimeout(girth1Timeout.current);
    girth1Timeout.current = setTimeout(() => {
      onAction('girth1', null, girth1Prev.current);
      girth1Prev.current = val;
    }, 240);
    setGirth1(val);
  }
  const [girth13, setGirth13] = useState({ dX: 0, dY: 0, rX: 1.4, rY: 2.2, rotation: 0.1, diameter: null });
  const girth13Prev = useRef(null);
  const girth13Timeout = useRef(null);
  const girth13Handler = val => {
    if (!girth13Prev.current) girth13Prev.current = val;
    clearTimeout(girth13Timeout.current);
    girth13Timeout.current = setTimeout(() => {
      onAction('girth13', null, girth13Prev.current);
      girth13Prev.current = val;
    }, 240);
    setGirth13(val);
  }
  const [canopy, setCanopy] = useState({ dX: 0, dY: 0, rX: 1.8, rY: 2.2, rotation: 0.1, diameter: null });
  const canopyPrev = useRef(null);
  const canopyTimeout = useRef(null);
  const canopyHandler = val => {
    if (!canopyPrev.current) canopyPrev.current = val;
    clearTimeout(canopyTimeout.current);
    canopyTimeout.current = setTimeout(() => {
      onAction('canopy', null, canopyPrev.current);
      canopyPrev.current = val;
    }, 240);
    setCanopy(val);
  }

  const [position, setPosition] = useState([]);
  const [editing, setEditing] = useState('height');
  const [las, setLas] = useState(null);
  const selectedTree = match.params.tree;
  const { isDark } = useTheme();

  const [currentIndex, setCurrentIndex] = useState(0);
  const init = useRef(false);
  const { presentModal, dismissModal } = useModal();
  
  const todos = trees.filter(tree => tree.status === 'semantic_validation_todo').sort((a, b) => parseFloat(b.location_confidence || 0) - parseFloat(a.location_confidence || 0)).map((tree, index ) => ({ ...tree, index }));
  const currentTree = todos[currentIndex % todos.length];

  const _handleUndo = (action, target, from, to) => {
    if (action === 'SKIP') return setCurrentIndex(from);
    if (action === 'UPDATE') return updateTree(target, from);
    if (action === 'POSITION') return setPosition(from);
    setEditing(action);
    if (action === 'height') setHeight(from);
    if (action === 'canopyHeight') setCanopyHeight(from);
    if (action === 'trunkHeight') setTrunkHeight(from);
    if (action === 'girth1') setGirth1(from);
    if (action === 'girth13') setGirth13(from);
    if (action === 'canopy') setCanopy(from);
  };
  const { onAction, onUndo, isUndoAvailable } = useActionStack(_handleUndo);

  const _handleLasLoad = () => {
    setLas(null);
    setEditing('height');
    const url = `${(window._env_.REACT_APP_SEMANTICS_LAS || process.env.REACT_APP_SEMANTICS_LAS).replace('__MA__', selection[0].code).replace('__TREE__', currentTree?.id)}`;
    loader.load(url, pointcloud => setLas(pointcloud));
  }

  const _handleInit = () => {
    init.current = true;
    if (!selectedTree) window.history.pushState(null, null, generateUrl(currentTree?.id));
    else {
      const index = todos.findIndex(tree => String(tree.id) === String(selectedTree));
      if (index >= 0) setCurrentIndex(index);
    }
  }
 
  useEffect(() => {
    if (currentTree?.id) setCurrentTreeId(currentTree.id);
    if (!selection[0]?.code) return;
    if (currentTree?.id && !init.current) _handleInit();
    else if (currentTree?.id && init.current) window.history.pushState(null, null, generateUrl(currentTree?.id));
    if (currentTree?.id) {
      _handleLasLoad();
      _handleTreeLoaded();
    }

    if (currentTree?.location) {
      const location = currentTree?.location?.coordinates.slice(0);
      const position = proj4("EPSG:4326", "EPSG:3414", location || []);
      setPosition(position);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTree?.id, selection[0]?.code])


  const _handleTreeLoaded = () => {
    const tree = currentTree;

    setHeight(tree.height);
    setCanopyHeight(tree.crown_height);
    setTrunkHeight(tree.trunk_height);
    
    setGirth1(parseGirth(tree.girth_1_0m));
    setGirth13(parseGirth(tree.girth_1_3m));

    setCanopy({ dX: tree.crown_excentricity?.[0] || 0, dY: tree.crown_excentricity?.[1] || 0, rX: tree.canopy_ellipse_a || 1.8, rY: tree.canopy_ellipse_b || 2.2, rotation: tree.canopy_ellipse_direction || 0.1, diameter: null })
    // const [canopy, setCanopy] = useState({ dX: 0, dY: 0, rX: 1.8, rY: 2.2, rotation: 0.1, diameter: null });
  }

  const _handleSave = (tree) => {
    const girth_1_0m = calculateGirth(girth1);
    const girth_1_3m = calculateGirth(girth13);
    updateTree(tree.id, { 
      status: 'semantic_validation_done',
      girth_1_0m,
      girth_1_3m,
      height,
      crown_height: canopyHeight,
      trunk_height: trunkHeight,
      canopy_ellipse_a: canopy.rX,
      canopy_ellipse_b: canopy.rY,
      canopy_ellipse_direction: canopy.rotation,
      crown_excentricity: [canopy.dX, canopy.dY],
    });
  }
  const _handleRemove = (tree) => {
    if (!tree) return;
    onAction('UPDATE', tree?.id, { status: 'semantic_valudation_todo' });
    updateTree(tree.id, { status: 'deleted' });
  }
  const _handleField = (tree, comment) => {
    if (!tree) return;
    onAction('UPDATE', tree?.id, { status: 'semantic_valudation_todo' });
    updateTree(tree.id, { status: 'sent_to_field', comment });
  }
  const _handleSkip = (direction = 1) => () => {
    onAction('SKIP', null, currentIndex);
    setCurrentIndex((currentIndex + direction + trees.length) % trees.length);
  }

  const _hanleSemanticEditingChange = (editing) => setEditing(editing);

  const infoContent = [
    {
      title: 'Tree Semantics',
      key: 'semantics',
      active: editing,
      onClick: _hanleSemanticEditingChange,
      data: [
        {
          label: 'Height',
          id: 'height',
          value: `${round(height || currentTree?.height) || '–'} m`,
          horizontal: true,
          // onChange: val => setHeight(parseFloat(val) || height),
        },
        {
          label: 'First bifurcation',
          id: 'trunkHeight',
          value: `${round(trunkHeight || currentTree?.trunk_height) || '–'} m`,
          horizontal: true,
          // onChange: val => setTrunkHeight(parseFloat(val) || trunkHeight),
        },
        {
          label: 'Crown height',
          id: 'canopyHeight',
          value: `${round(canopyHeight || currentTree?.crown_height) || '–'} m`,
          horizontal: true,
          // onChange: val => setCanopyHeight(parseFloat(val) || canopyHeight),
        },
        {
          label: 'Girth',
          id: 'girth1',
          value: `${round(calculateGirth(girth1 || currentTree?.girth_1_0m)) || '–'} m`,
          horizontal: true,
          // onChange: val => setGirth1(parseGirth(parseFloat(val) || 2))
        },
        // {
        //   label: 'Girth – 1.3 m',
        //   id: 'girth13',
        //   value: `${round(calculateGirth(girth13 || currentTree?.girth_1_3m)) || '–'} m`,
        //   horizontal: true,
        //   // onChange: val => setGirth13(parseGirth(parseFloat(val) || 2))
        // },
        {
          label: 'Canopy',
          id: 'canopy',
          value: `${round(calculateGirth(canopy)) || '–'} m`,
          horizontal: true,
        },
      ],
    },
  ];

  const _handleTSEJob = () => {
    startPostValidation(selection[0]?.code, 'semantic_validation');
    setFinished(true);
  }

  return (
    <Finished finished={todos?.length === 0 && loaded} onStart={_handleTSEJob} done={finished}>
      <Grid 
        tabcontent={<SemanticInfoSelector content={infoContent} />}
      >
        <div className="semantics-editor-wrapper">
          <div className="viewers">
            <SemanticEditor 
              loading={las === null}
              background={isDark ? 0x000000 : 0xf8f8f8}
              pointcloud={las} 
              position={position} 
              onPositionChange={setPosition}  
              height={height}
              trunkHeight={trunkHeight}
              canopyHeight={canopyHeight}
              girth1={girth1}
              girth13={girth13}
              canopy={canopy}
              onHeightChange={heightHandler}
              onTrunkHeightChange={trunkHeightHandler}
              onCanopyHeightChange={canopyHandler}
              onGirth1Change={girth1Handler}
              onGirth13Change={girth13Handler}
              onCanopyChange={canopyHandler}            
              editing={editing}
              getConfig={getConfig}
              isDark={isDark}
            />
          </div>
          <ProgressControls
            max={trees?.length}
            value={trees.length - todos.length} 
            actions={validationActions({
              onUndo,
              isUndoAvailable,
              tree: currentTree, 
              updateTree: _handleSave, 
              removeTree: _handleRemove, 
              sendToField: _handleField,
              onSkip: _handleSkip, 
              numberOfTrees: todos.length, 
              dismissModal, 
              presentModal,
            })} 
          />
        </div>
      </Grid>
    </Finished>
  )
}

export default SemanticsValidation;