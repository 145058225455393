import * as THREE from "three";
import { LASFile } from "./laslaz.js";
import FilterPointsMaterial from './FilterPointsMaterial';

class PointCloud {
  constructor(key, points, pointCount, scale, offset, mins, maxs, filterPoints) {
    this.key = key;
    this.points = points;
    this.pointCount = pointCount;
    this.scale = scale;
    this.offset = offset;
    this.mins = mins;
    this.maxs = maxs;
    this.colorSpecified = false;
    this.filterPoints = filterPoints;
  }
}

class GeometryProcessor {
  constructor() {
    this.mx = null;
    this.mn = null;
    this.z_max = null;
    this.z_min = null;
    this.config = {};
  }

  getColorClassification(p) {
    return null;
  }

  static gradient = [
    [0, [0, 0, 255]],
    [0.25, [0, 255, 0]],
    [0.5, [255, 255, 0]],
    [0.75, [255, 0, 0]],
    [1, [255, 255, 255]],
  ];

  static pickHex(color1, color2, weight) {
    const w1 = weight;
    const w2 = 1 - w1;
    const rgb = [Math.round(color1[0] * w1 + color2[0] * w2) / 255.0,
                 Math.round(color1[1] * w1 + color2[1] * w2) / 255.0,
                 Math.round(color1[2] * w1 + color2[2] * w2) / 255.0];
    return rgb;
  }

  processGeometry(pointCloud) {
    // this.pointClouds.set(pointCloud.key, pointCloud);
    const geometry = new THREE.BufferGeometry();
    let count = pointCloud.pointCount;
    let classified = false;

    // console.log(pointCloud);

    if (typeof this.config.loadUnclassified !== 'undefined' && !this.config.loadUnclassified) {
      let classifiedCount = 0;
      for (let i = 0; i < count; i++) {
        let p = null;
        if (typeof pointCloud.points === 'function') p = pointCloud.points(i);
        else p = pointCloud.points[i];

        const colorClass = this.getColorClassification(p);
        if (colorClass === 'canopy' || colorClass === 'branch') {
          classifiedCount++;
          classified = true;
        }
      }
      count = classifiedCount;
      if (!classified) {
        count = pointCloud.pointCount;
      }
    }

    const positions = new Float32Array(count*3);
    const colors = new Float32Array(count*3);
    const intensity = new Float32Array(count);
    const classification = new Float32Array(count);

    pointCloud.colorSpecified = false;

    const corrective = new THREE.Vector3(
      pointCloud.mins[0] /* - Math.abs(pointCloud.mins[0] - pointCloud.maxs[0]) */,
      pointCloud.mins[1] /* - Math.abs(pointCloud.mins[1] - pointCloud.maxs[1]) */,
      pointCloud.mins[2] /* - Math.abs(pointCloud.mins[2] - pointCloud.maxs[2]) */,
    );

    let i = 0;
    for (let pointIndex = 0; pointIndex < pointCloud.pointCount; pointIndex++) {
      let p = null;
      if (typeof pointCloud.points === 'function') p = pointCloud.points(pointIndex);
      else p = pointCloud.points[pointIndex];
      const colorClass = this.getColorClassification(p);
      if (colorClass === 'canopy' || colorClass === 'branch') pointCloud.classificationColor = true;
      if (typeof this.config.loadUnclassified !== 'undefined' && !this.config.loadUnclassified &&  classified && colorClass !== 'canopy' && colorClass !== 'branch') continue;

      let x = p.position[0] * pointCloud.scale[0] + pointCloud.offset[0];
      let y = p.position[1] * pointCloud.scale[1] + pointCloud.offset[1];
      let z = p.position[2] * pointCloud.scale[2] + pointCloud.offset[2];

      if (this.mx === null) {
        this.mx = new THREE.Vector3(x, y, z);
      } else {
        this.mx.set(
          Math.max(this.mx.x, x),
          Math.max(this.mx.y, y),
          Math.max(this.mx.z, z)
        );
      }

      if (this.mn === null) {
        this.mn = new THREE.Vector3(x, y, z);
      } else {
        this.mn.set(
          Math.min(this.mn.x, x),
          Math.min(this.mn.y, y),
          Math.min(this.mn.z, z)
        );
      }

      let color;
      if (pointCloud.classificationColor) color = this.getColor(colorClass, pointCloud);
      else {
        const index = GeometryProcessor.gradient.findIndex(([rangeStart]) => p.intensity / 65536 < rangeStart);
        const minColor = GeometryProcessor.gradient[index - 1];
        const maxColor = GeometryProcessor.gradient[index];
        color = GeometryProcessor.pickHex(minColor[1], maxColor[1], 1 - (p.intensity / 65535 - minColor[0]) / (maxColor[0] - minColor[0]));
        pointCloud.colorSpecified = true;
      }

      if (this.config.corrective) {
        positions[3 * i] = x - corrective.x;
        positions[3 * i + 1] = y - corrective.y;
        positions[3 * i + 2] = z - corrective.z;
      } else {
        positions[3 * i] = x;
        positions[3 * i + 1] = y;
        positions[3 * i + 2] = z;
      }

      if (colorClass === 'canopy' || colorClass === 'branch') {
        this.z_max =
          this.z_max === null
            ? positions[3 * i + 2]
            : Math.max(this.z_max, positions[3 * i + 2]);
        this.z_min =
          this.z_min === null
            ? positions[3 * i + 2]
            : Math.min(this.z_min, positions[3 * i + 2]);
      }

      colors[3 * i] = color[0];
      colors[3 * i + 1] = color[1];
      colors[3 * i + 2] = color[2];

      intensity[i] = p.intensity;
      classification[i] = p.classification;

      i++;
    }

    if (positions.length > 0) {
      geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(positions, 3)
      );
    }
    if (colors.length > 0 && pointCloud.colorSpecified) {
      geometry.setAttribute(
        'color',
        new THREE.BufferAttribute(colors, 3)
      );
    }
    if (intensity.length > 0) {
      geometry.setAttribute(
        'intensity',
        new THREE.BufferAttribute(intensity, 1)
      );
    }
    // if (classification.length > 0) {
    //   geometry.setAttribute(
    //     'classification',
    //     new THREE.BufferAttribute(classification, 1)
    //   );
    // }
    geometry.computeBoundingSphere();

    return geometry;
  }

  getColor(colorClass, pointCloud) {
    var r, g, b;
    if (colorClass === 'canopy') {
      r = 72 / 255.0;
      g = 187 / 255.0;
      b = 120 / 255.0;
      pointCloud.colorSpecified = true;
    } else if (colorClass === 'branch') {
      r = 121 / 255.0;
      g = 85 / 255.0;
      b = 72 / 255.0;
      pointCloud.colorSpecified = true;
    } else {
      r = 41 / 255.0;
      g = 51 / 255.0;
      b = 54 / 255.0;
    }
    return [r, g, b];
  }

  setConfig(config) {
    this.config = {
      ...this.config,
      ...config,
    };
  }
}

class LASProcessor extends GeometryProcessor {
  constructor(loader) {
    super();
    this.loader = loader;
    this.config = {
      ...super.config,
      corrective: true,
      loadUnclassified: true,
      pointsMaterial: {
        size: 0.05,
      },
    };
  }

  /**
   * Merge config for material and color classification
   *
   * @param {*} config The configuration object
   */
  setConfig(config) {
    super.setConfig(config);
    this.config = {
      ...this.config,
      ...config,
    };
  }

  parse(data, path, onLoad, onError) {
    let lasFile = new LASFile(data);
    let lasHeader;
    return lasFile
      .open()
      .then(function () {
        lasFile.isOpen = true;
        return lasFile;
      })
      .then(
        (lasFile) => {
          return lasFile.getHeader().then(function (h) {
            return [lasFile, h];
          });
        }
      )
      .then(
        (v) => {
          lasFile = v[0];
          lasHeader = v[1];
          
          return lasFile.readData(lasHeader.pointsCount, 0, 1);
        }
      )
      .then(
        ({ buffer, count }) => {
          const Unpacker = lasFile.getUnpacker();
          const decoder = new Unpacker(buffer, count, lasHeader);

          // console.log(buffer, count, lasHeader);
          const pcAndMesh = this.processLAS(decoder);

          onLoad(pcAndMesh);
        }
      );
  }

  getColorClassification(p) {
    if (p.classification === 21) return 'canopy';
    else if (p.classification === 20) return 'branch';
    else return null;
  }

  processLAS(lasBuffer) {
    const pc = new PointCloud(
      'pc',
      i => lasBuffer.getPoint(i),
      lasBuffer.pointsCount,
      lasBuffer.scale,
      lasBuffer.offset,
      lasBuffer.mins,
      lasBuffer.maxs,
    );
    const geometry = this.processGeometry(pc);
    const three = THREE;
    const createMaterial = (THREE = three, size = 0.5, opacity = 1.0) => {
      const material = new THREE.PointsMaterial({ size, opacity });

      material.clipIntersection = false;

      if (pc.colorSpecified) material.vertexColors = true;
      else material.color.setHex(0xf8f8f8);

      material.userData.setSize = (newSize) => {
        material.size = newSize ? (size * newSize) : size;
        material.needsUpdate = true;
      }

      material.userData.defaultSize = size;

      material.localClippingEnabled = true;

      material.userData.filterPoints = (coords) => {
        if (!coords) return material.clippingPlanes = [];
        const { minZ, maxZ } = coords;
        material.clippingPlanes = [new THREE.Plane(new THREE.Vector3(0, 0, 1), -minZ), new THREE.Plane(new THREE.Vector3(0, 0, -1), maxZ)];
      }

      return material;
    }

    return {
      pc: pc,
      geometry: geometry,
      material: createMaterial(),
      name: this.lasName,
      createMaterial,
    };
  }
}

class LoaderBase extends THREE.Loader {
  constructor(manager, responseType) {
    super(manager);
    this.manager = manager;
    this.responseType = responseType;
  }

  /**
   * Set GeometryProcessor accessor method
   *
   * @param {*} processor The geometry processor instance
   */
  setProcessor(processor) {
    this.processor = processor;
  }

  /**
   * Get GeometryProcessor accessor method
   *
   * @returns The geometry processor instance
   */
  getProcessor() {
    return this.processor;
  }

  load(url, onLoad, onProgress, onError) {
    var resourcePath;

    if (this.resourcePath !== '') resourcePath = this.resourcePath;
    else if (this.path !== '') resourcePath = this.path;
    else resourcePath = THREE.LoaderUtils.extractUrlBase(url);

    // Tells the LoadingManager to track an extra item, which resolves after
    // the model is fully loaded. This means the count of items loaded will
    // be incorrect, but ensures manager.onLoad() does not fire early.
    this.manager.itemStart(url);

    const _onError = (e) => {
      if (onError) onError(e);
      else console.error(e);

      this.manager.itemError(url);
      this.manager.itemEnd(url);
    };

    const loader = new THREE.FileLoader(this.manager);

    loader.setPath(this.path);
    loader.setResponseType(this.responseType);
    loader.setRequestHeader(this.requestHeader);
    loader.setWithCredentials(this.withCredentials);

    loader.load(
      url,
      (data) => {
        try {
          this.processor.parse(
            data,
            resourcePath,
            (result) => {
              onLoad(result);
              this.manager.itemEnd(url);
            },
            _onError
          );
        } catch (e) {
          _onError(e);
        }
      },
      onProgress,
      _onError
    );
  }

  setConfig(config) {
    this.config = {
      ...this.config,
      config
    }
  }
}

export default class LASLoader extends LoaderBase {
  constructor(manager) {
    super(manager, 'arraybuffer');
    this.setProcessor(new LASProcessor(this));
    this.manager = manager;
  }
}
