import React, { useRef } from 'react';

const Toggle = ({ label, value, onChange = () => {}, onPureChange = () => {}, name, disabled }) => {
  const _input = useRef(null);
  const _handleClick = () => _input.current && _input.current.focus();
  const _handleChange = ({ target: { value }}) => {
    onPureChange(value);
    onChange({ name, value: value })
  }

  return (
    <div className={`color-input-wrapper regular-button-states ${disabled ? 'disabled' : ''} ${value ? 'active' : 'inactive'}`} onClick={_handleClick}>
      <span className="label">{label}</span>
      <div className="color" style={{ background: value }}></div>
      <input type="color" onChange={_handleChange} ref={_input} value={value} />
    </div>
  )
}

export default Toggle;
