import React, { createContext, useContext, useState, useEffect } from 'react';
import { useError } from './error';
import { useHistory } from 'react-router-dom';
import { useUser } from './user';

export const Context = createContext({ });

// Access to context
export const useData = () => useContext(Context);
export const withData = (Component) => props => <Context.Consumer>{theme => <Component { ...props } theme={theme} />}</Context.Consumer>;

// Constants
const SRID = 4326;

export const getCapturePoint = async (tree, token) => {
  if (!tree) return;
  const location = tree.location.coordinates;
  const res = await fetch(`${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/v1/capture_point/${location[0]},${location[1]},${SRID}8?key=${token}`);
  const data = await res.json();

  return Array.isArray(data) ? data : [];
}

export const Provider = (props) => {
  const [data, setData] = useState({});
  const { token } = useUser();
  const { managedAreas = [], pipelines = [], loaded = false } = data;
  
  const URLS = [
    `${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/v1/query/managed_areas?columns=id,description,code,frontend_aoi_bbox&key=${token}`,
    `${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/v1/pipelines/get?key=${token}`,
  ]
  const _handleLoad = async () => {
    const [managedAreas, pipelines] = await Promise.all((await Promise.all(URLS.map(url => fetch(url)))).map(res => res.json()))

    if (managedAreas?.statusCode === 401 || pipelines?.statusCode === 401) return;

    setData({ managedAreas: Array.isArray(managedAreas) ? managedAreas : [], pipelines: Array.isArray(pipelines) ? pipelines : [], loaded: true });
  }
  
  const _handleManagedArea = (id) => managedAreas?.find(area => String(area.id) === String(id)) || null;
  const _handlePipeline = (id) => pipelines?.find(pipeline => String(pipeline.id) === String(id)) || null;
  
  const _handlePostValidation = (code, step) => {
    fetch(`${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/v1/pipelines/start-post-validation?done_step=${step}&managed_area=${code}&key=${token}`, { method: 'POST' });
    const index = data?.pipelines?.findIndex(pipeline => pipeline.code === code);
    if (data?.pipelines?.[index]) {
      const pipelines = [...data.pipelines];
      pipelines[index].current_manual_step = step === 'db_match' ? 'completed' : `${step}_done`;
      pipelines[index].processing = true;
      setData({ ...data, pipelines });
    }
  }

  useEffect(() => {
    _handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Context.Provider value={{ 
      managedAreas,
      getManagedArea: _handleManagedArea,
      pipelines,
      loaded,
      getPipeline: _handlePipeline,
      startPostValidation: _handlePostValidation,
    }}>
      { props.children }
    </Context.Provider>
  )
};

const TREE_FIELDS = {
  'id': 'id',
  'managed_area': 'manager_area',
  'comment': 'comment',
  'managed_area_id': 'managed_area_id',
  'status': 'status',
  'location_confidence': 'lcoation_confidence',
  'height': 'height',
  'trunk_height': 'trunkHeight',
  'crown_height': 'crownHeight',
  'girth_1_0m': 'girth1',
  'girth_1_3m': 'girth13',
};

export const useTrees = (managedArea, cb = () => {}) => {
  const [trees, setTrees] = useState([]);
  const [loaded, setLoaded] = useState(null);
  const history = useHistory();
  const { token } = useUser();

  const _handleLoad = async (managedArea) => {
    if (!managedArea) return;
    setLoaded(false);
    try {
      const res = await fetch(`${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/v1/query/trees?columns=${Object.keys(TREE_FIELDS).join()}&filter=managed_area_id%3D${managedArea?.id || managedArea}&transform_columns=location&key=${token}&q=${new Date().getTime()}`).then(res => res.json());
      const trees = res.map(tree => ({ ...tree, location: JSON.parse(tree.location || '{}'), geometry: JSON.parse(tree.location || '{}') }));
      setTrees(trees);
      setLoaded(true);
      console.log('TREES', trees)
      cb(trees);
    } catch (error) {
      console.log('Failed to load trees: ', error)
    }
    // setTrees([]);
  }

  const updateTree = (id, change) => {
    if (!Array.isArray(id)) return _handleTreeUpdate(id, change);
    else {
      const _trees = [...trees];
      id.forEach(tree => {
        const id = tree?.id || tree;
        const index = _trees.findIndex(tree => String(tree.id) === String(id));
        const _change = { ...change };
        delete _change.location;
        _handleTreeUpdate(id, change, true);
        _trees[index] = { ..._trees[index], ..._change };
      });
      console.log('BULK UPDATE: ', id, _trees)
      setTrees(_trees);
    }
  }

  const _handleTreeUpdate = (id, change = {}, preventUpdate) => {
    const _trees = [...trees];
    const index = _trees.findIndex(tree => String(tree.id) === String(id));
    if (!_trees[index]) return;

    try {
      fetch(`${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/v1/trees/update?id=${id}&key=${token}`, { 
        method: 'PATCH',
        body: JSON.stringify(Object.keys(change).reduce((prev, key) => change[key] ? { ...prev, [key]: change[key] } : prev, {})),
        headers: { 'Content-Type': 'application/json' }
      });
    } catch (e) {
      console.log('failed to save changes')
    }

    delete change.location;

    _trees[index] = { ..._trees[index], ...change };
    if (!preventUpdate) setTrees(_trees);
  }
  
  useEffect(() => {
    _handleLoad(managedArea);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedArea]);

  const _handleTreeAdd = async (e, managedArea) => {
    const { lng, lat } = e.lngLat;
    if (!managedArea) return;
    try { 
      const data = await fetch(`${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/v1/trees/${lng},${lat},${SRID}?managed_area=${managedArea?.code}&managed_area_id=${managedArea?.id}&key=${token}&q=${new Date().getTime()}`, { method: 'POST' });
      const [tree] = await data.json();

      tree.location = tree.location_wgs84;
      setTrees([...trees, tree]);

      setTimeout(() => history.push(`/validation/${managedArea?.id}/location/${tree?.id}?new`), 100);
    } catch (e) {
      console.warn('FAILED TO ADD NEW TREE: ', e);
    }
  }

  return { trees: trees || [], loaded, updateTree, getCapturePoint: tree => getCapturePoint(tree, token), onTreeAdd: _handleTreeAdd };
}

const MAVEN_FIELDS = [
  'id',
  'loc_cd',
  'common_nm',
  'grth_size',
  'height',
  'sent_to_field',
]

export const useMavenTrees = (managedArea, cb = () => {}) => {
  const [trees, setTrees] = useState([]);
  const [loaded, setLoaded] = useState(null);
  const { token } = useUser();

  const _handleLoad = async (managedArea) => {
    if (!managedArea) return;
    setLoaded(false);
    try {
      const res = await fetch(`${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/v1/query/maven_trees?columns=${MAVEN_FIELDS.join(',')}&filter=loc_cd%3D'${managedArea}'&transform_columns=geom&key=${token}&q=${new Date().getTime()}`).then(res => res.json());
      const trees = res.map(tree => ({ ...tree, location: JSON.parse(tree.geom || '{}'), geometry: JSON.parse(tree.geom || '{}'), girth_1_0m: tree.grth_size }));
      setTrees(trees);
      setLoaded(true);
      cb(trees);
    } catch (error) {
      console.warn('FAILED TO SET MAVENS: ', error)
    }
  }

  const _handleMavenTree = (id, change) => {
    const _trees = [...trees];
    const index = _trees.findIndex(tree => String(tree.id) === String(id));
    if (!_trees[index]) return;

    fetch(`${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/v1/maven_field?id=${id}&comment=${change.comment}&key=${token}&q=${new Date().getTime()}`, { method: 'PATCH' });

    _trees[index] = { ..._trees[index], ...change };
    setTrees(_trees);
  }

  useEffect(() => {
    _handleLoad(managedArea?.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedArea?.code]);

  return { trees: trees || [], loaded, updateTree: _handleMavenTree };
}

const INDIVIDUAL_TREE_FIELDS = [
  'id',
  'managed_area',
  'managed_area_id',
  'location',
  'location_confidence',
  'location_validation_confidence',
  'ml_instance_confidence',
  'height',
  'trunk_diameter',
  'trunk_height',
  'trunk_centroid',
  'trunk_leaning_vector',
  'trunk_frontal_area',
  'crown_height',
  'crown_width',
  'crown_direction',
  'crown_excentricity',
  'crown_centroid',
  'crown_frontal_area',
  'canopy_ellipse_a',
  'canopy_ellipse_b',
  'canopy_ellipse_direction',
  'first_bifurcation_point',
  'first_bifurcation_confidence',
  'girth_1_0m',
  'girth_1_3m',
  'girths',
  'voxels',
  'las_clip_path',
  'images_path',
  'status',
  'manual_treelocation_changes',
  'manual_semantic_changes',
  'comment',
  'source',
  'maven_id',
];

export const useTree = (treeid) => {
  const { token } = useUser();
  const [tree, setTree] = useState(null);

  const _handleTreeLoad = async () => {
    const res = await fetch(`${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/v1/query/trees?columns=${Object.keys(TREE_FIELDS).join()}&filter=id%3D${treeid}&transform_columns=location&key=${token}&q=${new Date().getTime()}`).then(res => res.json());
    const [tree] = res.map(tree => ({ ...tree, location: JSON.parse(tree.location || '{}'), geometry: JSON.parse(tree.location || '{}') }));

    setTree(tree);
  }

  useEffect(() => {
    if (treeid) _handleTreeLoad();
  }, [treeid]);

  return { tree };
};