import React from 'react';
import Icon from '../Icon';

const MapControls = ({ fitToBounds, zoomIn, zoomOut, isAddVisible, isAdding, onAddToggle = () => {}, onUnselect }) => {
  return (
    <div className="map-controls-wrapper">
      <ActionGroup>
        <Action action={fitToBounds} icon='expand' />
      </ActionGroup>
      <ActionGroup>
        <Action action={zoomIn} icon='plus' />
        <Action action={zoomOut} icon='minus' />
      </ActionGroup>
      { isAddVisible && <ActionGroup>
        <Action active={isAdding} action={onAddToggle} icon='tree' />
      </ActionGroup> }
      { onUnselect && <ActionGroup>
        <Action action={onUnselect} icon='redo-alt' />
      </ActionGroup> }
    </div>
  )
}

const ActionGroup = ({ children }) => {
  return (
    <div className="action-group">
      { children }
    </div>
  )
}

const Action = ({ icon, action = () => {}, active }) => {
  return (
    <div className={`action-wrapper ${active ? 'active' : ''}`} onClick={action}>
      <Icon icon={icon} />
    </div>
  )
}

export default MapControls;