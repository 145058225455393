import React, { useState } from 'react';
import { Button, TextInput } from '../inputs';

const SendToField = ({ history, dismissModal = () => null, onSendToField = () => {}, tree }) => {
  const [note, setNote] = useState('');
  const [error, setError] = useState('');

  const _handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (!note) return setError('This field is required');
    onSendToField(tree, note);
    dismissModal();
  }

  return (
    <div className="preferences-wrapper">
      <form onSubmit={_handleSubmit}>
        <TextInput key={tree?.id || tree?.tree?.id} label='Note' onPureChange={setNote} value={note} error={error} autoFocus />
        <Button label='Send to field' primary onClick={_handleSubmit} />
      </form>
    </div>
  )
}

export default SendToField;
