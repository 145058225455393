import React, { useState } from 'react';
import HorizontalTabs from './HorizontalTabs';

const Controls = ({ tabs = []}) => {
  const [currentTab, setCurrentTab] = useState(tabs[0]?.id);
  return (
    <div className="controls-wrapper">
      <HorizontalTabs tabs={tabs} active={currentTab} onPureSelect={setCurrentTab} />
      { tabs.find(tab => tab.id === currentTab).component }
    </div>
  )
}

export default Controls;