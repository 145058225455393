import React, { useState, useEffect } from 'react';
import Segment from './Segment';

const Info = ({ content = [] }) => {
  return (
    <div className="info-wrapper">
      {
        content.map(segment => (
          <Segment label={segment.title} key={segment.key}>
            {
              segment.data?.map(entry => <Entry active={segment.active} action={segment.onClick} key={`${entry.id}-${segment.key}`} {...entry} />)
            }
          </Segment>
        ))
      }
    </div>
  )
}

const Entry = ({ label, value, horizontal, id, active, action, onChange }) => {
  const [val, setValue] = useState(value || '-');
  const _handleAction = () => action && action(id);

  const _handleSubmit = (e) => {
    e.preventDefault();
    onChange(val?.replace(',', '.')?.trim());
  }

  const _handleChange = ({ target: { value }}) => setValue(value);

  useEffect(() => {
    setValue(value)
  }, [value])
  return (
    <form
      className={`entry-wrapper ${horizontal ? 'horizontal' : 'vertical'} ${active === id ? 'active' : 'inactive'} ${action ? 'button' : 'regular'}`} 
      onClick={_handleAction}
      onSubmit={_handleSubmit}
    >
      <span className="label">{ label }</span>
      <input disabled={!onChange} onBlur={_handleSubmit} onChange={_handleChange} className="value" value={val} />
      <input type="submit" value="submit" className='submit' />
    </form>
  )
}

export default Info;