import React, { useState } from 'react';

const Hierarchy = ({ hierarchy = [], colors = [] }) => {
  return (
    <div className="hierarhy-wrapper">
      {
        hierarchy.map(level => <Level colors={colors} key={level.id} { ...level } />)
      }
    </div>
  )
}

const Level = ({ label, sublevels = [], level = 0, colors = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const _handleAction = () => {
    if (sublevels.length > 0) setIsOpen(!isOpen);
    // TODO: add click action
  }
  return (
    <div className="level-outer-wrapper">
      <div
        onClick={_handleAction}
        className="level-wrapper regular-button-states"
      >
        <div className="dot" style={{ background: colors[level] || 'red' }} />
        <span className="label">{ label } { level }</span>
      </div>
      {
        isOpen && (
          <div className="sublevels-wrapper">
            {
              sublevels.map(lvl => <Level level={level + 1} { ...lvl } key={lvl.id} colors={colors} />)
            }
          </div>
        )
      }
    </div>
  )
}

export default Hierarchy;
