import React, { Fragment } from 'react'
import * as THREE from 'three';
import { LoaderWrapper } from '../../layout';

import SemanticMover from './SemanticMover';

const SemanticEditor = ({ position, onPositionChange = () => {}, pointcloud = {}, treeLoaded = false, loading, ...props }) => {
  const _handlePositionSet = (position) => onPositionChange(position.map((pos, i) => pos + (pointcloud?.pc?.mins?.[i] || 0)))

  const pos = new THREE.Vector3(
    position[0] - (pointcloud?.pc?.mins?.[0] || 0),
    position[1] - (pointcloud?.pc?.mins?.[1] || 0),
    position[2] - (pointcloud?.pc?.mins?.[2] || 0),
  );

  return (
    <LoaderWrapper loading={loading}>
      <div className="semantic-editor-wrapper" style={{ width: '100%', height: '100%', display: 'flex' }}>
        {
          (pointcloud || pointcloud === false) && (
            <Fragment>
              <SemanticMover 
                position={pos}
                setPosition={_handlePositionSet}
                pointcloud={pointcloud}
                {...props}
              />
              <SemanticMover 
                position={pos}
                setPosition={_handlePositionSet}
                pointcloud={pointcloud}
                {...props}
                isUp
              />
            </Fragment>
          )
        }
      </div>
    </LoaderWrapper>
  )
}

export default SemanticEditor;
