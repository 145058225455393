import React, { useState, useEffect, useRef } from 'react';
import { Switch } from 'react-router-dom';
import { Skeleton, Route } from '../../layout';
import { useData, useTrees } from '../../providers/data';

// Screens
import ValidationMap from './ValidationMap';
import LocationValidation from './LocationValidation';
import SemanticsEditor from './SemanticsEditor';
import DatabaseMatching from './DatabaseMatching';

const paths = ['dbmatch', 'semantics', 'location'];

const generateTabs = ({ history, path, isMASelected, pipeline, onPathChange = () => {}, onLocationSelect = () => {}, onSemanticsSelect = () => {}, onDBMatchSelect = () => {},}) => [
  {
    title: 'Map',
    icon: 'map',
    active: /validation\/?$/.test(path),
    path: '/validation',
    exact: true,
  },
  {
    title: 'Managed Area',
    icon: 'map-marked-alt',
    active: /validation\/\w+\/?$/.test(path),
    disabled: !isMASelected,
    onClick: () => onPathChange(''),
  },
  null,
  {
    title: 'Location',
    icon: 'map-marker-alt',
    active: /location/.test(path),
    disabled: !pipeline || pipeline?.current_manual_step !== 'location_validation',
    onClick: () => onPathChange('location'),
  },
  null,
  {
    title: 'Semantics',
    icon: 'tree',
    active: /semantics/.test(path),
    disabled: !pipeline || pipeline?.current_manual_step !== 'semantic_validation',
    onClick: () => onPathChange('semantics'),
  },
  null,
  {
    title: 'DB Matching',
    icon: 'link',
    active: /dbmatch/.test(path),
    disabled: !pipeline || pipeline?.current_manual_step !== 'db_match',
    onClick: () => onPathChange('dbmatch'),
  },
];


const ValidationRouter = ({ location, history, match }) => {
  const [selectedMA, setSelectedMA] = useState(match.params.MA || null);
  const [currentTreeId, setCurrentTreeId] = useState(null);
  const { getPipeline, getManagedArea, startPostValidation, pipelines } = useData();
  const managedarea = getPipeline(selectedMA);
  const currentManagedArea = getManagedArea(selectedMA);

  const [deletedTrees, setDeletedTrees] = useState([]);
  
  const trees = useTrees(selectedMA);

  const mapRef = useRef(null);
  const path = location.pathname;

  const _handleTab = (path) => selectedMA && history.push(`/validation/${selectedMA}/${path}`);

  const _handleMAFocus = () => {
    const bbox = pipelines.map(pipeline => JSON.parse(pipeline?.bbox)?.coordinates);
    if (bbox && bbox.length > 1) mapRef.current.focusOnBBox(bbox);
  };


  const _handleMapFocus = () => {
    if (!mapRef.current) return;
    if (currentManagedArea?.id) mapRef.current.focusOnBBox(currentManagedArea?.frontend_aoi_bbox?.coordinates);
    else _handleMAFocus();
  }

  useEffect(() => {
    _handleMapFocus();
  }, [pipelines.length, currentManagedArea?.id]);

  useEffect(() => {
    setSelectedMA(match.params.MA || null);
  }, [match.params.MA]);

  const _handleTreeDelete = (tree) => setDeletedTrees([ ...deletedTrees, tree?.id || tree]);


  return (
    <Skeleton
      header={{
        user: {},
        title: `Validation ${(managedarea || '') && ' – ' + managedarea.description}${currentTreeId ? ` - ${currentTreeId}` : ''}`,
      }} 
      tabs={generateTabs({ history, path, isMASelected: selectedMA !== null, pipeline: managedarea, onPathChange: _handleTab })}
    >
      <Switch>
        <Route 
          title={`${managedarea?.code} | DB Match | Validation`}
          path='/validation/:MA/dbmatch' 
          render={props => <DatabaseMatching { ...{ setCurrentTreeId, ...props, ...trees, startPostValidation, selection: [managedarea, selectedMA] }} />} 
        />
        <Route 
          title={`${managedarea?.code} | Semantics | Validation`}
          path='/validation/:MA/semantics' 
          render={props => <SemanticsEditor { ...{ setCurrentTreeId, ...props, startPostValidation, ...trees, selection: [managedarea, selectedMA] }} />} 
        />
        <Route 
          title={`${managedarea?.code} | Location Validation | Validation`}
          path='/validation/:MA/location/:tree?' 
          render={props => <LocationValidation { ...{ setCurrentTreeId, ...props, ...trees, startPostValidation, selection: [managedarea, selectedMA] }} />} 
        />
        <Route 
          title={`${managedarea?.code || selectedMA || 'Overview'} | Validation`}
          path='/validation/:MA?' 
          render={props => <ValidationMap { ...{ setCurrentTreeId, ...props, ...trees, deletedTrees, onDelete: _handleTreeDelete, startPostValidation, focusOnMA: _handleMapFocus, selection: [managedarea, selectedMA], mapRef, resetFocus: _handleMAFocus }} />} 
        />
      </Switch>
    </Skeleton>
  )
}

export default ValidationRouter;
