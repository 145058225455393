import React from 'react';
import Loader from '../components/Loader';

const LoaderWrapper = ({ children, loading = true }) => {
  return (
    <div className='loader-container-wrapper'>
      <div className={`overlay ${loading ? 'loading' : 'hidden'}`}>
        <Loader />
      </div>
      { children }
    </div>
  )
}

export default LoaderWrapper;
