import React from 'react';
import { Skeleton } from '../layout';
import { TextInput, Button } from '../components/inputs';
import { Icon } from '../components';
import { withRouter } from 'react-router-dom';
const NotFound = ({ history }) => {
  return (
    <Skeleton header={{ title: null, user: {} }}>
      <div className="notfound-wrapper">
        <Icon icon='rtms' />
        <h1>404 - Page not found.</h1>
        <p>It seems the page you are looking for is missing.</p>
      </div>
    </Skeleton>
  )
}

export default withRouter(NotFound);