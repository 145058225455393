import * as THREE from 'three';

class SemanticControl {
  constructor({ scene, onMovingChange, camera, container, points, repaint, getConfig, isDark, zoom }) {
    this.scene = scene;
    this.onMovingChange = onMovingChange;
    this.camera = camera;
    this.container = container;
    this.repaint = repaint;
    this._points = points;
    this.zoom = zoom;
    this.visible = false;
    this.intersections = [];
    this.raycaster = new THREE.Raycaster();
    this.intersection = new THREE.Vector3();
    this.mouse = new THREE.Vector2();
    this.plane = new THREE.Plane();

    this.getConfig = getConfig;
    this.isDark = isDark;

    this.pointSize = null;

    this.moving = false;

    this.pointFilters = null;
    this.setFilter();
  }

  setVisibility = (visible) => {
    this.object.visible = visible;
    this.visible = visible;
    this.setFilter();
    this.setSize();
    this.setZoom();
  }
  setPosition = position => this.object.position.copy(position);

  setFilter = () => {
    if (this.object && this.object.visible && this._points) this._points.material.userData.filterPoints(this.pointFilters);
    else if (this.object && !this.object.visible && this._points) this._points.material.userData.filterPoints(null);
  }

  setZoom = () => {
    if (this.object && this.object.visible && this._points && this.zoom && this.camera.zoom !== this.zoom) {
      this.camera.zoom = this.zoom;
      this.camera.updateProjectionMatrix();
      this.repaint();
    }
  }

  setSize = () => {
    if (this.object && this.object.visible && this._points) this._points.material.userData.setSize(this.pointSize);
    else if (this.object && !this.object.visible && this._points) this._points.material.userData.setSize(null);
  }

  set points(points) {
    this._points = points;
    this.setFilter();
  }

  handlePointerDown = (e) => {
    e.preventDefault();

    this.moving = true;

    this.plane.setFromNormalAndCoplanarPoint(
      this.camera.position.clone().normalize(),
      this.object.position,
    );

    this.moving = true;
    this.onMovingChange(true);
  }

  handlePointerUp = (e) => {
    e.preventDefault();

    this.moving = false;
    this.onMovingChange(false);
  }

  updateIntersection(e, objects) {
    const rect = this.container.getBoundingClientRect();
    this.mouse.x =  ((e.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.ray.intersectPlane(this.plane, this.intersection);
    const intersects = this.raycaster.intersectObjects(objects || this.object.children);

    return intersects;
  }
}

export class HeightControl extends SemanticControl {
  constructor(props) {
    super(props);
    this.onHeightChange = props.onHeightChange;

    const geometry = new THREE.CylinderBufferGeometry(props.radius, props.radius, HeightControl.width, 100);
    geometry.rotateX(Math.PI / 2);
    const material = new THREE.MeshBasicMaterial({ color: new THREE.Color(this.isDark ? 0xffff00 : 0xff8c00), side: THREE.DoubleSide });
    this.object = new THREE.Mesh(geometry, material);
    this.object.visible = false;

    this.inverse = props.inverse;

    this.diff = 0;

    this.scene.add(this.object);
  }

  static width = 0.05;

  setPosition = (vec2, height) => {
    if (this.inverse) this.object.position.copy(new THREE.Vector3(vec2.x, vec2.y, this.diff - height));
    else this.object.position.copy(new THREE.Vector3(vec2.x, vec2.y, height));

    this.object.material.color = new THREE.Color(this.isDark ? 0xffff00 : 0xff8c00);
    this.object.material.needsUpdate = true;
  }

  setDifference = diff => this.diff = diff;

  handlePointerMove = (e) => {
    e.preventDefault();
    
    if (!this.moving) return;
    if (!e.ctrlKey) return;
    
    e.stopPropagation();

    const rect = this.container.getBoundingClientRect();
    this.mouse.x =  ((e.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;
    
    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.ray.intersectPlane(this.plane, this.intersection);

    if (this.inverse) this.onHeightChange(this.diff - this.intersection.z);
    else this.onHeightChange(this.intersection.z);
  }
}

export class EllipseControl extends SemanticControl {
  constructor(props) {
    super(props);
    this.height = props.height;

    this.onChange = (state) => props.onChange({ ...state, perimeter: EllipseControl.calculateCircumference(state) });
    this.filter = props.filter !== false;

    this.object = new THREE.Group();

    this.state = {};

    this.ellipseMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(this.isDark ? 0xffff00 : 0xff8c00), opacity: 0.4, transparent: true })
    const circleGeometry = new THREE.CircleBufferGeometry(EllipseControl.pointRadius, 32);

    this.object.position.set(0, 0, props.height);

    this.circles = [];
    {
      const material = new THREE.MeshBasicMaterial({ color: EllipseControl.pointColor });
      const circle = new THREE.Mesh(circleGeometry, material);
      circle.position.set(0, 0, 0.01);
      circle.name = 'point-center';
      this.object.add(circle);
      this.circles.push(circle);
      this.centerCircle = circle;
    }
    {
      const material = new THREE.MeshBasicMaterial({ color: EllipseControl.pointColor });
      const circle = new THREE.Mesh(circleGeometry, material);
      circle.position.set(0, 0, 0.01);
      circle.name = 'point-rX';
      this.object.add(circle);
      this.circles.push(circle);
      this.rXCircle = circle;
    }
    {
      const material = new THREE.MeshBasicMaterial({ color: EllipseControl.pointColor });
      const circle = new THREE.Mesh(circleGeometry, material);
      circle.position.set(0, 0, 0.01);
      circle.name = 'point-rY';
      this.object.add(circle);
      this.circles.push(circle);
      this.rYCircle = circle;
    }

    this.pointSize = props.pointSize;

    this.scene.add(this.object);
  }

  static ellipseColor = 0xffff00;
  static pointColor = 0xff0000;
  static activeColor = 0x00ffff;
  static clippingRadius = 0.2;
  static pointRadius = 0.1;
  static minRadius = 0.05;

  static calculateAngle(p1, p2) { return Math.atan2(p2.y - p1.y, p2.x - p1.x); }
  static calculateCircumference({ rX, rY }) {
    // Method 1
    // const h = Math.pow((rX - rY), 2) / Math.pow((rX+rY), 2);
    // return (Math.PI * (rX + rY)) * (1 + ((3 * h) / (10 + Math.sqrt(4 - (3 * h) )) ));

    // Method 2
    return 2 * Math.PI * Math.sqrt((rX **2 + rY ** 2) / 2);
  }

  setPosition = (pos, val) => {
    if (!val) return;
    const { dX, dY, rX, rY, rotation } = val;
    this.position = pos;
    this.object.position.copy(new THREE.Vector3(pos.x + dX, pos.y + dY, 100));
    this.renderEllipse({ rX, rY, rotation });
    this.state = { dX, dY, rX, rY, rotation };
    this.pointFilters = this.filter ? { minZ: this.height - EllipseControl.clippingRadius, maxZ: this.height + EllipseControl.clippingRadius } : null;
    this.setFilter();

    this.ellipseMaterial.color = new THREE.Color(this.isDark ? 0xffff00 : 0xff8c00);
    this.ellipseMaterial.needsUpdate = true;
  }

  renderEllipse = ({ rX, rY, rotation }) => {
    this.object.rotation.z = THREE.Math.degToRad(rotation);

    this.circles.forEach((circle) => {
      const scale = 1 / this.camera.zoom;
      circle.scale.set(scale, scale, scale);
    });

    if (this.state.rX === rX && this.state.rY === rY) return;
    if (this.ellipse) this.object.remove(this.ellipse);

    const path = new THREE.Shape();
    path.absellipse(
      0,
      0,
      rX,
      rY,
      0,
      Math.PI * 2,
      false,
      0,
    );
    this.ellipse = new THREE.Mesh(new THREE.ShapeBufferGeometry(path, 100), this.ellipseMaterial)
    this.object.add(this.ellipse);

    this.rXCircle.position.set(rX, 0, this.rXCircle.position.z);
    this.rYCircle.position.set(0, rY, this.rYCircle.position.z);
    this.centerCircle.position.set(0, 0, this.centerCircle.position.z);
  }

  handlePointerDown = (e) => {
    e.preventDefault();

    this.moving = true;

    this.plane.setFromNormalAndCoplanarPoint(
      this.camera.position.clone().normalize(),
      this.object.position,
    );

    const intersects = this.updateIntersection(e, this.object.children);
    const point = intersects.find(i => i.object.name.startsWith('point-'));
    if (point) {
      this.selPoint = point.object;
      this.selPoint.material.color.setHex(EllipseControl.activeColor);
      this.currentPoint.material.needsUpdate = true;
    }
    const mouseIntersect = new THREE.Vector2(this.intersection.x, this.intersection.y);

    this.oldAngle = EllipseControl.calculateAngle(this.object.position, this.intersection);
    this.oldRotation = this.state.rotation;
    this.oldVector = mouseIntersect.clone().sub(new THREE.Vector2(this.object.position.x, this.object.position.y));

    this.moving = true;
    this.onMovingChange(true);
  }

  handlePointerUp = (e) => {
    e.preventDefault();

    this.moving = false;
    this.onMovingChange(false);

    this.currentPoint = this.selPoint;
    this.selPoint = null;
  }

  handlePointerMove = (e) => {
    e.preventDefault();

    const intersects = this.updateIntersection(e, this.object.children);
    if (!this.selPoint) {
      const point = intersects.find(i => i.object.name.startsWith('point-'));
      if (this.currentPoint !== point) {
        if (this.currentPoint) {
          this.currentPoint.material.color.setHex(EllipseControl.pointColor);
          this.currentPoint.material.needsUpdate = true;
          this.repaint();
          this.currentPoint = null;
        }
        if (point) {
          point.object.material.color.setHex(EllipseControl.activeColor);
          point.object.material.needsUpdate = true;
          this.currentPoint = point.object;
          this.repaint();
        }
      }
    }

    if (!this.moving) return;
    if (!e.ctrlKey) return;

    e.stopPropagation();

    const mouseIntersect = new THREE.Vector2(this.intersection.x, this.intersection.y);
    const position = new THREE.Vector2(this.object.position.x, this.object.position.y)
    
    // Rotation
    if (!this.selPoint) {
      return this.onChange({ 
        ...this.state,
        rotation: THREE.Math.radToDeg(this.oldRotation - (this.oldAngle - EllipseControl.calculateAngle(this.object.position, this.intersection))),
      });
    } 

    // We need to calculate the angle between the old center->point and new center->point vector to decide if radius can be increased
    const a = mouseIntersect.clone().sub(position);
    const b = a.clone().multiply(this.oldVector);
    const angle = Math.acos((b.x + b.y) / (this.oldVector.length() * a.length()));

    const distance = mouseIntersect.distanceTo(position);
    
    // Point controls
    if (this.selPoint.name === 'point-center') {
      this.onChange({ 
        dX: this.intersection.x - this.position.x, 
        dY: this.intersection.y - this.position.y, 
        rX: this.state.rX, 
        rY: this.state.rY,
        rotation: this.state.rotation,
      });
    } else if (this.selPoint.name === 'point-rX') {
      this.onChange({ 
        dX: this.object.position.x - this.position.x, 
        dY: this.object.position.y - this.position.y,
        rX: angle < Math.PI / 2 ? distance : EllipseControl.minRadius,
        rY: this.state.rY,
        rotation: this.state.rotation,
      });
    } else if (this.selPoint.name === 'point-rY') {
      this.onChange({ 
        dX: this.object.position.x - this.position.x, 
        dY: this.object.position.y - this.position.y,
        rX: this.state.rX, 
        rY: angle < Math.PI / 2 ? distance : EllipseControl.minRadius,
        rotation: this.state.rotation,
      });
    }
  }
}

// Deprecated width controls (used for girth previously)
export class WidthControl extends SemanticControl {
  constructor(props) {
    super(props);
    this.onWidthChange = props.onWidthChange;
    this.height = props.height;

    const geometry = new THREE.BoxGeometry(WidthControl.width, 1.0, 1.0);
    const material = new THREE.MeshBasicMaterial({ color: WidthControl.color, opacity: WidthControl.opacity, transparent: true });

    this.left = new THREE.Mesh(geometry, material);
    this.right = new THREE.Mesh(geometry, material);

    this.object = new THREE.Group();
    this.controlLines = new THREE.Group();
    this.controlLines.add(this.left);
    this.controlLines.add(this.right);
    this.object.add(this.controlLines)

    const lineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
    const points = [new THREE.Vector3(0, -2, this.height), new THREE.Vector3(0, 2, this.height)];
    this.line = new THREE.Line(new THREE.BufferGeometry().setFromPoints(points), lineMaterial);
    this.object.add(this.line);

    this.object.visible = false;

    this.scene.add(this.object);
  }
  
  static color = 0xffff00;
  static width = 0.01;
  static opacity = 0.7;

  setPosition = (vec3, width) => {
    this.object.position.copy(new THREE.Vector3(vec3.x, vec3.y, this.height + vec3.z));

    const distance = width / 2 + WidthControl.width / 2;
    this.left.position.set(distance * -1, 0, 0);
    this.right.position.set(distance, 0, 0);

    this.controlLines.quaternion.copy(this.camera.quaternion);

    this.line.quaternion.copy(this.camera.quaternion);
    this.line.rotateZ(Math.PI / 2);
  }

  handlePointerMove = (e) => {
    e.preventDefault();
    
    if (!this.moving) return;
    if (!e.ctrlKey) return;
    
    e.stopPropagation();

    const rect = this.container.getBoundingClientRect();
    this.mouse.x =  ((e.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;
    
    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.ray.intersectPlane(this.plane, this.intersection);

    const poi = new THREE.Vector3(this.intersection.x, this.object.position.y, this.object.position.z);

    const distance = Math.max(0, poi.distanceTo(this.object.position));

    this.onWidthChange(distance * 2);
  }
}
