import React, { createContext, useContext, useState } from 'react';
import Icon from '../components/Icon';

export const Context = createContext({ });

export const useError = () => useContext(Context);

const isUnauthenticated = process.env.NODE_ENV !== 'development' && !(window.API_KEY && window.API_KEY.length > 32);


export const Provider = (props) => {
  const [error, setError] = useState(isUnauthenticated ? 'Unauthenticated (401)' : '');
  return (
    <Context.Provider value={{ error, setError }}>
      {
        error ? <ErrorView error={error} /> : props.children
      }
    </Context.Provider>
  )
};

const ErrorView = ({ error }) => {
  return (
    <div className="error-overlay">
      <Icon icon='rtms' />
      <h1>An error occured. - { String(error) }</h1>
      <h2>Please try again later or get in touch with us.</h2>
    </div>
  )
}