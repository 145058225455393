import React, { useState, useEffect, useRef } from 'react';
import { Skeleton, Route } from '../../layout';
import { Switch } from 'react-router-dom';
import { useData, useTrees } from '../../providers/data';

import CompletedMap from './CompletdMap';
import SingleTree from './SingleTree';

const generateTabs = ({ history, path }) => [
  {
    title: 'Map',
    icon: 'map',
    active: path === 'validation',
    path: '/cma',
    exact: true,
  },
  {
    title: 'Managed Area',
    icon: 'map-marked-alt',
    // active: path !== 'validation' && !paths.includes(path),
    // disabled: !isMASelected,
    // onClick: () => onPathChange(''),
  },
  null,
  {
    title: 'Semantics',
    icon: 'tree',
    active: path === 'semantics',
    // disabled: !pipeline || pipeline?.current_manual_step !== 'semantic_validation',
    // onClick: () => onPathChange('semantics'),
  },
];

const Completed = ({ history, match }) => {
  const [managedArea, setManagedArea] = useState(null);
  const { pipelines } = useData();
  const trees = useTrees(match.params.MA);
  const mapRef = useRef(null);

  const _handleMAFocus = () => {
    const bbox = pipelines.map(pipeline => JSON.parse(pipeline?.bbox)?.coordinates);
    if (bbox && bbox.length > 1) mapRef.current.focusOnBBox(bbox);
  }

  const _handleMapFocus = () => {
    if (!mapRef.current) return;
    if (managedArea?.id) mapRef.current.focusOnBBox(managedArea?.frontend_aoi_bbox?.coordinates);
    else _handleMAFocus();
  }

  const _handleMASelect = () => {
    const pipeline = pipelines.find(pipeline => pipeline.id === parseInt(match.params.MA));
    setManagedArea(pipeline || null);
  }

  useEffect(() => {
    _handleMapFocus();
  }, [pipelines.length, managedArea?.id]);

  useEffect(() => {
    _handleMASelect();
  }, [pipelines.length, match.params.MA])


  return (
    <Skeleton
      header={{
        user: {},
        title: `Completed Managed Areas`,
      }} 
      tabs={generateTabs({ history })}
    >
      <Switch>
        <Route 
          title={`Tree inspector | Completed Managed Areas`}
          path='/cma/tree/:tree' 
          render={props => <SingleTree { ...{ ...props, ...trees, pipelines, mapRef, managedArea } } />} 
        />
        <Route 
          title={`${managedArea?.code || 'Overview'} | Completed Managed Areas`}
          path='/cma/:MA?' 
          render={props => <CompletedMap { ...{ ...props, ...trees, pipelines, mapRef, managedArea } } />} 
        />
      </Switch>
    </Skeleton>
  )
}

export default Completed;