import React, { PureComponent } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { HeightControl, WidthControl, EllipseControl } from './controls';

class SemanticMover extends PureComponent {
  constructor(props) {
    super(props);

    this.moveStart = new THREE.Vector2();
    this.moveEnd = new THREE.Vector2();
    this.moveDelta = new THREE.Vector2();
    this.moveSpeed = 0.003;

    this.scene = new THREE.Scene();
    this.scene.up.set(0, 0, 1);
    this.scene.background = new THREE.Color(this.props.background || 0x000000);

    this.sphere = new THREE.Mesh(
      new THREE.SphereGeometry(SemanticMover.sphereSize, 32, 32),
      new THREE.MeshBasicMaterial({ color: SemanticMover.sphereColor })
    );
    this.sphere.visible = false;
    this.scene.add(this.sphere);

    window.addEventListener('resize', () => this.updateRatio());

    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.updateRatio();
      }
    });

    this.state = {
      moving: false,
    };
  }

  static frustumSize = 12;
  static sphereSize = 0.2;
  static sphereColor = 0xff0000;
  static minZoom = 0.3;
  static maxZoom = 7;
  static heightCircleRadius = 3;
  static trunkCircleRadius = 1.0;
  static canopyCircleRadius = 1.2;

  componentDidMount = () => {
    this.resizeObserver.observe(this.container);

    this.cameras = {
      PERSPECTIVE: new THREE.PerspectiveCamera(
        60,
        5 / 2,
        0.1,
        1000,
      ),
      ORTHOGRAPHIC: new THREE.OrthographicCamera(
        0.5 * SemanticMover.frustumSize * 5/2,
        0.5 * SemanticMover.frustumSize * 5/2,
        SemanticMover.frustumSize / 2,
        SemanticMover.frustumSize / -2, 
        0.1,
        1000
      ),
    }

    Object.values(this.cameras).forEach((camera) => {
      camera.up.set(0, 0, 1);
      camera.updateProjectionMatrix();
    });
    this.camera = this.cameras.ORTHOGRAPHIC;

    this.semanticControls = [];
    const controlProps = { 
      scene: this.scene, 
      camera: this.camera, 
      container: this.container,
      points: this.points,
      repaint: this.repaint,
      getConfig: this.props.getConfig,
      isDark: this.props.isDark,
      onMovingChange: moving => this.setState({ moving }),
    };
    if (!this.props.isUp) {
      this.heightControl = new HeightControl({ ...controlProps, onHeightChange: this.props.onHeightChange, radius: SemanticMover.heightCircleRadius, zoom: 0.5 });
      this.trunkHeightControl = new HeightControl({ ...controlProps, onHeightChange: this.props.onTrunkHeightChange, radius: SemanticMover.trunkCircleRadius, zoom: 0.6 });
      this.canopyHeightControl = new HeightControl({ ...controlProps, onHeightChange: this.props.onCanopyHeightChange, radius: SemanticMover.canopyCircleRadius, inverse: true, zoom: 0.45 });

      this.semanticControls.push(this.heightControl, this.trunkHeightControl, this.canopyHeightControl);
    }
    if (this.props.isUp) {
      this.girth1Control = new EllipseControl({ ...controlProps, onChange: this.props.onGirth1Change, height: 1, pointSize: 5, zoom: 4 });
      this.girth13Control = new EllipseControl({ ...controlProps, onChange: this.props.onGirth13Change, height: 1.3, pointSize: 5, zoom: 3.8 });
      this.canopyControl = new EllipseControl({ ...controlProps, onChange: this.props.onCanopyChange, height: 0, filter: false, zoom: 0.85 });
      this.semanticControls.push(this.girth1Control, this.girth13Control, this.canopyControl);
    }

    this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas: this.canvas });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.localClippingEnabled = true;
    // this.renderer.clippingPlanes = [new THREE.Plane(new THREE.Vector3(0, 0, 1), -10)];
    this.updateRatio();

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enablePan = true;
    this.controls.rotateSpeed = 1.0;
    this.controls.minZoom = SemanticMover.minZoom;
    this.controls.maxZoom = SemanticMover.maxZoom;
    // this.controls.zoomSpeed = 0.5;

    this.controls.addEventListener('change', () => this.repaint());

    if (this.props.isUp) {
      // this.controls.enableRotate = false;
      this.controls.minPolarAngle = 0;
      this.controls.maxPolarAngle = 0;
    } else {
      this.controls.minPolarAngle = Math.PI / 2;
      this.controls.maxPolarAngle = Math.PI / 2;
    }
    this.controls.update();
    this.repaint();

    this.updateFromProps({});
  }

  updateRatio = () => {
    const container = this.container || document.getElementById(this.scene.uuid);

    if (!this.container) return console.warn('this.container is undefined, this is probably due to the hot reloader', this);
    if (!container) return console.error('No container has been found for MovableTree.', this);

    this.aspectRatio = container.clientWidth / container.clientHeight;
    this.renderer.setSize(container.clientWidth, container.clientHeight);

    this.cameras.ORTHOGRAPHIC.left  = - 0.5 * SemanticMover.frustumSize * this.aspectRatio;
    this.cameras.ORTHOGRAPHIC.right = 0.5 * SemanticMover.frustumSize * this.aspectRatio;
    this.cameras.ORTHOGRAPHIC.top  = 0.5 * SemanticMover.frustumSize;
    this.cameras.ORTHOGRAPHIC.bottom = - 0.5 * SemanticMover.frustumSize;
    if (this.cameras.ORTHOGRAPHIC.updateProjectionMatrix) this.cameras.ORTHOGRAPHIC.updateProjectionMatrix();

    this.cameras.PERSPECTIVE = this.aspectRatio;
    if (this.cameras.PERSPECTIVE.updateProjectionMatrix) this.cameras.PERSPECTIVE.updateProjectionMatrix();

    if (this.controls) this.controls.update();

    this.repaint();
  }

  updateFromProps = (props) => {
    if (props.background !== this.props.background) {
      this.scene.background = new THREE.Color(this.props.background || 0x000000);
      requestAnimationFrame(this.repaint);
    }
    if (props.editing !== this.props.editing) requestAnimationFrame(this.repaint);
    if (props.pointcloud !== this.props.pointcloud) {
      if (this.points) {
        this.points.geometry.dispose();
        this.points.material.dispose();
        this.scene.remove(this.points);
      }

      let boundingSphere;
      // pointcloud can be false
      if (this.props.pointcloud) {
        this.pointcloud = this.props.pointcloud;
        const points = new THREE.Points(this.props.pointcloud.geometry, this.props.pointcloud.createMaterial());
        this.points = points; 
        this.points.up.set(0, 0, 1);
        this.semanticControls.forEach(c => c.points = this.points);

        console.log('Pointcloud updated: ', this.props.pointcloud)

        this.scene.add(this.points);

        boundingSphere = points.geometry.boundingSphere;
      } else {
        boundingSphere = new THREE.Sphere(
          new THREE.Vector3().copy(this.sphere.position),
          8,
        );
      }

      if (this.controls) {
        this.controls.target.set(
          boundingSphere.center.x, 
          boundingSphere.center.y, 
          boundingSphere.center.z,
        );
      }

      if (this.props.isUp) {
        this.camera.position.set(
          this.props.position.x,
          this.props.position.y,
          150,
        );
        this.controls.target.copy(
          new THREE.Vector3(
            this.props.position.x,
            this.props.position.y,
            this.props.position.z
          )
        );
      } else {
        this.camera.position.set(
          boundingSphere.center.x + boundingSphere.radius * 1.8,
          boundingSphere.center.y + boundingSphere.radius * 1.8,
          boundingSphere.center.z,
        );
        this.controls.target.copy(
          new THREE.Vector3(
            boundingSphere.center.x,
            boundingSphere.center.y,
            boundingSphere.center.z,
          )
        );
      }
      this.controls.update();
      requestAnimationFrame(this.repaint);
    } 
  }

  componentDidUpdate = prevProps => this.updateFromProps(prevProps)
  
  repaint = () => requestAnimationFrame(() => this.forceUpdate());

  render() {
    if (!this.props.uneditable) {
      this.sphere.position.copy(this.props.position);
  
      if (this.renderer) this.renderer.render(this.scene, this.camera);
    }

    let semanticControl;
    if (this.semanticControls && !this.props.uneditable) {
      this.semanticControls.forEach((control) => {
        control.getConfig = this.props.getConfig;
        control.isDark = this.props.isDark;
      });

      if (this.heightControl) this.heightControl.setPosition(new THREE.Vector2(this.props.position.x, this.props.position.y), this.props.height);
      if (this.trunkHeightControl) this.trunkHeightControl.setPosition(new THREE.Vector2(this.props.position.x, this.props.position.y), this.props.trunkHeight);
      if (this.girth1Control) this.girth1Control.setPosition(this.props.position, this.props.girth1);
      if (this.girth13Control) this.girth13Control.setPosition(this.props.position, this.props.girth13);
      if (this.canopyControl) {
        this.canopyControl.setPosition(this.props.position, this.props.canopy);
        this.canopyControl.height = this.props.height;
      }
      if (this.canopyHeightControl) {
        // canopy height is not the Z where the canopy starts but the height of the canopy (meaning: HEIGHT - Z where canopy starts)
        this.canopyHeightControl.setDifference(this.props.height);
        this.canopyHeightControl.setPosition(new THREE.Vector2(this.props.position.x, this.props.position.y), this.props.canopyHeight);
      }

      switch (this.props.editing) {
        case 'height':
          semanticControl = this.heightControl;
          break;
        case 'trunkHeight':
          semanticControl = this.trunkHeightControl;
          break;
        case 'canopyHeight':
          semanticControl = this.canopyHeightControl;
          break;
        case 'girth1':
          semanticControl = this.girth1Control;
          break;
        case 'girth13':
          semanticControl = this.girth13Control;
          break;
        case 'canopy':
          semanticControl = this.canopyControl;
          break;
        default:
          semanticControl = null;
      }

      this.semanticControls.forEach((control) => {
        if (control === semanticControl) return;

        control.setVisibility(false);
      });

      // It may not exist if it is an `isUp` instance
      if (semanticControl && !semanticControl.visible) semanticControl.setVisibility(true);
    }

    return (
      <div 
        className='tree-mover'
        id={this.scene && this.scene.uuid}
        ref={e => this.container = e} 
        onPointerDown={semanticControl && semanticControl.handlePointerDown}
        onPointerMove={semanticControl && semanticControl.handlePointerMove}
        onPointerUp={semanticControl && semanticControl.handlePointerUp}
        style={{ flex: '1', cursor: this.state.moving ? 'grabbing' : 'grab', position: 'relative' }}
      >
        <canvas 
          ref={e => this.canvas = e} 
          style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}
        />
      </div>
    );
  }
}

export default SemanticMover;