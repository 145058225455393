import React, { useRef } from 'react'
import * as THREE from 'three'
import { useFrame } from 'react-three-fiber'

const tempObject = new THREE.Object3D()

const Trunk = ({ data, pressed, movement }) => {
  const ref = useRef()
  const coords = data.COORDS.COORD.map(item =>
    item.split(',').map(x => parseInt(x, 10))
  )

  useFrame(() => {
    coords.forEach(([x, y, z], id) => {
      tempObject.position.set(x, y, z)
      tempObject.scale.set(1, 1, 1)
      tempObject.updateMatrix()
      ref.current.setMatrixAt(id, tempObject.matrix)
    })

    ref.current.instanceMatrix.needsUpdate = true

    ref.current.rotation.x = 1.5 * Math.PI
    ref.current.position.y = -50
    if (pressed) {
      ref.current.rotation.z = ref.current.rotation.z + movement.x * 0.2
    }
  })

  return (
    <instancedMesh
      ref={ref}
      args={[null, null, coords.length]}
      scale={[1, 1, 1]}>
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <meshPhongMaterial attach="material" color="#807153" />
    </instancedMesh>
  )
}

export default Trunk
