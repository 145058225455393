import React, { useEffect } from 'react';
import HorizontalTabs from './HorizontalTabs';
import { withRouter } from 'react-router-dom';
const MultiTabView = ({ location, tabs = {}, value = {}, onPureChange = () => {}, name, onChange = () => {} }) => {
  const query = new URLSearchParams(location.search.replace('?', ''));

  const _handleChange = ({ name, value: val }) => {
    const _value = { ...value };
    _value[name] = val;
    onPureChange(_value);
    onChange({ name, value: _value });

    Object.keys(value).forEach(key => query.set(key, _value[key]));
    window.history.replaceState(null, null, `${location.pathname}?${query.toString()}`);
  }

  useEffect(() => {
    const _value = { ...value };
    Object.keys(tabs).forEach(key => _value[key] = query.get(key) || value[key] || tabs[key][0].id);

    onPureChange(_value);
    onChange({ name, value: _value });
  }, [])

  const tabKeys = Object.keys(tabs);
  return (
    <div className="multi-tab-view-wrapper">
      {
        tabKeys.map(key => (
          <HorizontalTabs 
            active={value[key]} 
            key={key} 
            id={key} 
            tabs={tabs[key]} 
            onSelect={_handleChange}
            disabled={tabKeys.filter(_ => _ !== key).reduce((prev, key) => [...prev, value?.[key]], [])}
          />
        ))
      }
    </div>
  )
}

export default withRouter(MultiTabView);