import React from 'react';
import { useTheme } from '../../providers/theme';
import { useConfig } from '../../providers/config';
import { Toggle, Button, Color } from '../inputs';

const Preferences = ({ history, dismissModal = () => null }) => {
  const { toggleTheme, setTheme, isDark } = useTheme();
  const { getConfig, setConfig, config } = useConfig();

  const _handleUikit = () => {
    history.push('/uikit');
    dismissModal();
  }

  const _handleColorChange = ({ name, value }) => {
    const _config = { ...config };
    _config.colors[name] = value;
    setConfig(_config);
  }

  const statusconfig = getConfig('statuses');
  const statuses = Object.keys(statusconfig).map(key => statusconfig[key]).map(({ label, color, category }) => ({
    label,
    category,
    color,
    value: getConfig(`colors.${color}`),
  }));

  const categories = statuses.reduce((prev, status) => ({ ...prev, [status.category]: [...(prev[status.category] || []), status] }), {});
  const categoryList = Object.keys(categories).map(key => ({
    ...getConfig(`categories.${key}`),
    key,
    entries: categories[key],
  }))
  console.log(categoryList);

  return (
    <div className="preferences-wrapper">
      <h1>Theme</h1>
      <Toggle onPureChange={setTheme} value={isDark} label='Dark mode' />
      <h1>Colors</h1>
      {
        categoryList.map(category => <Category key={category.key} {...category} onChange={_handleColorChange} />)
      }
    </div>
  )
}

const Category = ({ entries = [], label = 'Category ', onChange }) => {
  return (
    <div className="category-wrapper">
      <h2>{ label }</h2>
      {
        entries.map(({ label, color, value }) =>  <Color label={label} name={color} onChange={onChange} value={value} key={color} />)
      }
    </div>
  )
}

export default Preferences;
