import React from 'react';
import Nparks from './Nparks';
import RTMS from './RTMS';
import GH from './GH';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const icons = {
  nparks: Nparks,
  rtms: RTMS,
  gh: GH,
};

const Icon = ({ icon }) => {
  if (!icon) return null;
  const Comp = icons[icon];
  if (Comp) return <Comp />
  return <FontAwesomeIcon icon={icon} />
}

export default Icon;