import React from 'react';
import { Skeleton } from '../layout';
import { TextInput, Button } from '../components/inputs';
import { withRouter, Link } from 'react-router-dom';

import { ReactComponent as Validation } from '../svg/validation.svg';
import { ReactComponent as Overview } from '../svg/overview.svg';
import { ReactComponent as Completed } from '../svg/completed.svg';
import { ReactComponent as Inspection } from '../svg/inspection.svg';

const OPTIONS = [
  {
    label: 'Task Overview',
    image: <Overview />,
    path: '/overview'
  },
  {
    label: 'Validation',
    image: <Validation />,
    path: '/validation'
  },
  {
    label: 'Virtual Inspection',
    image: <Inspection />,
    path: '/inspection'
  },
  {
    label: 'Completed managed areas',
    image: <Completed />,
    path: '/cma'
  },
]

const HomePage = ({ history }) => {
  return (
    <Skeleton header={{ title: null, user: {} }}>
      <div className="home-wrapper">
        <div className="options-wrapper">
          {
            OPTIONS.map(option => <Option { ...option } key={option.path || option.label} />)
          }
        </div>
      </div>
    </Skeleton>
  )
}

const Option = ({ label, image, path }) => {
  return (
    <Link className="option-wrapper" to={path || '#'} >
      <div className="image">
        <div className="outline" />
        { image }
      </div>
      <h2>{ label }</h2>
    </Link>
  )
}

export default withRouter(HomePage);