import React, { createContext, useContext, useState } from 'react';

const STORAGE_KEY = '@theme.isDark';

export const Context = createContext({ });

export const useTheme = () => useContext(Context);

export const withTheme = (Component) => props => <Context.Consumer>{theme => <Component { ...props } ref={props._ref} theme={theme} />}</Context.Consumer>;

export const Provider = (props) => {
  const [isDark, setIsDark] = useState((localStorage.getItem(STORAGE_KEY) === 'true'));
  /**
   * Toggle the application's current theme
   */
  const toggleTheme = () => _handleThemeSet(!isDark);

  /**
   * Set's the current theme
   * @param isDark: Whether to set the theme to dark
   */
  const setTheme = (isDark) => _handleThemeSet(isDark);

  // Setting theme, updating local storage
  const _handleThemeSet = (isDark) => {
    setIsDark(isDark);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(isDark))
  }
  return (
    <Context.Provider value={{ isDark, toggleTheme, setTheme }}>
      <main className={`page-wrapper ${isDark ? 'dark' : 'light'}`}>
        { props.children }
      </main>
    </Context.Provider>
  )
};