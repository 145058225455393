import React, { useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Grid } from '../../layout';
import { MultiTabView, VoxelViewer, PanoramicView, Map, Carousel } from '../../components';
import SemanticMover from '../../components/SemanticEditor/SemanticMover';
import { useTree, getCapturePoint } from '../../providers/data';
import { useUser } from '../../providers/user';
import { useConfig } from '../../providers/config';
import dummyTreeVoxel from '../../dummyVoxel';
import { useTheme } from '../../providers/theme';
import LASLoader from '../../utils/LASLoader';
import proj4 from 'proj4';

const MOCK_PHOTOS = {
  images: [
    {
      src:'https://i.pinimg.com/originals/fe/18/fe/fe18fe384000f818af777422beeed260.jpg',
    },
    {
      src: 'https://lh3.googleusercontent.com/proxy/G0l6JmOcG12Leuv43Y85W4iAikB62E2x9A6o9a6swMTk_ecgyZoYnL1J4sm21QBoTkqR9XAIQ5tQkuLGoxeps5mJsvdhiOxHKha4SfVaXIN4Wte9NI5KyOd8ZEM',
    },
    {
      src: 'https://i.pinimg.com/originals/cd/89/e6/cd89e689cf7b858d0edcea4712bafe1a.jpg',
    },
  ],
};

const MAP_SOURCES = [
  { id: 'trees', source: 'trees', name: 'Trees' },
  { id: 'mas', source: 'managed_areas', name: 'Managed Areas' },
];


// Creating las loader
const loadingManager = new THREE.LoadingManager();
const loader = new LASLoader(loadingManager);
const TABS = [
  {
    label: 'Photos',
    id: 'photos'
  },
  {
    label: 'Voxel',
    id: 'voxel',
  },
  {
    label: 'Panoramic',
    id: 'panoramic'
  },
  {
    label: 'Pointcloud',
    id: 'pointcloud'
  },
  {
    label: '2D Map',
    id: 'map'
  },
];

const MULTITABS = {
  left: TABS,
  right: TABS,
}

const useViews = (tree, { token, isDark, mapRef }) => {
  const [photos, setPhotos] = useState(null);
  const [voxel, setVoxel] = useState(null);
  const { getConfig } = useConfig();
  const [panoramic, setPanoramic] = useState(null);
  const [pointcloud, setPointcloud] = useState(null);
  const [map, setMap] = useState(null);

  const _handleVoxel = () => {
    // TODO: download and parse voxel data
    setVoxel(<VoxelViewer xml={dummyTreeVoxel} backgroundColor={isDark ? '#000' : '#fff'} />);
  };

  const _handlePanoramic = async () => {
    const images = await getCapturePoint(tree, token);
    const photos = images.map(({ path }) => ({ src: (window._env_.REACT_APP_PANORAMA || process.env.REACT_APP_PANORAMA).replace('__PATH__', path) }))
    console.log(photos);
    const _tree = { ...tree, geometry: { ...tree.geometry, coordinates: proj4("EPSG:4326", "EPSG:3414", tree?.location?.coordinates?.slice(0) || []) }};
    setPanoramic(<PanoramicView
      selTree={_tree}
      tree={[_tree]}
      capturePoint={{ position: { coordinates: images?.[0]?.origin.coordinates } }}
      images={images}
      cameras={images}
    />);
    setPhotos(<Carousel carouselData={{ images: photos }} />)
  }

  const _handlePointcloud = async () => {
    const url = `${(window._env_.REACT_APP_LOCATION_LAS || process.env.REACT_APP_LOCATION_LAS).replace('__MA__', tree.managed_area).replace('__TREE__', tree.id)}`;
    loader.load(url, pointcloud => {
      setPointcloud(<SemanticMover 
        pointcloud={pointcloud}
        uneditable
      />)
    });
  }

  const _handleMap = () => {
    const layers = [
      {
        id: 'mas',
        source: 'mas',
        type: 'fill',
        color: '#082',
      },
      {
        id: 'completed',
        source: 'trees',
        type:'circle',
        filter: ["==", "id", parseInt(tree.id)],
        color: getConfig(`colors.${getConfig(`statuses.completed`)?.color}`) || '#08f'
      }
    ];

    setMap(<Map
      _ref={mapRef}
      sources={MAP_SOURCES}
      layers={layers}
      sourceVisbile={{ trees: true, mas: true }}
      layerVisible={{ 
        mas: true,
        maven: true,
        completed: true,
      }}
      active={{ 
        mas: tree.managed_area_id,
        completed: parseInt(tree.id)
      }}
    />);
    setTimeout(() => {
      mapRef.current?.focusOnPoint(tree?.geometry?.coordinates || [], 20);
    }, 2000);
  }

  const _handlePhotos = () => {}

  useEffect(() => {
    console.log(tree)
    if (tree) {
      _handleVoxel();
      _handlePanoramic();
      _handlePointcloud();
      _handleMap();
      _handlePhotos();
    }
  }, [tree?.id]);

  return {
    photos,
    voxel,
    panoramic,
    pointcloud,
    map,
  }
}

const SingleTree = ({ match }) => {
  const mapRef = useRef(null);
  const [activeTabs, setActiveTab] = useState({
    left: 'photos',
    right: 'panoramic'
  });
  const { token } = useUser();
  const { tree } = useTree(match.params.tree);
  const { isDark } = useTheme();
  const views = useViews(tree, { token, isDark, mapRef });

  useEffect(() => {
    if (activeTabs.left === 'map' || activeTabs.right === 'map') setTimeout(() => mapRef.current?.focusOnPoint(tree?.geometry?.coordinates || [], 20), 250);
  }, [activeTabs.left, activeTabs.right, tree?.id])

  return (
    <Grid>
      <div className="single-tree-wrapper">
        <MultiTabView
          tabs={MULTITABS}
          value={activeTabs}
          // value={activeTab2}
          onPureChange={setActiveTab}
        />
        <div className="views">
          <div className="view-wrapper">
            { views[activeTabs.left] }
          </div>
          <div className="view-wrapper">
            { views[activeTabs.right] }
          </div>
        </div>
      </div>
    </Grid>
  )
}

export default SingleTree;
