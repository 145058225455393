import React from 'react';
import { Slider, Button } from './inputs';
const round = (val) => Math.round(val * 100) / 100;
const Controls = ({ girthDifference, maxDistance, setGirthDifferece, setMaxDistance, onSendAllToField = () => {} }) => {
  return (
    <div className="dbmatch-controls">
      <span className="title">DB Match configuration</span>
      <div className="caption">
        <span className="label">Max distance</span>
        <span className="value">{round(maxDistance)} m</span>
      </div>
      <Slider min={0} max={10} label={(val) => `${(Math.round(val * 100)) / 100} m`} value={maxDistance} onPureChange={setMaxDistance} />
      <div className="caption">
        <span className="label">Max girth difference</span>
        <span className="value">{round(girthDifference)} m</span>
      </div>
      <Slider min={0} max={1} label={(val) => `${Math.round(val * 100) / 100} m`} value={girthDifference} onPureChange={setGirthDifferece} />
      <Button label='Send all desolate to field' onClick={onSendAllToField} primary />
    </div>
  )
}

export default Controls;
