import React, { useState, useRef, useEffect } from 'react';
import { Map, ProgressControls, Matching, PanoramicView, DBMatchControls, Finished } from '../../components';
import { Grid } from '../../layout';
import { validationActions } from '../../core/progressActions';
import { useMavenTrees } from '../../providers/data';
import { LoaderWrapper } from '../../layout';
import { useModal } from '../../providers/modal';
import { useConfig } from '../../providers/config';
import { dbmatch, generateLines } from '../../core';
import { useTheme } from '../../providers/theme';
import proj4 from 'proj4';
const TREE_SOURCES = [
  {
    id: 'maven',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  },
  {
    id: 'connections',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  },
  {
    id: 'todos',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  },
];

const LAYER_CONFIG = [
  {
    id: 'connections',
    source: 'connections',
    type: 'line',
    color: '#082',
  },
  {
    id: 'db_match_todo',
    source: 'todos',
    type: 'circle',
    geojson: true,
  },
  {
    id: 'maven',
    source: 'maven',
    type: 'circle',
    geojson: true,
  },
];

const generateConnectionFeatures = (currentTree) => (
  generateLines([currentTree]).map(({ id, coordinates, ...properties }) => ({
    type: 'Feature',
    id,
    properties,
    geometry: {
      type: 'LineString',
      coordinates,
    },
  }))
)

const generateConnections = (map, currentTree) => map?.setDataForSource('connections', { 
  type: 'FeatureCollection', 
  features: generateConnectionFeatures(currentTree)
});

const setSources = ({ todos, mavens }, map, current, shouldGenerateConnections = true) => {
  const treeFeatures = todos.map((tree, i) => ({
    type: 'Feature',
    id: parseInt(tree.id),
    properties: { ...tree },
    geometry: {
      coordinates: tree.geometry.coordinates.slice(0, 2),
      type: 'Point',
    },
  }));

  const mavenFeatures = mavens.map((maven, i) => ({
    type: 'Feature',
    id: parseInt(maven.id),
    properties: { ...maven },
    geometry: {
      coordinates: maven.location.coordinates.slice(0, 2),
      type: 'Point',
    },
  }));

  console.log('set sources', mavenFeatures, map.current)
  map.current?.setDataForSource('todos', { type: 'FeatureCollection', features: treeFeatures });
  map.current?.setDataForSource('maven', { type: 'FeatureCollection', features: mavenFeatures });
  if (shouldGenerateConnections) generateConnections(map?.current, current);
}

const DatabaseMatching = ({ trees = [], loaded, updateTree, getCapturePoint, selection = [], startPostValidation, setCurrentTreeId }) => {
  // Config & general hooks
  const { presentModal, dismissModal } = useModal();
  const { getConfig } = useConfig();

  const { isDark } = useTheme();

  // Panoramic view config
  const [panoramaImages, setPanoramaImages] = useState([]);
  const [capturePoint, setCapturePoint] = useState({ position: { coordinates: [0, 0, 0] } });

  // Map reference
  const map = useRef(null);
  const layers = useRef(LAYER_CONFIG.map((layer) => {
    const config = getConfig(`statuses.${layer.id}`);
    return { ...layer, label: config?.label || 'Unnamed', color: getConfig(`colors.${config?.color}`) };
  }));

  // MAtching config
  const [girthDifference, setGirthDifferece] = useState(.2);
  const [maxDistance, setMaxDistance] = useState(2);
  
  // Whether RMTS trees are finsihed
  const [RTMSFinished, setRTMSFinished] = useState(false);
  const [processFinished, setProcessFinished] = useState(false);
  const [mavensStarted, setMavensStarted] = useState(false);

  // Selected maven tree
  const [selected, setSelected] = useState(null);

  // Current index offset
  const [currentIndex, setCurrentIndex] = useState(0);
  
  // Getting trees
  const { trees: mavenTrees, loaded: mavensLoaded, updateTree: updateMavenTree } = useMavenTrees(selection[0]);
  console.log(mavenTrees, selection[0]);
  // Filtering maven trees
  const mavens = mavenTrees.filter(maven => !maven.sent_to_field && !trees.find(({ maven_id }) => maven_id === maven.id));
  const todos = trees.filter((tree) => tree.status === 'db_match_todo');

  console.log('RENDER: ', todos?.length, mavens?.length)


  // Genearating matches
  const matches = dbmatch(todos || [], mavens || [], { maxDistance, girthDifference });
  
  // Getting current tree
  const treeList = mavensStarted ? mavens.map(tree => ({ tree })) : matches;
  const currentTree = treeList[currentIndex % treeList.length];

  // =========================
  // Methods
  // =========================

  // Getting panoramadetails
  const _handlePanorama = async () => {
    const panoramaImages = await getCapturePoint(currentTree?.tree);
    console.log('Loading panorama', panoramaImages)
    setPanoramaImages(panoramaImages);
    setCapturePoint({ position: { coordinates: panoramaImages?.[0]?.origin.coordinates } });
  }

  // focusing on tree
  const _handleTreeFocus = (tree) => tree?.geometry?.coordinates && map.current?.focusOnPoint(tree?.geometry?.coordinates || [], 20);

  // Start maven process
  const _handleMavenProcessStart = () => setMavensStarted(true)

  const _handleFinish = () => {
    startPostValidation(selection[0]?.code, 'db_match');
    setProcessFinished(true);
  }

  // =========================
  // Tree methods
  // =========================

  const _handleUpdate = () => {
    if (mavensStarted) return;
    updateTree(currentTree?.tree?.id, { status: 'completed', maven_id: selected });
  }

  const _handleFieldAll = () => {
    const desolates = matches.filter(match => !match?.matches?.length);
    _handleField(desolates.map(({ tree }) => tree));
  }
  const _handleField = (tree = currentTree, comment) => {
    if (mavensStarted) return updateMavenTree(Array.isArray(tree) ? tree.map(tree => tree?.tree?.id) : tree?.tree?.id, { sent_to_field: true });
    updateTree(Array.isArray(tree) ? tree : tree?.tree?.id, { status: 'sent_to_field', comment, maven_id: selected });
  }

  const _handleSkip = (direction = 1) => () => setCurrentIndex((currentIndex + direction + trees.length) % trees.length);

  // Set selected match
  const _handleSelect = (val) => setSelected(val);

  // =========================
  // Lifecycle
  // =========================

  // Set sources each time todos or mavens change
  useEffect(() => {
    setSources({ todos, mavens }, map, currentTree, !mavensStarted);
    if (todos.length === 0 && loaded) setRTMSFinished(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todos?.length, mavens?.length, mavensLoaded]);
  
  // Set sources After theme change
  useEffect(() => {
    // TODO: clean up this mess and implement a data persistance system
    setTimeout(() => setSources({ todos, mavens }, map, currentTree, !mavensStarted), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDark]);

  // Regenerate connections when girth or maxdistance change
  useEffect(() => {
    generateConnections(map?.current, currentTree);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTree?.matches?.length]);

  // Current tree changed
  useEffect(() => {
    if (currentTree?.tree?.id) setCurrentTreeId(currentTree.tree.id);
    if (currentTree?.matches?.[0]?.tree?.id) setSelected(currentTree?.matches?.[0]?.tree?.id);
    _handleTreeFocus(currentTree?.tree);
    if (currentTree?.tree?.id) _handlePanorama();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTree?.tree?.id]);

  return (
    <LoaderWrapper loading={!trees.length}>
      <Finished 
        done={processFinished}
        finished={RTMSFinished && !mavensStarted || (!todos?.length && trees.length && !mavens.length)} 
        title={mavens.length && 'All RTMS trees finished'}
        action={{
          label: mavens.length ? 'Start maven trees' : 'Finish process',
          onClick: mavens.length ? _handleMavenProcessStart : _handleFinish,
        }}
      >
        <Grid tabcontent={<DBMatchControls { ...{ girthDifference, maxDistance, setGirthDifferece, setMaxDistance, onSendAllToField: _handleFieldAll }} />}>
          <div className={`database-matching-wrapper ${(RTMSFinished || mavensStarted) ? 'mavens' : ''}`}>
            <div className="vertical-split">
              <PanoramicView 
                selTree={currentTree?.tree || null}
                trees={mavenTrees.concat(trees).map(tree => ({ ...tree, geometry: { ...tree.geometry, coordinates: proj4("EPSG:4326", "EPSG:3414", tree?.location?.coordinates?.slice(0) || [])}}))}
                capturePoint={capturePoint}
                images={panoramaImages}
                cameras={panoramaImages}
                getConfig={getConfig}
                connections={generateConnectionFeatures(currentTree)}
                activeConnection={parseInt(`${currentTree?.tree?.id}${selected}`)}
                blockLoader={!trees.length}
                background={isDark ? 0x000000 : 0xf8f8f8}
              />
              <Map
                _ref={map}
                sources={TREE_SOURCES}
                onFocusReset={_handleTreeFocus}
                layers={layers.current || []}
                active={{
                  db_match_todo: currentTree?.tree?.id,
                  connections: parseInt(`${currentTree?.tree?.id}${selected}`),
                  maven: mavensStarted ? currentTree?.tree?.id : selected
                }}
                sourceVisible={{ trees: true }}
                layerVisible={{
                  maven: true,
                  db_match_todo: true,
                  connections: true,
                }}
              />
            </div>
            {
              !RTMSFinished && (
                <Matching
                  source={currentTree?.tree}
                  matches={currentTree?.matches?.map((match) => ({
                    ...match,
                    ...match?.tree,
                  }))}
                  onCurrentClick={_handleTreeFocus}
                  selected={selected}
                  onSelect={_handleSelect}
                />
              )
            }
            <ProgressControls
              max={mavensStarted ? mavenTrees?.length : trees?.length}
              value={(mavensStarted ? mavenTrees?.length : trees.length) - treeList.length}
              actions={validationActions({
                tree: currentTree,
                updateTree: _handleUpdate,
                sendToField: _handleField,
                onSkip: _handleSkip,
                numberOfTrees: treeList?.length,
                presentModal,
                dismissModal,
                deleteHidden: true,
                saveDisabled: !selected && currentTree?.matches?.length > 0 || mavensStarted,
              })}
            />
          </div>
        </Grid>
      </Finished>
    </LoaderWrapper>
  );
};

export default DatabaseMatching;