import React, { createContext, useContext, useState } from 'react';

const STORAGE_KEY = '@configv2.1';

export const Context = createContext({ });

const defaultConfig = JSON.stringify({
  colors: {
    maven: '#a103fc',
    field: '#914b00',
    location_validation: '#ff003c',
    location_validated: '#ff9900',
    semantic_validation: '#0020ff',
    semantic_validated: '#fff200',
    dbmatch: '#1e7800',
    connection: '#7fb',
    completed: '#00ffa6',
    error: '#f20',
    processing: '#ccc',
    semantic_marker: '#f82'
  },
  categories: {
    tree_status: {
      label: 'Tree status'
    },
    pipeline_status: {
      label: 'Pipelines'
    },
    dbmatch: {
      label: 'DB matching'
    }
  },
  statuses: {
    maven: {
      category: 'tree_status',
      label: 'MAVEN II',
      color: 'maven'
    },
    location_validation: {
      category: 'tree_status',
      label: 'Location validation',
      color: 'location_validation'
    },
    semantic_validation: {
      category: 'tree_status',
      label: 'Semantic validation',
      color: 'semantic_validation'
    },
    db_match: {
      category: 'tree_status',
      label: 'DB matching',
      color: 'dbmatch'
    },
    location_validation_todo: {
      category: 'tree_status',
      label: 'Location proposal',
      color: 'location_validation'
    },
    location_validation_done: {
      category: 'tree_status',
      label: 'Validated location',
      color: 'location_validated',
    },
    semantic_validation_todo: {
      category: 'tree_status',
      label: 'Semantic proposal',
      color: 'semantic_validation'
    },
    semantic_validation_done: {
      category: 'tree_status',
      label: 'Validated semantic',
      color: 'semantic_validated',
    },
    db_match_todo: {
      category: 'tree_status',
      label: 'DB match proposal',
      color: 'dbmatch'
    },
    completed: {
      category: 'tree_status',
      label: 'Completed',
      color: 'completed'
    },
    sent_to_field: {
      category: 'tree_status',
      label: 'Sent to field',
      color: 'field'
    },
    ERROR: {
      category: 'pipeline_status',
      label: 'Error',
      color: 'error'
    },
    PROCESSING: {
      category: 'pipeline_status',
      label: 'Processing',
      color: 'processing'
    },
    connections: {
      category: 'dbmatch',
      label: 'Connections',
      color: 'connection'
    }
  }
});

// Access to context
export const useConfig = () => useContext(Context);
export const withConfig = (Component) => props => <Context.Consumer>{theme => <Component { ...props } theme={theme} />}</Context.Consumer>;

export const useStaticConfig = () => {
  const config = { ...JSON.parse(defaultConfig), ...JSON.parse(localStorage.getItem(STORAGE_KEY) || defaultConfig) };

  const getConfig = (key) => Object.byPath(config, key);
  return {
    config, 
    getConfig
  }
}

export const Provider = (props) => {
  const localConfig = JSON.parse(localStorage.getItem(STORAGE_KEY) || defaultConfig);
  const [config, setConfig] = useState({ ...JSON.parse(defaultConfig), ...localConfig });

  const _handleSetUpdate = (config) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(config));
    setConfig(config);
  }

  const _handleUpdate = (key, value) => {
    const _config = { ...config };
    _handleSetUpdate(_config)
  }

  const _handleGetConfig = (key) => Object.byPath(config, key);

  return (
    <Context.Provider value={{ config, setConfig: _handleSetUpdate, updateConfig: _handleUpdate, getConfig: _handleGetConfig }}>
      { props.children }
    </Context.Provider>
  )
};