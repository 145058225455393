import React, { PureComponent } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

class MovableTree extends PureComponent {
  constructor(props) {
    super(props);

    this.moveStart = new THREE.Vector2();
    this.moveEnd = new THREE.Vector2();
    this.moveDelta = new THREE.Vector2();
    this.moveSpeed = 0.003;

    this.plane = new THREE.Plane();
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    this.intersection = new THREE.Vector3();

    this.scene = new THREE.Scene();
    this.scene.up.set(0, 0, 1);
    this.scene.background = new THREE.Color(this.props.background || 0x000000);

    this.axesHelper = new THREE.AxesHelper(5);
    this.axesHelper.visible = false;
    this.scene.add(this.axesHelper);

    {
      const geometry = this.props.isUp ? new THREE.CylinderGeometry(MovableTree.sphereSize, MovableTree.sphereSize, 49, 32) : new THREE.SphereGeometry(MovableTree.sphereSize, 32, 32);
      const material = new THREE.MeshBasicMaterial({ color: MovableTree.sphereColor });

      if (this.props.isUp) {
        material.transparent = true;
        material.opacity = 0.9;
      }
  
      this.sphere = new THREE.Mesh(
        geometry,
        material,
      );
      if (this.props.isUp) this.sphere.rotateX(Math.PI / 2);
      this.scene.add(this.sphere);
    }

    window.addEventListener('resize', () => this.updateRatio());

    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.updateRatio();
      }
    });

    this.state = {
      moving: false,
    };
  }

  static frustumSize = 8;
  static sphereSize = 0.2;
  static sphereColor = 0xff0000;
  static minZoom = 0.2;
  static maxZoom = 7;

  componentDidMount = () => {
    this.resizeObserver.observe(this.container);

    this.cameras = {
      PERSPECTIVE: new THREE.PerspectiveCamera(
        60,
        5 / 2,
        0.1,
        1000,
      ),
      ORTHOGRAPHIC: new THREE.OrthographicCamera(
        -0.5 * MovableTree.frustumSize * 5/2,
        0.5 * MovableTree.frustumSize * 5/2,
        0.5 * MovableTree.frustumSize,
        -0.5 * MovableTree.frustumSize, 
        0.1,
        1000
      ),
    }

    Object.values(this.cameras).forEach((camera) => {
      camera.up.set(0, 0, 1);
      camera.updateProjectionMatrix();
    });
    this.camera = this.cameras.ORTHOGRAPHIC;

    this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas: this.canvas });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.updateRatio();

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enablePan = true;
    this.controls.rotateSpeed = 2.0;
    this.controls.minZoom = MovableTree.minZoom;
    this.controls.maxZoom = MovableTree.maxZoom;
    // this.controls.zoomSpeed = 0.5;
    // this.controls.staticMoving = true;

    this.controls.addEventListener('change', () => this.repaint());
    this.controls.enableRotate = true;

    if (this.props.isUp) {
      this.controls.minPolarAngle = 0;
      this.controls.maxPolarAngle = 0;
    } else {
      this.controls.minPolarAngle = Math.PI / 2;
      this.controls.maxPolarAngle = Math.PI / 2;
    }
    this.controls.update();

    this.updateFromProps({});
  }

  updateRatio = () => {
    const container = this.container || document.getElementById(this.scene.uuid);

    if (!this.container) return console.warn('this.container is undefined, this is probably due to the hot reloader', this);
    if (!container) return console.error('No container has been found for MovableTree.', this);

    this.aspectRatio = container.clientWidth / container.clientHeight;
    this.renderer.setSize(container.clientWidth, container.clientHeight);

    this.cameras.ORTHOGRAPHIC.left = - 0.5 * MovableTree.frustumSize * this.aspectRatio;
    this.cameras.ORTHOGRAPHIC.right = 0.5 * MovableTree.frustumSize * this.aspectRatio;
    this.cameras.ORTHOGRAPHIC.top = 0.5 * MovableTree.frustumSize;
    this.cameras.ORTHOGRAPHIC.bottom = - 0.5 * MovableTree.frustumSize;
    if (this.cameras.ORTHOGRAPHIC.updateProjectionMatrix) this.cameras.ORTHOGRAPHIC.updateProjectionMatrix();

    this.cameras.PERSPECTIVE = this.aspectRatio;
    if (this.cameras.PERSPECTIVE.updateProjectionMatrix) this.cameras.PERSPECTIVE.updateProjectionMatrix();

    if (this.controls) this.controls.update();

    requestAnimationFrame(this.repaint);
  }

  updateFromProps = (props) => {
    this.sphere.position.copy(this.props.position);
    this.axesHelper.position.copy(this.sphere.position);

    this.axesHelper.visible = this.props.isNew;

    if (props.background !== this.props.background) {
      this.scene.background = new THREE.Color(this.props.background || 0x000000);
      requestAnimationFrame(this.repaint);
    }
    if (props.pointcloud !== this.props.pointcloud || (!props.position && this.props.position) || (Number.isNaN(props.position.x) && !Number.isNaN(this.props.position.x))) {
      this.sphere.position.copy(this.props.position);

      if (this.points) {
        this.points.geometry.dispose();
        this.points.material.dispose();
        this.scene.remove(this.points);
      }

      let boundingSphere;
      // pointcloud can be false
      if (this.props.pointcloud) {
        this.pointcloud = this.props.pointcloud;

        const points = new THREE.Points(this.props.pointcloud.geometry, this.props.pointcloud.createMaterial());
        this.points = points; 
        this.points.up.set(0, 0, 1);

        this.scene.add(this.points);
        this.controls.update();
        requestAnimationFrame(this.repaint);

        boundingSphere = points.geometry.boundingSphere;
      } else {
        boundingSphere = new THREE.Sphere(
          new THREE.Vector3().copy(this.sphere.position),
          8,
        );
      }

      if (this.controls) {
        this.controls.target.set(
          this.sphere.position.x, 
          this.sphere.position.y, 
          this.sphere.position.z + 2,
        );
      }

      this.axesHelper.position.copy(this.sphere.position);

      if (this.props.isUp) {
        this.camera.position.set(
          this.sphere.position.x,
          this.sphere.position.y,
          this.sphere.position.z + 50,
        );
        this.camera.lookAt(
          new THREE.Vector3(
            this.sphere.position.x,
            this.sphere.position.y,
            this.sphere.position.z,
          )
        );
      } else {
        this.camera.position.set(
          this.sphere.position.x + boundingSphere.radius * 2,
          this.sphere.position.y + boundingSphere.radius * 2,
          this.sphere.position.z,
        );
        this.camera.lookAt(
          new THREE.Vector3(
            this.sphere.position.x,
            this.sphere.position.y,
            this.sphere.position.z,
          )
        );
      }
      this.controls.update();
      requestAnimationFrame(this.repaint);
    } 
  }

  componentDidUpdate = prevProps => this.updateFromProps(prevProps)
  
  repaint = () => {
    const scale = 1 / this.camera.zoom;
    if (this.props.isUp) this.sphere.scale.set(scale, 1, scale);
    else this.sphere.scale.set(scale, scale, scale);

    this.forceUpdate();
  }

  handlePointerDown = (e) => {
    e.preventDefault();

    this.moving = true;

    this.plane.setFromNormalAndCoplanarPoint(
      this.props.isUp ? new THREE.Vector3(0, 0, 1) : this.camera.position.clone().normalize(),
      this.sphere.position,
    );

    this.setState({ moving: true });
  }

  handlePointerUp = (e) => {
    e.preventDefault();

    this.setState({ moving: false });
  }

  handlePointerMove = (e) => {
    if (!this.state.moving) return;
    if (!e.ctrlKey) return;

    e.preventDefault();
    e.stopPropagation();

    const rect = this.container.getBoundingClientRect();
    this.mouse.x =  ((e.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;
    
    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.ray.intersectPlane(this.plane, this.intersection);

    if (this.props.isUp) {
      this.props.setPosition([
        this.intersection.x,
        this.intersection.y,
        this.sphere.position.z,
      ]);
    } else {
      this.props.setPosition([
        this.sphere.position.x,
        this.sphere.position.y,
        this.intersection.z,
      ]);
    }
  }

  render() {
    this.sphere.position.copy(this.props.position);

    if (this.renderer) this.renderer.render(this.scene, this.camera);

    return (
      <div 
        className='tree-mover'
        id={this.scene && this.scene.uuid}
        ref={e => this.container = e} 
        style={{ cursor: this.state.moving ? 'grabbing' : 'grab', position: 'relative' }}
        onPointerDown={this.handlePointerDown}
        onPointerMove={this.handlePointerMove}
        onPointerUp={this.handlePointerUp}
      >
        {this.props.error ? <p style={{ position: 'absolute', zIndex: 100, top: 0, left: 10, color: 'white' }}>No pointcloud is available.</p> : null}
        <canvas 
          ref={e => this.canvas = e} 
          style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}
          // style={{ width: '100%', height: '100%' }} 
        />
      </div>
    );
  }
}

export default MovableTree;