import React, { useEffect } from 'react';
import Icon from './Icon';
import Popup from './Popup';
import { useStaticConfig } from '../providers/config';
import { Button } from './inputs';

const round = (val, unit) => `${(Math.round(val * 100) / 100) || '–'} ${unit || ''}`;

const details = {
  sent_to_field: [
    {
      label: 'RTMS ID',
      resolver: 'id'
    },
    {
      label: 'Comment',
      resolver: ({ properties }) => properties?.comment || '-'
    },
  ],
  'location_validation_todo|location_validation_done': [
    {
      label: 'RTMS ID',
      resolver: 'id'
    },
    {
      label: 'Confidence level',
      resolver: ({ properties }) => round(properties?.status === 'location_validation_done' ? 100 : properties?.location_confidence * 100, '%')
    },
  ],
  'db_match_todo|semantic_validation_todo|semantic_validation_done': [
    {
      label: 'RTMS ID',
      resolver: 'id'
    },
    {
      label: 'Girth',
      resolver: ({ properties }) => round(properties?.girth_1_0m, 'm')
    },
    {
      label: 'Height',
      resolver: ({ properties }) => round(properties?.height, 'm')
    },
  ],
  'completed': [
    {
      label: 'ID',
      resolver: 'id'
    },
    {
      label: 'height',
      resolver: ({ properties }) => properties?.height + ' m'
    },
    {
      label: 'girth',
      resolver: ({ properties }) => round(properties?.grth_size, 'm')
    },
    {
      label: 'scientific name',
      resolver: ({ properties }) => properties.common_nm || '–'
    },
  ],
  'maven': [
    {
      label: 'ID',
      resolver: 'properties.tree_id'
    },
    {
      label: 'scientific name',
      resolver: ({ properties }) => properties.common_nm || '–'
    },
    {
      label: 'height',
      resolver: ({ properties }) => properties?.height + ' m'
    },
    {
      label: 'girth',
      resolver: ({ properties }) => round(properties?.grth_size, 'm')
    },
  ]
}

const TreePopup = ({ tree, onClose, isDetailsVisible }) => {
  const { getConfig } = useStaticConfig();

  const treeStatus = tree.properties?.status || 'maven'; // Safe to assume that every RTMS tree has status
  const status = getConfig(`statuses.${tree.properties.status}`);

  const configKey = Object.keys(details).find(key => key.split('|').includes(treeStatus));
  const config = details?.[configKey];

  const _handleDetails = () => {
    console.log('Handling details')
    window?._openTreeDetails(tree.id);
  }

  useEffect(() => {
    console.log('ALMA')
  }, [])

  return (
    <div className="tree-popup custom-popup-wrapper">
      <div className="close-wrapper" id='popup-close-wrapper' >
        <Icon icon='times' />
      </div>
      {
        config
        ? config?.map(conf => <Detail label={conf.label} key={conf.resolver} value={Object.byResolver(tree, conf.resolver)} />)
        : <span className="empty">No configuration found for status: {status?.label || treeStatus}</span>
      }
      { isDetailsVisible && <Button primary id={`tree-details-handler`} label='Details' /> }
    </div>
  )
}

const Detail = ({ label, value }) => {
  return (
    <div className="detail-wrapper">
      <span className="label">{ label }</span>
      <span className="value">{ value }</span>
    </div>
  )
}

export default TreePopup;
