import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import Navigation from './routes';
import reportWebVitals from './reportWebVitals';
import Providers from './providers';
import './core/library';
import proj4 from 'proj4';

// Defining proj4 projections
proj4.defs("EPSG:4326","+proj=longlat +datum=WGS84 +no_defs");
proj4.defs("EPSG:3414","+proj=tmerc +lat_0=1.366666666666667 +lon_0=103.8333333333333 +k=1 +x_0=28001.642 +y_0=38744.572 +ellps=WGS84 +units=m +no_defs");

// Prototypes    
String.prototype.toPath = function() {
  return this.split(/\[([^}]+)\]|\./g).filter(segment => !!segment);
}

Object.byPath = function(object, path) {
  path = Array.isArray(path) ? path : String(path).toPath();
  return path.reduce((prev, key) => (prev && prev[key]) || null, object);
};

Object.byResolver = function(object, resolver) {
  return typeof(resolver) === 'function' ? resolver(object) : Object.byPath(object, resolver);
};

Object.toKebab = function(object) {
  return Object.keys(object).reduce((prev, key) => ({ ...prev, [key.split(/(?=[A-Z])/).join('-').toLowerCase()]: object[key]}), {})
};

Array.groupByTwo = function(array) {
  return array.reduce((acc, val, ind, array) => {
    const { data, prev } = acc;
    if (prev) data.push([prev, val])

    return { data, prev: prev ? null : val }
  }, { data: [], prev: null }).data;
};

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Navigation />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
