export const voxelJSON = {
  TREE_PARA: {
    IDENTITY: {
      GUID: '3',
      BASE_COORD: '29495.73,38300.43,9.07',
      TRANS_VEC: '29495.73,38300.43,9.07',
      SCAN_DATE: '2011-09-14',
    },
    SIZE: {
      TREE_HEIGHT: '18.64',
      GIRTH: {
        GIRTH_10: '1.52',
        GIRTH_13: '1.45',
        GIRTHS: '',
      },
      TRUNK: {
        TRUNK_H: '1.39',
        ROOT_ABOVE: '',
        TRUNK_CENTROID: 'NaN,NaN,NaN',
        TVEC_YAW: '',
        TVEC_PITCH: '',
      },
      CROWN: {
        CROWN_W: '9.79',
        CROWN_H: '17.25',
        CROWN_NORMAL: '-.72,-.7,',
        CROWN_CENTROID: 'NaN,NaN,NaN',
        CROWN_PRES_AREA: '',
        ELLIPSE_A: '7.56',
        ELLIPSE_B: '11.28',
        DIRECTION: '88.1',
        CROWN_EXC_DX: '-.27',
        CROWN_EXC_DY: '1.08',
      },
    },
    GROWTH_SPACE: {
      B_VOXELS: {
        VOX_SZ: '.2',
        MIN_PT: '5',
        COORDS: {
          COORD: [
            '-16,-7,62',
            '-16,-7,64',
            '-16,-7,57',
            '-16,-7,61',
            '-16,-7,63',
            '-16,-7,56',
            '-17,-7,64',
            '-15,-7,61',
            '-15,-7,62',
            '-15,-7,60',
            '-7,-7,74',
            '-7,-7,75',
            '-7,-7,73',
            '-7,-7,72',
            '-8,-7,74',
            '-8,-7,75',
            '-3,-7,47',
            '-3,-7,69',
            '-3,-7,70',
            '-3,-7,68',
            '-3,-7,46',
            '-3,-7,71',
            '-3,-7,67',
            '-4,-7,69',
            '-4,-7,70',
            '-4,-7,68',
            '-4,-7,67',
            '-4,-7,71',
            '1,-7,53',
            '1,-7,54',
            '1,-7,55',
            '1,-7,52',
            '2,-7,53',
            '2,-7,55',
            '2,-7,54',
            '2,-7,52',
            '3,-7,72',
            '3,-7,73',
            '3,-7,74',
            '3,-7,71',
            '3,-7,69',
            '3,-7,70',
            '4,-7,72',
            '4,-7,73',
            '4,-7,74',
            '7,-7,42',
            '7,-7,43',
            '7,-7,41',
            '7,-7,44',
            '7,-7,40',
            '9,-7,51',
            '9,-7,50',
            '9,-7,45',
            '9,-7,49',
            '9,-7,48',
            '9,-7,47',
            '9,-7,52',
            '10,-7,52',
            '10,-7,51',
            '10,-7,50',
            '10,-7,49',
            '10,-7,53',
            '10,-7,65',
            '10,-7,66',
            '10,-7,54',
            '10,-7,67',
            '10,-7,46',
            '11,-7,67',
            '11,-7,65',
            '11,-7,69',
            '11,-7,68',
            '11,-7,47',
            '11,-7,66',
            '11,-7,53',
            '11,-7,46',
            '12,-7,47',
            '13,-7,48',
            '16,-7,52',
            '16,-7,51',
            '15,-7,50',
            '15,-7,51',
            '15,-7,52',
            '14,-7,49',
            '14,-7,50',
            '14,-7,48',
            '-17,-8,65',
            '-17,-8,64',
            '-17,-8,66',
            '-16,-8,64',
            '-8,-8,75',
            '-8,-8,76',
            '-8,-8,77',
            '-7,-8,75',
            '-4,-8,71',
            '-4,-8,72',
            '-4,-8,70',
            '-4,-8,69',
            '-3,-8,72',
            '-3,-8,71',
            '-3,-8,46',
            '-3,-8,47',
            '-3,-8,70',
            '2,-8,55',
            '2,-8,56',
            '2,-8,54',
            '2,-8,57',
            '1,-8,55',
            '1,-8,56',
            '1,-8,54',
            '3,-8,76',
            '3,-8,74',
            '3,-8,75',
            '3,-8,73',
            '4,-8,75',
            '4,-8,74',
            '4,-8,76',
            '5,-8,74',
            '5,-8,75',
            '7,-8,44',
            '7,-8,43',
            '7,-8,42',
            '10,-8,53',
            '10,-8,51',
            '10,-8,49',
            '10,-8,52',
            '10,-8,54',
            '10,-8,67',
            '10,-8,68',
            '10,-8,69',
            '9,-8,52',
            '9,-8,51',
            '9,-8,53',
            '9,-8,45',
            '9,-8,49',
            '9,-8,50',
            '11,-8,52',
            '11,-8,69',
            '11,-8,68',
            '11,-8,53',
            '16,-8,53',
            '16,-8,52',
            '18,-8,55',
            '18,-8,54',
            '17,-8,54',
            '17,-8,53',
            '19,-8,55',
            '15,-8,53',
            '15,-8,52',
            '-17,-9,65',
            '-17,-9,66',
            '-9,-9,59',
            '-9,-9,58',
            '-11,-9,60',
            '-8,-9,76',
            '-8,-9,77',
            '-4,-9,73',
            '-4,-9,72',
            '-4,-9,74',
            '-3,-9,51',
            '-3,-9,49',
            '-3,-9,50',
            '-3,-9,52',
            '-5,-9,73',
            '2,-9,57',
            '2,-9,56',
            '2,-9,59',
            '2,-9,58',
            '2,-9,55',
            '1,-9,56',
            '3,-9,57',
            '3,-9,76',
            '3,-9,77',
            '3,-9,56',
            '4,-9,76',
            '4,-9,77',
            '7,-9,45',
            '7,-9,44',
            '7,-9,43',
            '8,-9,47',
            '8,-9,46',
            '9,-9,45',
            '10,-9,52',
            '10,-9,53',
            '11,-9,53',
            '13,-9,56',
            '14,-9,56',
            '14,-9,57',
            '-11,-15,63',
            '-11,-15,64',
            '-10,-15,63',
            '-10,-15,64',
            '-12,-15,63',
            '-12,-15,64',
            '5,-15,66',
            '5,-15,65',
            '5,-15,64',
            '4,-15,64',
            '4,-15,65',
            '4,-15,63',
            '6,-15,67',
            '-11,-14,62',
            '-11,-14,63',
            '-7,-14,76',
            '2,-14,71',
            '2,-14,70',
            '4,-14,64',
            '4,-14,63',
            '4,-14,65',
            '4,-14,62',
            '5,-14,65',
            '5,-14,64',
            '5,-14,63',
            '13,-14,59',
            '13,-14,58',
            '12,-14,58',
            '11,-14,66',
            '11,-14,67',
            '10,-14,66',
            '-12,-16,63',
            '-12,-16,64',
            '-10,-16,64',
            '-10,-16,63',
            '-11,-16,63',
            '-11,-16,64',
            '4,-16,64',
            '4,-16,65',
            '5,-16,66',
            '5,-16,67',
            '5,-16,68',
            '5,-16,65',
            '6,-16,67',
            '6,-16,68',
            '-10,-10,59',
            '-10,-10,60',
            '-9,-10,59',
            '-9,-10,58',
            '-9,-10,60',
            '-3,-10,52',
            '-3,-10,51',
            '-3,-10,53',
            '-3,-10,54',
            '-3,-10,50',
            '-3,-10,49',
            '-4,-10,74',
            '-4,-10,75',
            '-4,-10,73',
            '-5,-10,74',
            '-5,-10,73',
            '2,-10,58',
            '2,-10,60',
            '2,-10,59',
            '2,-10,57',
            '2,-10,56',
            '1,-10,59',
            '3,-10,58',
            '3,-10,57',
            '4,-10,58',
            '4,-10,59',
            '4,-10,77',
            '8,-10,46',
            '8,-10,45',
            '7,-10,46',
            '7,-10,45',
            '7,-10,44',
            '14,-10,56',
            '14,-10,57',
            '13,-10,56',
            '16,-10,58',
            '16,-10,59',
            '16,-10,60',
            '15,-10,58',
            '15,-10,57',
            '15,-10,59',
            '-10,-11,59',
            '-10,-11,60',
            '-11,-11,60',
            '-3,-11,52',
            '-3,-11,53',
            '-3,-11,54',
            '-3,-11,51',
            '-5,-11,74',
            '-4,-11,74',
            '-4,-11,75',
            '1,-11,60',
            '1,-11,61',
            '1,-11,62',
            '1,-11,63',
            '1,-11,59',
            '2,-11,59',
            '2,-11,61',
            '2,-11,60',
            '2,-11,62',
            '2,-11,63',
            '2,-11,64',
            '2,-11,58',
            '4,-11,58',
            '4,-11,60',
            '4,-11,59',
            '3,-11,59',
            '3,-11,58',
            '3,-11,57',
            '8,-11,46',
            '8,-11,47',
            '7,-11,46',
            '7,-11,45',
            '7,-11,47',
            '16,-11,59',
            '-10,-13,61',
            '-10,-13,62',
            '-11,-13,62',
            '-11,-13,61',
            '-7,-13,75',
            '-7,-13,76',
            '-6,-13,76',
            '-6,-13,75',
            '-4,-13,58',
            '-3,-13,58',
            '-3,-13,57',
            '2,-13,65',
            '2,-13,69',
            '2,-13,66',
            '2,-13,68',
            '2,-13,70',
            '2,-13,67',
            '2,-13,71',
            '5,-13,62',
            '4,-13,62',
            '4,-13,63',
            '4,-13,61',
            '4,-13,64',
            '6,-13,62',
            '6,-13,63',
            '3,-13,69',
            '3,-13,66',
            '3,-13,67',
            '3,-13,68',
            '3,-13,70',
            '3,-13,61',
            '7,-13,63',
            '9,-13,64',
            '9,-13,65',
            '8,-13,64',
            '8,-13,63',
            '10,-13,65',
            '10,-13,66',
            '12,-13,57',
            '12,-13,58',
            '11,-13,66',
            '13,-13,58',
            '-10,-12,61',
            '-10,-12,60',
            '-10,-12,62',
            '-11,-12,61',
            '-6,-12,76',
            '-3,-12,54',
            '-3,-12,55',
            '-3,-12,53',
            '-3,-12,58',
            '-4,-12,74',
            '2,-12,63',
            '2,-12,62',
            '2,-12,64',
            '2,-12,65',
            '2,-12,66',
            '2,-12,68',
            '2,-12,67',
            '2,-12,69',
            '1,-12,63',
            '1,-12,62',
            '4,-12,61',
            '4,-12,60',
            '4,-12,62',
            '4,-12,59',
            '3,-12,58',
            '3,-12,66',
            '3,-12,61',
            '3,-12,59',
            '3,-12,60',
            '3,-12,69',
            '3,-12,68',
            '3,-12,67',
            '5,-12,62',
            '5,-12,61',
            '5,-12,60',
            '6,-12,63',
            '7,-12,63',
            '7,-12,47',
            '12,-12,57',
            '13,-12,59',
            '-18,-4,46',
            '-18,-4,47',
            '-18,-4,45',
            '-19,-4,48',
            '-19,-4,47',
            '-19,-4,49',
            '-14,-4,42',
            '-15,-4,42',
            '-13,-4,55',
            '-10,-4,51',
            '-10,-4,50',
            '-10,-4,52',
            '-9,-4,50',
            '-9,-4,78',
            '-9,-4,51',
            '-9,-4,77',
            '-9,-4,49',
            '-11,-4,52',
            '-11,-4,51',
            '-6,-4,46',
            '-6,-4,45',
            '-6,-4,76',
            '-6,-4,75',
            '-6,-4,78',
            '-6,-4,77',
            '-6,-4,47',
            '-7,-4,47',
            '-7,-4,48',
            '-7,-4,49',
            '-7,-4,46',
            '-7,-4,72',
            '-8,-4,49',
            '-8,-4,48',
            '-8,-4,50',
            '-5,-4,75',
            '-5,-4,45',
            '-5,-4,76',
            '-5,-4,53',
            '-5,-4,65',
            '-5,-4,52',
            '-5,-4,66',
            '-5,-4,43',
            '-5,-4,63',
            '-5,-4,64',
            '-5,-4,62',
            '-3,-4,41',
            '-3,-4,51',
            '-3,-4,42',
            '-3,-4,50',
            '-3,-4,39',
            '-3,-4,52',
            '-3,-4,34',
            '-3,-4,60',
            '-3,-4,43',
            '-3,-4,53',
            '-3,-4,59',
            '-3,-4,58',
            '-3,-4,57',
            '-3,-4,40',
            '-3,-4,38',
            '-3,-4,56',
            '-3,-4,30',
            '-3,-4,54',
            '-3,-4,49',
            '-3,-4,37',
            '-3,-4,33',
            '-3,-4,35',
            '-3,-4,55',
            '-3,-4,29',
            '-3,-4,32',
            '-4,-4,63',
            '-4,-4,53',
            '-4,-4,52',
            '-4,-4,61',
            '-4,-4,60',
            '-4,-4,51',
            '-4,-4,56',
            '-4,-4,57',
            '-4,-4,55',
            '-4,-4,62',
            '-4,-4,42',
            '-4,-4,59',
            '-4,-4,64',
            '-4,-4,58',
            '-4,-4,65',
            '-4,-4,43',
            '-4,-4,54',
            '-1,-4,44',
            '-1,-4,45',
            '-1,-4,43',
            '-1,-4,46',
            '-1,-4,74',
            '-1,-4,75',
            '-1,-4,73',
            '-1,-4,47',
            '-2,-4,43',
            '-2,-4,44',
            '-2,-4,42',
            '-2,-4,45',
            '-2,-4,35',
            '-2,-4,34',
            '0,-4,47',
            '0,-4,46',
            '0,-4,48',
            '0,-4,45',
            '0,-4,75',
            '0,-4,74',
            '0,-4,49',
            '2,-4,64',
            '2,-4,63',
            '2,-4,65',
            '2,-4,66',
            '2,-4,62',
            '1,-4,62',
            '1,-4,48',
            '1,-4,63',
            '1,-4,61',
            '1,-4,49',
            '5,-4,71',
            '5,-4,34',
            '5,-4,33',
            '5,-4,70',
            '5,-4,75',
            '5,-4,32',
            '5,-4,35',
            '5,-4,74',
            '5,-4,31',
            '5,-4,36',
            '5,-4,73',
            '5,-4,69',
            '6,-4,34',
            '6,-4,36',
            '6,-4,35',
            '6,-4,37',
            '6,-4,73',
            '6,-4,33',
            '6,-4,38',
            '3,-4,67',
            '3,-4,66',
            '3,-4,65',
            '4,-4,69',
            '4,-4,67',
            '4,-4,66',
            '4,-4,68',
            '4,-4,32',
            '4,-4,70',
            '4,-4,31',
            '7,-4,37',
            '7,-4,39',
            '7,-4,38',
            '7,-4,36',
            '7,-4,40',
            '7,-4,41',
            '7,-4,42',
            '9,-4,61',
            '9,-4,70',
            '9,-4,62',
            '9,-4,60',
            '8,-4,40',
            '8,-4,41',
            '8,-4,39',
            '8,-4,42',
            '10,-4,62',
            '10,-4,70',
            '10,-4,61',
            '-19,-3,48',
            '-19,-3,47',
            '-19,-3,49',
            '-18,-3,46',
            '-18,-3,45',
            '-18,-3,47',
            '-18,-3,48',
            '-16,-3,64',
            '-17,-3,64',
            '-15,-3,42',
            '-15,-3,62',
            '-15,-3,63',
            '-15,-3,64',
            '-14,-3,42',
            '-14,-3,41',
            '-14,-3,61',
            '-14,-3,62',
            '-13,-3,60',
            '-13,-3,61',
            '-13,-3,59',
            '-12,-3,59',
            '-12,-3,38',
            '-12,-3,37',
            '-12,-3,36',
            '-12,-3,58',
            '-11,-3,36',
            '-11,-3,37',
            '-11,-3,38',
            '-11,-3,58',
            '-11,-3,57',
            '-10,-3,35',
            '-10,-3,36',
            '-10,-3,34',
            '-10,-3,37',
            '-10,-3,33',
            '-10,-3,57',
            '-9,-3,32',
            '-9,-3,33',
            '-9,-3,31',
            '-5,-3,44',
            '-5,-3,45',
            '-5,-3,43',
            '-5,-3,46',
            '-5,-3,42',
            '-6,-3,46',
            '-6,-3,45',
            '-6,-3,77',
            '-6,-3,78',
            '-6,-3,44',
            '-2,-3,28',
            '-2,-3,43',
            '-2,-3,35',
            '-2,-3,30',
            '-2,-3,32',
            '-2,-3,29',
            '-2,-3,33',
            '-2,-3,37',
            '-2,-3,42',
            '-2,-3,31',
            '-2,-3,36',
            '-2,-3,41',
            '-2,-3,40',
            '-2,-3,34',
            '-2,-3,44',
            '-2,-3,26',
            '-2,-3,39',
            '-2,-3,38',
            '-2,-3,27',
            '-2,-3,50',
            '-2,-3,25',
            '-2,-3,49',
            '-2,-3,45',
            '-2,-3,48',
            '-2,-3,47',
            '-2,-3,24',
            '-2,-3,9',
            '-2,-3,46',
            '-2,-3,23',
            '-2,-3,10',
            '-2,-3,22',
            '-3,-3,30',
            '-3,-3,29',
            '-3,-3,39',
            '-3,-3,53',
            '-3,-3,36',
            '-3,-3,51',
            '-3,-3,37',
            '-3,-3,41',
            '-3,-3,40',
            '-3,-3,32',
            '-3,-3,42',
            '-3,-3,34',
            '-3,-3,38',
            '-3,-3,49',
            '-3,-3,33',
            '-3,-3,35',
            '-3,-3,50',
            '-3,-3,54',
            '-3,-3,31',
            '-3,-3,56',
            '-3,-3,57',
            '-3,-3,27',
            '-3,-3,48',
            '-3,-3,28',
            '-3,-3,52',
            '-3,-3,55',
            '-3,-3,58',
            '-3,-3,43',
            '-3,-3,26',
            '-3,-3,44',
            '-3,-3,25',
            '-3,-3,45',
            '-3,-3,47',
            '-3,-3,59',
            '-3,-3,24',
            '-3,-3,68',
            '-3,-3,46',
            '-3,-3,23',
            '-1,-3,44',
            '-1,-3,43',
            '-1,-3,45',
            '-1,-3,9',
            '-1,-3,10',
            '-1,-3,46',
            '-1,-3,58',
            '-4,-3,55',
            '-4,-3,58',
            '-4,-3,57',
            '-4,-3,52',
            '-4,-3,53',
            '-4,-3,59',
            '-4,-3,41',
            '-4,-3,43',
            '-4,-3,50',
            '-4,-3,56',
            '-4,-3,51',
            '-4,-3,37',
            '-4,-3,35',
            '-4,-3,54',
            '-4,-3,40',
            '-4,-3,34',
            '-4,-3,38',
            '-4,-3,39',
            '-4,-3,36',
            '-4,-3,42',
            '-4,-3,33',
            '-4,-3,44',
            '-4,-3,32',
            '2,-3,63',
            '2,-3,62',
            '2,-3,64',
            '2,-3,61',
            '1,-3,61',
            '1,-3,62',
            '1,-3,60',
            '1,-3,63',
            '1,-3,59',
            '0,-3,60',
            '0,-3,46',
            '0,-3,59',
            '0,-3,58',
            '5,-3,33',
            '5,-3,32',
            '5,-3,34',
            '5,-3,31',
            '5,-3,35',
            '5,-3,30',
            '4,-3,31',
            '4,-3,32',
            '4,-3,30',
            '4,-3,29',
            '4,-3,28',
            '6,-3,34',
            '6,-3,35',
            '6,-3,36',
            '6,-3,37',
            '6,-3,33',
            '3,-3,29',
            '3,-3,28',
            '3,-3,31',
            '9,-3,60',
            '9,-3,61',
            '9,-3,70',
            '9,-3,69',
            '9,-3,59',
            '9,-3,68',
            '10,-3,70',
            '10,-3,69',
            '7,-3,36',
            '7,-3,59',
            '8,-3,59',
            '8,-3,60',
            '-16,-6,57',
            '-16,-6,56',
            '-16,-6,62',
            '-16,-6,61',
            '-16,-6,60',
            '-15,-6,60',
            '-15,-6,59',
            '-15,-6,61',
            '-15,-6,58',
            '-15,-6,62',
            '-15,-6,56',
            '-14,-6,55',
            '-14,-6,56',
            '-14,-6,58',
            '-13,-6,55',
            '-13,-6,56',
            '-12,-6,78',
            '-12,-6,54',
            '-12,-6,77',
            '-10,-6,36',
            '-10,-6,75',
            '-11,-6,76',
            '-11,-6,75',
            '-9,-6,75',
            '-7,-6,73',
            '-7,-6,72',
            '-7,-6,71',
            '-6,-6,69',
            '-6,-6,71',
            '-6,-6,70',
            '-6,-6,72',
            '-6,-6,76',
            '-6,-6,54',
            '-6,-6,66',
            '-6,-6,65',
            '-6,-6,68',
            '-5,-6,65',
            '-5,-6,69',
            '-5,-6,54',
            '-5,-6,67',
            '-5,-6,66',
            '-5,-6,68',
            '-5,-6,53',
            '-5,-6,70',
            '-5,-6,77',
            '-8,-6,73',
            '-3,-6,46',
            '-3,-6,44',
            '-3,-6,45',
            '-3,-6,68',
            '-3,-6,54',
            '-3,-6,67',
            '-3,-6,47',
            '-3,-6,69',
            '-3,-6,66',
            '-4,-6,66',
            '-4,-6,65',
            '-4,-6,67',
            '-4,-6,54',
            '-4,-6,68',
            '-4,-6,69',
            '-2,-6,70',
            '-2,-6,69',
            '-2,-6,71',
            '-2,-6,68',
            '-1,-6,45',
            '-1,-6,72',
            '2,-6,52',
            '2,-6,53',
            '2,-6,54',
            '2,-6,51',
            '2,-6,68',
            '2,-6,67',
            '1,-6,50',
            '1,-6,51',
            '1,-6,53',
            '1,-6,52',
            '1,-6,49',
            '1,-6,54',
            '0,-6,49',
            '0,-6,50',
            '3,-6,69',
            '3,-6,70',
            '3,-6,71',
            '3,-6,72',
            '3,-6,66',
            '4,-6,72',
            '4,-6,71',
            '6,-6,74',
            '6,-6,75',
            '7,-6,39',
            '7,-6,41',
            '7,-6,40',
            '7,-6,74',
            '7,-6,43',
            '7,-6,45',
            '7,-6,75',
            '7,-6,42',
            '9,-6,45',
            '9,-6,47',
            '9,-6,48',
            '9,-6,49',
            '9,-6,46',
            '9,-6,44',
            '9,-6,50',
            '8,-6,45',
            '8,-6,44',
            '8,-6,43',
            '10,-6,45',
            '10,-6,65',
            '10,-6,46',
            '10,-6,64',
            '10,-6,63',
            '10,-6,66',
            '10,-6,49',
            '12,-6,47',
            '13,-6,48',
            '13,-6,47',
            '11,-6,46',
            '11,-6,66',
            '11,-6,47',
            '11,-6,65',
            '11,-6,64',
            '14,-6,49',
            '14,-6,48',
            '14,-6,50',
            '-18,-2,46',
            '-13,-2,59',
            '-13,-2,60',
            '-13,-2,61',
            '-12,-2,59',
            '-12,-2,60',
            '-12,-2,58',
            '-9,-2,32',
            '-9,-2,33',
            '-9,-2,31',
            '-9,-2,56',
            '-9,-2,30',
            '-9,-2,54',
            '-9,-2,50',
            '-9,-2,55',
            '-9,-2,53',
            '-9,-2,34',
            '-10,-2,33',
            '-10,-2,34',
            '-10,-2,55',
            '-10,-2,56',
            '-10,-2,57',
            '-10,-2,32',
            '-11,-2,58',
            '-11,-2,57',
            '-11,-2,56',
            '-11,-2,59',
            '-5,-2,44',
            '-5,-2,45',
            '-5,-2,46',
            '-5,-2,25',
            '-5,-2,43',
            '-8,-2,50',
            '-8,-2,30',
            '-8,-2,29',
            '-8,-2,49',
            '-8,-2,53',
            '-6,-2,45',
            '-6,-2,46',
            '-6,-2,26',
            '-6,-2,25',
            '-4,-2,42',
            '-4,-2,43',
            '-4,-2,41',
            '-4,-2,40',
            '-4,-2,44',
            '-4,-2,38',
            '-4,-2,39',
            '-4,-2,52',
            '-3,-2,36',
            '-3,-2,31',
            '-3,-2,32',
            '-3,-2,39',
            '-3,-2,27',
            '-3,-2,37',
            '-3,-2,30',
            '-3,-2,35',
            '-3,-2,41',
            '-3,-2,34',
            '-3,-2,47',
            '-3,-2,28',
            '-3,-2,33',
            '-3,-2,38',
            '-3,-2,25',
            '-3,-2,51',
            '-3,-2,40',
            '-3,-2,52',
            '-3,-2,29',
            '-3,-2,50',
            '-3,-2,49',
            '-3,-2,26',
            '-3,-2,42',
            '-3,-2,48',
            '-3,-2,44',
            '-3,-2,43',
            '-3,-2,24',
            '-3,-2,23',
            '-3,-2,21',
            '-3,-2,22',
            '-3,-2,46',
            '-3,-2,6',
            '-3,-2,20',
            '-3,-2,4',
            '-3,-2,45',
            '-3,-2,5',
            '-3,-2,1',
            '-3,-2,0',
            '-3,-2,8',
            '-3,-2,7',
            '-3,-2,3',
            '-3,-2,9',
            '-3,-2,2',
            '-2,-2,47',
            '-2,-2,2',
            '-2,-2,5',
            '-2,-2,24',
            '-2,-2,21',
            '-2,-2,43',
            '-2,-2,3',
            '-2,-2,27',
            '-2,-2,28',
            '-2,-2,37',
            '-2,-2,22',
            '-2,-2,42',
            '-2,-2,4',
            '-2,-2,6',
            '-2,-2,13',
            '-2,-2,31',
            '-2,-2,46',
            '-2,-2,30',
            '-2,-2,26',
            '-2,-2,35',
            '-2,-2,20',
            '-2,-2,23',
            '-2,-2,32',
            '-2,-2,33',
            '-2,-2,10',
            '-2,-2,48',
            '-2,-2,12',
            '-2,-2,45',
            '-2,-2,9',
            '-2,-2,25',
            '-2,-2,29',
            '-2,-2,41',
            '-2,-2,8',
            '-2,-2,36',
            '-2,-2,0',
            '-2,-2,11',
            '-2,-2,1',
            '-2,-2,7',
            '-2,-2,44',
            '-2,-2,51',
            '-2,-2,40',
            '-2,-2,38',
            '-2,-2,19',
            '-2,-2,49',
            '-2,-2,39',
            '-2,-2,34',
            '-2,-2,17',
            '-2,-2,52',
            '-2,-2,14',
            '-2,-2,16',
            '-2,-2,50',
            '-2,-2,15',
            '-2,-2,18',
            '-1,-2,6',
            '-1,-2,8',
            '-1,-2,5',
            '-1,-2,23',
            '-1,-2,2',
            '-1,-2,22',
            '-1,-2,49',
            '-1,-2,10',
            '-1,-2,48',
            '-1,-2,55',
            '-1,-2,4',
            '-1,-2,12',
            '-1,-2,21',
            '-1,-2,11',
            '-1,-2,3',
            '-1,-2,20',
            '-1,-2,9',
            '-1,-2,18',
            '-1,-2,7',
            '-1,-2,50',
            '-1,-2,1',
            '-1,-2,52',
            '-1,-2,24',
            '-1,-2,54',
            '-1,-2,53',
            '-1,-2,51',
            '-1,-2,19',
            '-1,-2,47',
            '-1,-2,0',
            '-1,-2,56',
            '-1,-2,14',
            '-1,-2,13',
            '-1,-2,57',
            '-1,-2,58',
            '-1,-2,59',
            '0,-2,11',
            '0,-2,57',
            '0,-2,9',
            '0,-2,59',
            '0,-2,10',
            '0,-2,0',
            '0,-2,12',
            '0,-2,1',
            '0,-2,18',
            '0,-2,2',
            '0,-2,58',
            '0,-2,56',
            '0,-2,3',
            '0,-2,19',
            '0,-2,13',
            '0,-2,8',
            '0,-2,60',
            '0,-2,7',
            '1,-2,59',
            '1,-2,0',
            '1,-2,60',
            '2,-2,24',
            '2,-2,26',
            '2,-2,25',
            '2,-2,27',
            '3,-2,28',
            '3,-2,26',
            '3,-2,27',
            '3,-2,29',
            '3,-2,25',
            '4,-2,30',
            '4,-2,28',
            '4,-2,29',
            '4,-2,31',
            '4,-2,27',
            '5,-2,31',
            '5,-2,30',
            '6,-2,65',
            '6,-2,66',
            '6,-2,64',
            '7,-2,59',
            '7,-2,58',
            '7,-2,65',
            '7,-2,57',
            '9,-2,59',
            '9,-2,68',
            '9,-2,67',
            '9,-2,69',
            '9,-2,60',
            '8,-2,58',
            '8,-2,59',
            '8,-2,57',
            '8,-2,65',
            '16,-2,53',
            '-14,-5,57',
            '-14,-5,56',
            '-13,-5,56',
            '-13,-5,55',
            '-13,-5,78',
            '-13,-5,54',
            '-12,-5,54',
            '-12,-5,78',
            '-12,-5,55',
            '-12,-5,77',
            '-12,-5,53',
            '-9,-5,50',
            '-9,-5,78',
            '-9,-5,77',
            '-9,-5,51',
            '-9,-5,79',
            '-9,-5,75',
            '-9,-5,49',
            '-10,-5,51',
            '-10,-5,36',
            '-10,-5,75',
            '-10,-5,50',
            '-10,-5,52',
            '-11,-5,53',
            '-11,-5,54',
            '-11,-5,76',
            '-11,-5,52',
            '-11,-5,75',
            '-11,-5,51',
            '-11,-5,77',
            '-6,-5,69',
            '-6,-5,70',
            '-6,-5,74',
            '-6,-5,72',
            '-6,-5,71',
            '-6,-5,47',
            '-6,-5,73',
            '-6,-5,68',
            '-6,-5,75',
            '-6,-5,54',
            '-6,-5,76',
            '-5,-5,70',
            '-5,-5,66',
            '-5,-5,72',
            '-5,-5,53',
            '-5,-5,69',
            '-5,-5,74',
            '-5,-5,75',
            '-5,-5,73',
            '-5,-5,71',
            '-5,-5,77',
            '-5,-5,68',
            '-5,-5,67',
            '-5,-5,76',
            '-5,-5,54',
            '-5,-5,65',
            '-5,-5,64',
            '-5,-5,63',
            '-5,-5,78',
            '-5,-5,52',
            '-5,-5,44',
            '-5,-5,43',
            '-5,-5,62',
            '-7,-5,48',
            '-7,-5,47',
            '-7,-5,71',
            '-7,-5,72',
            '-7,-5,49',
            '-8,-5,49',
            '-8,-5,50',
            '-8,-5,48',
            '-1,-5,43',
            '-1,-5,72',
            '-1,-5,44',
            '-1,-5,71',
            '-1,-5,73',
            '-1,-5,45',
            '-1,-5,46',
            '-1,-5,74',
            '-1,-5,47',
            '-1,-5,75',
            '-4,-5,52',
            '-4,-5,64',
            '-4,-5,66',
            '-4,-5,63',
            '-4,-5,65',
            '-4,-5,53',
            '-4,-5,43',
            '-4,-5,62',
            '-4,-5,61',
            '-4,-5,60',
            '-4,-5,54',
            '-4,-5,55',
            '-4,-5,78',
            '-4,-5,44',
            '-3,-5,44',
            '-3,-5,43',
            '-3,-5,48',
            '-3,-5,52',
            '-3,-5,66',
            '-3,-5,54',
            '-3,-5,67',
            '-3,-5,69',
            '-3,-5,68',
            '-3,-5,53',
            '-3,-5,49',
            '-3,-5,45',
            '-3,-5,47',
            '-2,-5,43',
            '-2,-5,70',
            '-2,-5,69',
            '-2,-5,71',
            '-2,-5,44',
            '-2,-5,48',
            '-2,-5,72',
            '-2,-5,68',
            '0,-5,48',
            '0,-5,47',
            '0,-5,49',
            '0,-5,46',
            '0,-5,50',
            '0,-5,75',
            '0,-5,74',
            '1,-5,50',
            '1,-5,48',
            '1,-5,49',
            '1,-5,51',
            '1,-5,52',
            '2,-5,67',
            '2,-5,66',
            '2,-5,51',
            '2,-5,68',
            '2,-5,65',
            '2,-5,52',
            '3,-5,66',
            '3,-5,65',
            '3,-5,68',
            '3,-5,67',
            '5,-5,73',
            '5,-5,74',
            '5,-5,71',
            '5,-5,75',
            '5,-5,70',
            '4,-5,67',
            '4,-5,68',
            '4,-5,69',
            '6,-5,73',
            '6,-5,74',
            '6,-5,38',
            '6,-5,37',
            '6,-5,36',
            '7,-5,39',
            '7,-5,38',
            '7,-5,40',
            '7,-5,41',
            '7,-5,43',
            '7,-5,37',
            '7,-5,42',
            '7,-5,45',
            '7,-5,74',
            '7,-5,73',
            '8,-5,43',
            '8,-5,44',
            '8,-5,42',
            '8,-5,41',
            '8,-5,39',
            '8,-5,40',
            '8,-5,45',
            '9,-5,44',
            '9,-5,45',
            '9,-5,62',
            '10,-5,70',
            '10,-5,62',
            '-10,1,59',
            '-10,1,58',
            '-10,1,60',
            '-11,1,60',
            '-11,1,61',
            '-11,1,59',
            '-11,1,62',
            '-6,1,42',
            '-6,1,48',
            '-6,1,43',
            '-6,1,44',
            '-5,1,42',
            '-5,1,41',
            '-5,1,43',
            '-1,1,4',
            '-1,1,11',
            '-1,1,13',
            '-1,1,10',
            '-1,1,3',
            '-1,1,6',
            '-1,1,2',
            '-1,1,9',
            '-1,1,5',
            '-1,1,16',
            '-1,1,21',
            '-1,1,12',
            '-1,1,14',
            '-1,1,65',
            '-1,1,66',
            '-1,1,15',
            '-1,1,20',
            '-1,1,64',
            '-1,1,67',
            '-1,1,0',
            '-1,1,17',
            '-1,1,1',
            '-1,1,18',
            '-1,1,19',
            '-1,1,8',
            '-1,1,7',
            '-1,1,22',
            '-1,1,23',
            '-2,1,3',
            '-2,1,16',
            '-2,1,6',
            '-2,1,14',
            '-2,1,13',
            '-2,1,4',
            '-2,1,11',
            '-2,1,2',
            '-2,1,17',
            '-2,1,15',
            '-2,1,66',
            '-2,1,5',
            '-2,1,0',
            '-2,1,68',
            '-2,1,69',
            '-2,1,10',
            '-2,1,1',
            '-2,1,67',
            '-2,1,18',
            '-2,1,12',
            '-2,1,70',
            '-2,1,7',
            '-2,1,19',
            '-2,1,9',
            '-2,1,20',
            '-2,1,71',
            '-2,1,73',
            '-2,1,72',
            '0,1,21',
            '0,1,3',
            '0,1,2',
            '0,1,0',
            '0,1,4',
            '0,1,20',
            '0,1,16',
            '0,1,1',
            '0,1,17',
            '0,1,5',
            '0,1,18',
            '0,1,19',
            '0,1,22',
            '0,1,23',
            '0,1,24',
            '0,1,25',
            '1,1,22',
            '1,1,23',
            '1,1,26',
            '1,1,24',
            '1,1,25',
            '1,1,0',
            '6,1,40',
            '6,1,47',
            '6,1,45',
            '6,1,41',
            '6,1,42',
            '6,1,50',
            '6,1,46',
            '6,1,48',
            '6,1,44',
            '6,1,39',
            '6,1,51',
            '6,1,43',
            '6,1,49',
            '6,1,38',
            '6,1,36',
            '6,1,37',
            '6,1,52',
            '6,1,35',
            '6,1,34',
            '6,1,33',
            '6,1,58',
            '6,1,53',
            '6,1,57',
            '6,1,56',
            '6,1,54',
            '6,1,59',
            '6,1,55',
            '5,1,38',
            '5,1,46',
            '5,1,44',
            '5,1,37',
            '5,1,43',
            '5,1,40',
            '5,1,45',
            '5,1,39',
            '5,1,36',
            '5,1,42',
            '5,1,41',
            '5,1,33',
            '5,1,35',
            '5,1,34',
            '5,1,32',
            '5,1,31',
            '2,1,27',
            '2,1,25',
            '2,1,28',
            '2,1,26',
            '2,1,24',
            '2,1,29',
            '3,1,30',
            '3,1,29',
            '3,1,31',
            '3,1,27',
            '3,1,28',
            '4,1,30',
            '4,1,32',
            '4,1,29',
            '4,1,31',
            '4,1,33',
            '8,1,57',
            '8,1,65',
            '8,1,63',
            '8,1,71',
            '8,1,56',
            '8,1,62',
            '8,1,44',
            '8,1,55',
            '8,1,64',
            '8,1,73',
            '8,1,66',
            '8,1,74',
            '8,1,72',
            '8,1,47',
            '8,1,75',
            '8,1,70',
            '8,1,58',
            '8,1,43',
            '8,1,42',
            '7,1,49',
            '7,1,55',
            '7,1,54',
            '7,1,52',
            '7,1,62',
            '7,1,60',
            '7,1,58',
            '7,1,51',
            '7,1,50',
            '7,1,53',
            '7,1,56',
            '7,1,61',
            '7,1,63',
            '7,1,64',
            '7,1,57',
            '7,1,59',
            '7,1,47',
            '7,1,48',
            '7,1,41',
            '7,1,42',
            '7,1,46',
            '7,1,43',
            '9,1,58',
            '9,1,68',
            '9,1,57',
            '9,1,59',
            '9,1,69',
            '9,1,70',
            '9,1,73',
            '9,1,71',
            '9,1,72',
            '9,1,49',
            '9,1,45',
            '9,1,44',
            '9,1,46',
            '9,1,43',
            '10,1,59',
            '10,1,49',
            '10,1,58',
            '10,1,47',
            '10,1,46',
            '10,1,45',
            '13,1,62',
            '13,1,50',
            '13,1,49',
            '13,1,47',
            '13,1,63',
            '13,1,46',
            '12,1,49',
            '12,1,61',
            '12,1,62',
            '12,1,48',
            '12,1,46',
            '12,1,47',
            '11,1,61',
            '11,1,60',
            '11,1,47',
            '11,1,46',
            '11,1,48',
            '14,1,63',
            '14,1,62',
            '14,1,64',
            '15,1,64',
            '15,1,65',
            '15,1,63',
            '16,1,64',
            '-9,-1,54',
            '-9,-1,55',
            '-9,-1,30',
            '-9,-1,32',
            '-9,-1,50',
            '-9,-1,31',
            '-9,-1,56',
            '-9,-1,53',
            '-9,-1,29',
            '-10,-1,56',
            '-10,-1,55',
            '-7,-1,27',
            '-7,-1,26',
            '-7,-1,28',
            '-7,-1,48',
            '-7,-1,29',
            '-8,-1,54',
            '-8,-1,30',
            '-8,-1,49',
            '-8,-1,28',
            '-8,-1,29',
            '-8,-1,50',
            '-8,-1,53',
            '-8,-1,48',
            '-5,-1,25',
            '-5,-1,24',
            '-6,-1,26',
            '-6,-1,25',
            '-6,-1,27',
            '-6,-1,46',
            '-3,-1,37',
            '-3,-1,36',
            '-3,-1,38',
            '-3,-1,1',
            '-3,-1,0',
            '-3,-1,26',
            '-3,-1,23',
            '-3,-1,2',
            '-3,-1,3',
            '-3,-1,22',
            '-3,-1,39',
            '-3,-1,8',
            '-3,-1,4',
            '-3,-1,21',
            '-3,-1,5',
            '-3,-1,20',
            '-3,-1,6',
            '-3,-1,7',
            '-3,-1,25',
            '-3,-1,24',
            '-3,-1,48',
            '-3,-1,47',
            '-3,-1,27',
            '-3,-1,9',
            '-3,-1,28',
            '-4,-1,37',
            '-4,-1,38',
            '-4,-1,24',
            '-4,-1,23',
            '-4,-1,39',
            '-4,-1,25',
            '-1,-1,23',
            '-1,-1,18',
            '-1,-1,19',
            '-1,-1,22',
            '-1,-1,53',
            '-1,-1,1',
            '-1,-1,49',
            '-1,-1,13',
            '-1,-1,51',
            '-1,-1,21',
            '-1,-1,17',
            '-1,-1,0',
            '-1,-1,16',
            '-1,-1,14',
            '-1,-1,20',
            '-1,-1,50',
            '-1,-1,52',
            '-1,-1,15',
            '-1,-1,24',
            '-1,-1,12',
            '-1,-1,10',
            '-1,-1,48',
            '-1,-1,54',
            '-1,-1,2',
            '-1,-1,55',
            '-1,-1,3',
            '-1,-1,8',
            '-1,-1,6',
            '-1,-1,4',
            '-1,-1,7',
            '-1,-1,57',
            '-1,-1,5',
            '-1,-1,11',
            '-1,-1,56',
            '-1,-1,9',
            '-1,-1,58',
            '-1,-1,60',
            '-1,-1,59',
            '-2,-1,10',
            '-2,-1,9',
            '-2,-1,14',
            '-2,-1,13',
            '-2,-1,1',
            '-2,-1,8',
            '-2,-1,16',
            '-2,-1,11',
            '-2,-1,15',
            '-2,-1,5',
            '-2,-1,2',
            '-2,-1,19',
            '-2,-1,0',
            '-2,-1,12',
            '-2,-1,4',
            '-2,-1,18',
            '-2,-1,26',
            '-2,-1,3',
            '-2,-1,28',
            '-2,-1,48',
            '-2,-1,24',
            '-2,-1,25',
            '-2,-1,27',
            '-2,-1,17',
            '-2,-1,7',
            '-2,-1,21',
            '-2,-1,6',
            '-2,-1,20',
            '-2,-1,23',
            '-2,-1,49',
            '-2,-1,51',
            '-2,-1,50',
            '-2,-1,47',
            '-2,-1,22',
            '0,-1,1',
            '0,-1,9',
            '0,-1,5',
            '0,-1,8',
            '0,-1,3',
            '0,-1,10',
            '0,-1,6',
            '0,-1,7',
            '0,-1,2',
            '0,-1,0',
            '0,-1,56',
            '0,-1,11',
            '0,-1,4',
            '0,-1,12',
            '0,-1,57',
            '0,-1,13',
            '0,-1,15',
            '0,-1,59',
            '0,-1,19',
            '0,-1,18',
            '0,-1,20',
            '0,-1,58',
            '0,-1,23',
            '0,-1,16',
            '0,-1,21',
            '0,-1,22',
            '0,-1,17',
            '0,-1,14',
            '0,-1,60',
            '1,-1,22',
            '1,-1,23',
            '1,-1,60',
            '1,-1,61',
            '1,-1,25',
            '1,-1,24',
            '1,-1,62',
            '2,-1,25',
            '2,-1,24',
            '2,-1,26',
            '2,-1,27',
            '2,-1,23',
            '2,-1,62',
            '3,-1,27',
            '3,-1,28',
            '3,-1,25',
            '3,-1,26',
            '4,-1,28',
            '4,-1,27',
            '4,-1,30',
            '8,-1,57',
            '8,-1,58',
            '8,-1,56',
            '8,-1,65',
            '8,-1,64',
            '8,-1,59',
            '7,-1,63',
            '7,-1,64',
            '7,-1,57',
            '7,-1,56',
            '7,-1,65',
            '7,-1,62',
            '7,-1,55',
            '7,-1,59',
            '6,-1,64',
            '9,-1,73',
            '10,-1,72',
            '10,-1,73',
            '16,-1,53',
            '16,-1,52',
            '15,-1,52',
            '-10,0,58',
            '-10,0,56',
            '-10,0,60',
            '-10,0,57',
            '-10,0,59',
            '-9,0,54',
            '-9,0,50',
            '-9,0,56',
            '-9,0,55',
            '-11,0,59',
            '-11,0,60',
            '-5,0,25',
            '-5,0,41',
            '-5,0,24',
            '-5,0,42',
            '-5,0,40',
            '-8,0,49',
            '-8,0,50',
            '-8,0,28',
            '-8,0,48',
            '-7,0,26',
            '-7,0,27',
            '-7,0,49',
            '-7,0,28',
            '-7,0,48',
            '-6,0,48',
            '-6,0,26',
            '-6,0,25',
            '-4,0,23',
            '-4,0,38',
            '-4,0,40',
            '-4,0,24',
            '-4,0,39',
            '-4,0,41',
            '-3,0,1',
            '-3,0,40',
            '-3,0,0',
            '-3,0,22',
            '-3,0,41',
            '-3,0,23',
            '-3,0,39',
            '-3,0,2',
            '-3,0,38',
            '-3,0,37',
            '-3,0,21',
            '-3,0,3',
            '-3,0,5',
            '-3,0,20',
            '-3,0,4',
            '-3,0,6',
            '-3,0,29',
            '-2,0,8',
            '-2,0,11',
            '-2,0,17',
            '-2,0,18',
            '-2,0,19',
            '-2,0,9',
            '-2,0,22',
            '-2,0,12',
            '-2,0,4',
            '-2,0,16',
            '-2,0,20',
            '-2,0,10',
            '-2,0,14',
            '-2,0,15',
            '-2,0,7',
            '-2,0,5',
            '-2,0,21',
            '-2,0,13',
            '-2,0,1',
            '-2,0,3',
            '-2,0,6',
            '-2,0,0',
            '-2,0,2',
            '-2,0,66',
            '-2,0,67',
            '-2,0,68',
            '-2,0,70',
            '-2,0,69',
            '-2,0,23',
            '-2,0,24',
            '-1,0,9',
            '-1,0,6',
            '-1,0,3',
            '-1,0,12',
            '-1,0,7',
            '-1,0,11',
            '-1,0,8',
            '-1,0,5',
            '-1,0,4',
            '-1,0,23',
            '-1,0,10',
            '-1,0,20',
            '-1,0,21',
            '-1,0,66',
            '-1,0,24',
            '-1,0,2',
            '-1,0,14',
            '-1,0,22',
            '-1,0,64',
            '-1,0,13',
            '-1,0,15',
            '-1,0,65',
            '-1,0,19',
            '-1,0,18',
            '-1,0,16',
            '-1,0,17',
            '-1,0,60',
            '-1,0,1',
            '-1,0,0',
            '-1,0,63',
            '-1,0,59',
            '0,0,19',
            '0,0,13',
            '0,0,21',
            '0,0,2',
            '0,0,20',
            '0,0,22',
            '0,0,3',
            '0,0,8',
            '0,0,4',
            '0,0,11',
            '0,0,10',
            '0,0,6',
            '0,0,17',
            '0,0,18',
            '0,0,9',
            '0,0,7',
            '0,0,1',
            '0,0,12',
            '0,0,0',
            '0,0,14',
            '0,0,15',
            '0,0,5',
            '0,0,60',
            '0,0,16',
            '0,0,23',
            '0,0,59',
            '0,0,58',
            '1,0,22',
            '1,0,0',
            '1,0,60',
            '1,0,23',
            '1,0,61',
            '1,0,24',
            '1,0,62',
            '2,0,24',
            '2,0,23',
            '2,0,25',
            '2,0,62',
            '6,0,49',
            '6,0,47',
            '6,0,45',
            '6,0,51',
            '6,0,46',
            '6,0,50',
            '6,0,48',
            '6,0,39',
            '6,0,38',
            '6,0,40',
            '6,0,44',
            '6,0,72',
            '6,0,37',
            '6,0,52',
            '6,0,43',
            '6,0,71',
            '6,0,53',
            '6,0,54',
            '6,0,56',
            '6,0,59',
            '6,0,58',
            '6,0,57',
            '6,0,60',
            '6,0,64',
            '6,0,61',
            '6,0,55',
            '5,0,45',
            '5,0,46',
            '5,0,38',
            '7,0,54',
            '7,0,61',
            '7,0,53',
            '7,0,55',
            '7,0,60',
            '7,0,56',
            '7,0,63',
            '7,0,57',
            '7,0,52',
            '7,0,62',
            '7,0,59',
            '7,0,58',
            '7,0,64',
            '7,0,51',
            '7,0,49',
            '7,0,71',
            '7,0,74',
            '7,0,50',
            '7,0,70',
            '7,0,72',
            '7,0,73',
            '7,0,48',
            '8,0,57',
            '8,0,63',
            '8,0,73',
            '8,0,72',
            '8,0,56',
            '8,0,70',
            '8,0,64',
            '8,0,55',
            '8,0,61',
            '8,0,71',
            '8,0,59',
            '8,0,62',
            '8,0,44',
            '8,0,47',
            '8,0,58',
            '8,0,74',
            '8,0,66',
            '8,0,65',
            '10,0,59',
            '10,0,60',
            '10,0,72',
            '10,0,73',
            '9,0,58',
            '9,0,59',
            '9,0,72',
            '9,0,74',
            '9,0,66',
            '9,0,70',
            '9,0,68',
            '9,0,71',
            '9,0,57',
            '9,0,73',
            '9,0,69',
            '13,0,62',
            '11,0,60',
            '11,0,59',
            '11,0,61',
            '12,0,61',
            '12,0,71',
            '15,0,51',
            '15,0,52',
            '16,0,53',
            '16,0,52',
            '14,0,51',
            '14,0,52',
            '17,0,53',
            '-13,3,70',
            '-13,3,71',
            '-13,3,56',
            '-13,3,55',
            '-13,3,69',
            '-12,3,55',
            '-12,3,69',
            '-12,3,71',
            '-12,3,70',
            '-12,3,68',
            '-11,3,70',
            '-11,3,69',
            '-11,3,68',
            '-11,3,63',
            '-11,3,64',
            '-6,3,48',
            '-6,3,49',
            '-6,3,46',
            '-6,3,47',
            '-6,3,50',
            '-6,3,44',
            '-3,3,75',
            '-3,3,78',
            '-3,3,77',
            '-3,3,76',
            '-3,3,79',
            '-4,3,78',
            '-7,3,55',
            '2,3,28',
            '2,3,27',
            '4,3,30',
            '4,3,31',
            '4,3,29',
            '4,3,32',
            '3,3,29',
            '3,3,28',
            '3,3,30',
            '3,3,27',
            '6,3,49',
            '6,3,48',
            '6,3,47',
            '10,3,49',
            '10,3,50',
            '8,3,48',
            '8,3,47',
            '8,3,74',
            '9,3,48',
            '9,3,49',
            '7,3,48',
            '7,3,49',
            '12,3,48',
            '11,3,50',
            '13,3,48',
            '13,3,47',
            '13,3,50',
            '14,3,47',
            '14,3,48',
            '16,3,49',
            '16,3,50',
            '15,3,48',
            '15,3,49',
            '-13,4,72',
            '-13,4,55',
            '-13,4,56',
            '-13,4,71',
            '-13,4,57',
            '-13,4,58',
            '-14,4,72',
            '-14,4,58',
            '-12,4,71',
            '-12,4,55',
            '-12,4,49',
            '-9,4,48',
            '-9,4,47',
            '-9,4,49',
            '-10,4,48',
            '-10,4,49',
            '-11,4,50',
            '-11,4,49',
            '-6,4,51',
            '-6,4,49',
            '-6,4,50',
            '-6,4,52',
            '-3,4,79',
            '-3,4,78',
            '-7,4,53',
            '-7,4,51',
            '-7,4,52',
            '-7,4,54',
            '-7,4,50',
            '11,4,51',
            '11,4,50',
            '12,4,51',
            '10,4,50',
            '16,4,50',
            '-12,2,69',
            '-12,2,68',
            '-11,2,62',
            '-11,2,61',
            '-11,2,63',
            '-11,2,64',
            '-11,2,70',
            '-11,2,68',
            '-11,2,69',
            '-11,2,60',
            '-10,2,60',
            '-10,2,61',
            '-3,2,76',
            '-3,2,75',
            '-3,2,74',
            '-3,2,73',
            '-2,2,0',
            '-2,2,73',
            '-2,2,72',
            '-2,2,19',
            '-2,2,1',
            '-6,2,46',
            '-6,2,44',
            '-6,2,45',
            '-6,2,43',
            '-6,2,47',
            '-1,2,0',
            '-1,2,1',
            '-1,2,23',
            '-1,2,21',
            '-1,2,22',
            '-1,2,20',
            '-5,2,43',
            '-5,2,44',
            '-5,2,45',
            '-4,2,44',
            '0,2,22',
            '0,2,23',
            '0,2,21',
            '0,2,24',
            '0,2,25',
            '0,2,20',
            '1,2,23',
            '1,2,25',
            '1,2,24',
            '1,2,26',
            '1,2,22',
            '1,2,27',
            '2,2,26',
            '2,2,27',
            '2,2,28',
            '2,2,24',
            '2,2,25',
            '2,2,29',
            '5,2,31',
            '5,2,33',
            '5,2,32',
            '5,2,35',
            '5,2,45',
            '5,2,37',
            '5,2,36',
            '5,2,34',
            '5,2,38',
            '5,2,43',
            '5,2,44',
            '4,2,31',
            '4,2,30',
            '4,2,29',
            '4,2,32',
            '4,2,28',
            '4,2,33',
            '3,2,29',
            '3,2,27',
            '3,2,28',
            '3,2,26',
            '3,2,31',
            '3,2,30',
            '6,2,36',
            '6,2,34',
            '6,2,45',
            '6,2,43',
            '6,2,41',
            '6,2,35',
            '6,2,37',
            '6,2,33',
            '6,2,38',
            '6,2,40',
            '6,2,42',
            '6,2,44',
            '6,2,39',
            '6,2,46',
            '6,2,47',
            '6,2,48',
            '6,2,49',
            '7,2,46',
            '7,2,34',
            '7,2,47',
            '7,2,33',
            '7,2,42',
            '7,2,43',
            '8,2,47',
            '8,2,43',
            '8,2,48',
            '8,2,44',
            '8,2,42',
            '9,2,44',
            '9,2,48',
            '9,2,58',
            '9,2,72',
            '9,2,71',
            '9,2,49',
            '10,2,49',
            '12,2,48',
            '12,2,47',
            '12,2,46',
            '13,2,47',
            '13,2,50',
            '13,2,48',
            '11,2,47',
            '11,2,48',
            '14,2,47',
            '14,2,48',
            '15,2,48',
            '15,2,47',
            '15,2,65',
            '15,2,64',
            '16,2,65',
            '18,2,65',
            '-13,5,58',
            '-14,5,58',
            '-11,5,50',
            '-11,5,49',
            '-10,5,49',
            '-7,5,54',
            '-7,5,55',
            '-7,5,53',
            '11,5,51',
            '11,5,50',
            '17,5,50',
            '17,5,51',
            '16,5,50',
            '16,5,51',
            '-12,6,54',
            '-12,6,55',
            '-12,6,52',
            '-12,6,51',
            '-14,6,58',
            '-11,6,51',
            '-11,6,50',
            '12,6,51',
            '4,-18,66',
            '4,-18,65',
            '3,-18,65',
            '3,-18,66',
            '4,-17,65',
            '5,-17,65',
            '5,-17,68',
          ],
        },
      },
      C_VOXELS: {
        VOX_SZ: '.75',
        MIN_PT: '15',
        COORDS: {
          COORD: [
            '-5,-3,13',
            '-5,-3,18',
            '-5,-3,15',
            '-5,-3,19',
            '-5,-3,16',
            '-5,-3,17',
            '-5,-3,14',
            '-5,-3,12',
            '-4,-3,18',
            '-4,-3,19',
            '-4,-3,16',
            '-4,-3,17',
            '-4,-3,15',
            '-4,-3,20',
            '-3,-3,16',
            '-3,-3,22',
            '-3,-3,17',
            '-3,-3,19',
            '-3,-3,21',
            '-3,-3,14',
            '-3,-3,20',
            '-3,-3,18',
            '-3,-3,13',
            '-2,-3,21',
            '-2,-3,18',
            '-2,-3,16',
            '-2,-3,20',
            '-2,-3,22',
            '-2,-3,17',
            '-2,-3,15',
            '-2,-3,19',
            '-2,-3,14',
            '-2,-3,13',
            '-1,-3,12',
            '-1,-3,11',
            '-1,-3,20',
            '-1,-3,16',
            '-1,-3,14',
            '-1,-3,22',
            '-1,-3,21',
            '-1,-3,15',
            '-1,-3,13',
            '-1,-3,17',
            '-1,-3,19',
            '-1,-3,18',
            '0,-3,14',
            '0,-3,13',
            '0,-3,20',
            '0,-3,22',
            '0,-3,15',
            '0,-3,19',
            '0,-3,21',
            '0,-3,16',
            '0,-3,12',
            '0,-3,17',
            '0,-3,18',
            '1,-3,19',
            '1,-3,18',
            '1,-3,20',
            '1,-3,17',
            '1,-3,16',
            '1,-3,15',
            '1,-3,21',
            '1,-3,14',
            '1,-3,13',
            '1,-3,11',
            '1,-3,12',
            '1,-3,10',
            '2,-3,11',
            '2,-3,18',
            '2,-3,16',
            '2,-3,19',
            '2,-3,12',
            '2,-3,10',
            '2,-3,17',
            '2,-3,20',
            '2,-3,13',
            '2,-3,21',
            '2,-3,14',
            '2,-3,15',
            '3,-3,14',
            '3,-3,11',
            '3,-3,12',
            '3,-3,19',
            '3,-3,15',
            '3,-3,20',
            '3,-3,13',
            '3,-3,16',
            '3,-3,18',
            '3,-3,17',
            '4,-3,17',
            '4,-3,16',
            '4,-3,18',
            '4,-3,15',
            '4,-3,19',
            '4,-3,14',
            '4,-3,20',
            '4,-3,13',
            '4,-3,12',
            '5,-3,14',
            '5,-3,16',
            '5,-3,15',
            '5,-3,17',
            '5,-3,19',
            '5,-3,18',
            '5,-3,13',
            '6,-3,15',
            '6,-3,16',
            '6,-3,17',
            '6,-3,14',
            '-4,-2,18',
            '-4,-2,16',
            '-4,-2,17',
            '-4,-2,19',
            '-4,-2,14',
            '-4,-2,15',
            '-4,-2,13',
            '-4,-2,21',
            '-4,-2,22',
            '-4,-2,20',
            '-4,-2,23',
            '-4,-2,12',
            '-4,-2,11',
            '-4,-2,10',
            '-5,-2,13',
            '-5,-2,15',
            '-5,-2,17',
            '-5,-2,16',
            '-5,-2,18',
            '-5,-2,12',
            '-5,-2,19',
            '-5,-2,14',
            '-5,-2,20',
            '-6,-2,13',
            '-6,-2,12',
            '-6,-2,14',
            '-6,-2,16',
            '-6,-2,15',
            '-3,-2,22',
            '-3,-2,21',
            '-3,-2,20',
            '-3,-2,23',
            '-3,-2,14',
            '-3,-2,15',
            '-3,-2,16',
            '-3,-2,19',
            '-3,-2,17',
            '-3,-2,13',
            '-3,-2,10',
            '-3,-2,11',
            '-3,-2,9',
            '-2,-2,20',
            '-2,-2,13',
            '-2,-2,15',
            '-2,-2,23',
            '-2,-2,14',
            '-2,-2,16',
            '-2,-2,21',
            '-2,-2,19',
            '-2,-2,12',
            '-2,-2,22',
            '-2,-2,18',
            '-2,-2,17',
            '-2,-2,10',
            '-1,-2,11',
            '-1,-2,12',
            '-1,-2,18',
            '-1,-2,13',
            '-1,-2,14',
            '-1,-2,19',
            '-1,-2,15',
            '-1,-2,21',
            '-1,-2,17',
            '-1,-2,20',
            '-1,-2,22',
            '0,-2,14',
            '0,-2,13',
            '0,-2,12',
            '0,-2,21',
            '0,-2,20',
            '0,-2,15',
            '0,-2,19',
            '0,-2,22',
            '0,-2,11',
            '0,-2,18',
            '0,-2,16',
            '0,-2,17',
            '1,-2,15',
            '1,-2,20',
            '1,-2,13',
            '1,-2,17',
            '1,-2,18',
            '1,-2,19',
            '1,-2,16',
            '1,-2,21',
            '1,-2,14',
            '1,-2,22',
            '1,-2,11',
            '1,-2,12',
            '1,-2,23',
            '2,-2,13',
            '2,-2,19',
            '2,-2,11',
            '2,-2,12',
            '2,-2,18',
            '2,-2,14',
            '2,-2,20',
            '2,-2,21',
            '2,-2,22',
            '2,-2,15',
            '2,-2,10',
            '2,-2,17',
            '3,-2,11',
            '3,-2,14',
            '3,-2,12',
            '3,-2,15',
            '3,-2,18',
            '3,-2,17',
            '3,-2,13',
            '3,-2,20',
            '3,-2,19',
            '3,-2,16',
            '3,-2,21',
            '3,-2,22',
            '4,-2,13',
            '4,-2,18',
            '4,-2,12',
            '4,-2,19',
            '4,-2,17',
            '4,-2,20',
            '4,-2,14',
            '4,-2,16',
            '4,-2,21',
            '4,-2,15',
            '5,-2,14',
            '5,-2,15',
            '5,-2,18',
            '5,-2,16',
            '5,-2,19',
            '5,-2,13',
            '5,-2,17',
            '6,-2,15',
            '6,-2,14',
            '6,-2,16',
            '-4,-4,19',
            '-4,-4,18',
            '-4,-4,17',
            '-4,-4,16',
            '-4,-4,20',
            '-5,-4,17',
            '-5,-4,18',
            '-5,-4,19',
            '-5,-4,16',
            '-3,-4,17',
            '-3,-4,19',
            '-3,-4,18',
            '-3,-4,20',
            '-3,-4,16',
            '-3,-4,21',
            '-3,-4,15',
            '-2,-4,19',
            '-2,-4,20',
            '-2,-4,21',
            '-2,-4,18',
            '-2,-4,22',
            '-2,-4,16',
            '-1,-4,17',
            '-1,-4,15',
            '-1,-4,16',
            '-1,-4,21',
            '-1,-4,20',
            '-1,-4,14',
            '-1,-4,22',
            '-1,-4,19',
            '0,-4,16',
            '0,-4,17',
            '0,-4,15',
            '0,-4,21',
            '0,-4,14',
            '0,-4,20',
            '0,-4,22',
            '0,-4,19',
            '0,-4,18',
            '1,-4,19',
            '1,-4,17',
            '1,-4,20',
            '1,-4,18',
            '1,-4,16',
            '1,-4,12',
            '1,-4,15',
            '1,-4,13',
            '1,-4,21',
            '2,-4,16',
            '2,-4,17',
            '2,-4,13',
            '2,-4,14',
            '2,-4,18',
            '2,-4,12',
            '2,-4,15',
            '2,-4,20',
            '2,-4,19',
            '2,-4,11',
            '3,-4,12',
            '3,-4,13',
            '3,-4,20',
            '3,-4,15',
            '3,-4,19',
            '3,-4,14',
            '3,-4,16',
            '3,-4,18',
            '3,-4,17',
            '3,-4,11',
            '3,-4,21',
            '4,-4,15',
            '4,-4,17',
            '4,-4,18',
            '4,-4,20',
            '4,-4,16',
            '4,-4,19',
            '4,-4,14',
            '5,-4,16',
            '5,-4,17',
            '-4,-5,18',
            '-4,-5,17',
            '-4,-5,19',
            '-5,-5,18',
            '-5,-5,17',
            '-5,-5,19',
            '-3,-5,17',
            '-3,-5,18',
            '-3,-5,20',
            '-3,-5,19',
            '-2,-5,18',
            '-2,-5,20',
            '-2,-5,17',
            '-2,-5,21',
            '-1,-5,20',
            '-1,-5,15',
            '-1,-5,21',
            '-1,-5,16',
            '0,-5,16',
            '0,-5,17',
            '0,-5,18',
            '0,-5,19',
            '1,-5,17',
            '1,-5,18',
            '1,-5,19',
            '1,-5,20',
            '1,-5,15',
            '1,-5,14',
            '1,-5,16',
            '2,-5,15',
            '2,-5,14',
            '2,-5,19',
            '2,-5,17',
            '2,-5,13',
            '2,-5,18',
            '2,-5,16',
            '2,-5,20',
            '2,-5,12',
            '3,-5,17',
            '3,-5,14',
            '3,-5,16',
            '3,-5,18',
            '3,-5,15',
            '3,-5,13',
            '3,-5,19',
            '3,-5,12',
            '3,-5,20',
            '4,-5,16',
            '4,-5,18',
            '4,-5,17',
            '4,-5,19',
            '4,-5,15',
            '4,-5,20',
            '5,-5,17',
            '5,-5,16',
            '5,-5,15',
            '-4,-6,18',
            '-4,-6,17',
            '-4,-6,19',
            '-3,-6,18',
            '-3,-6,17',
            '-3,-6,19',
            '1,-6,17',
            '1,-6,18',
            '1,-6,19',
            '1,-6,16',
            '1,-6,20',
            '1,-6,15',
            '1,-6,14',
            '2,-6,18',
            '2,-6,19',
            '2,-6,17',
            '2,-6,15',
            '2,-6,14',
            '2,-6,16',
            '0,-6,18',
            '0,-6,17',
            '0,-6,19',
            '3,-6,17',
            '3,-6,16',
            '3,-6,15',
            '3,-6,14',
            '3,-6,18',
            '4,-6,16',
            '4,-6,17',
            '4,-6,18',
            '5,-6,16',
            '5,-6,17',
            '-6,-1,14',
            '-6,-1,13',
            '-6,-1,12',
            '-6,-1,15',
            '-5,-1,13',
            '-5,-1,18',
            '-5,-1,11',
            '-5,-1,12',
            '-5,-1,14',
            '-5,-1,19',
            '-5,-1,17',
            '-5,-1,16',
            '-5,-1,15',
            '-5,-1,20',
            '-4,-1,21',
            '-4,-1,14',
            '-4,-1,20',
            '-4,-1,11',
            '-4,-1,16',
            '-4,-1,18',
            '-4,-1,19',
            '-4,-1,12',
            '-4,-1,17',
            '-4,-1,15',
            '-4,-1,13',
            '-4,-1,10',
            '-4,-1,23',
            '-4,-1,22',
            '-3,-1,22',
            '-3,-1,10',
            '-3,-1,14',
            '-3,-1,16',
            '-3,-1,23',
            '-3,-1,11',
            '-3,-1,13',
            '-3,-1,17',
            '-3,-1,15',
            '-3,-1,21',
            '-3,-1,18',
            '-3,-1,20',
            '-3,-1,9',
            '-3,-1,8',
            '-3,-1,7',
            '-2,-1,21',
            '-2,-1,13',
            '-2,-1,15',
            '-2,-1,10',
            '-2,-1,14',
            '-2,-1,12',
            '-2,-1,20',
            '-2,-1,19',
            '-2,-1,22',
            '-2,-1,7',
            '-2,-1,9',
            '-2,-1,23',
            '-2,-1,8',
            '-2,-1,11',
            '-2,-1,18',
            '-2,-1,17',
            '-2,-1,6',
            '-1,-1,21',
            '-1,-1,11',
            '-1,-1,7',
            '-1,-1,22',
            '-1,-1,20',
            '-1,-1,23',
            '-1,-1,12',
            '-1,-1,15',
            '-1,-1,14',
            '-1,-1,6',
            '-1,-1,13',
            '-1,-1,18',
            '-1,-1,19',
            '-1,-1,17',
            '-1,-1,10',
            '-1,-1,8',
            '-1,-1,9',
            '-1,-1,3',
            '-1,-1,16',
            '-1,-1,5',
            '-1,-1,2',
            '0,-1,3',
            '0,-1,15',
            '0,-1,20',
            '0,-1,21',
            '0,-1,2',
            '0,-1,12',
            '0,-1,19',
            '0,-1,11',
            '0,-1,16',
            '0,-1,18',
            '0,-1,14',
            '0,-1,22',
            '0,-1,17',
            '0,-1,9',
            '0,-1,6',
            '0,-1,7',
            '0,-1,10',
            '0,-1,4',
            '0,-1,8',
            '0,-1,5',
            '0,-1,23',
            '1,-1,7',
            '1,-1,18',
            '1,-1,20',
            '1,-1,14',
            '1,-1,13',
            '1,-1,22',
            '1,-1,17',
            '1,-1,21',
            '1,-1,16',
            '1,-1,8',
            '1,-1,6',
            '1,-1,15',
            '1,-1,19',
            '1,-1,9',
            '1,-1,10',
            '1,-1,12',
            '2,-1,14',
            '2,-1,20',
            '2,-1,9',
            '2,-1,10',
            '2,-1,18',
            '2,-1,21',
            '2,-1,22',
            '2,-1,17',
            '2,-1,19',
            '2,-1,11',
            '2,-1,12',
            '2,-1,8',
            '2,-1,16',
            '2,-1,15',
            '3,-1,12',
            '3,-1,15',
            '3,-1,11',
            '3,-1,22',
            '3,-1,21',
            '3,-1,17',
            '3,-1,16',
            '3,-1,10',
            '3,-1,20',
            '3,-1,18',
            '3,-1,19',
            '3,-1,13',
            '3,-1,14',
            '4,-1,13',
            '4,-1,12',
            '4,-1,21',
            '4,-1,20',
            '4,-1,17',
            '4,-1,19',
            '4,-1,16',
            '4,-1,18',
            '4,-1,14',
            '4,-1,15',
            '5,-1,19',
            '5,-1,21',
            '5,-1,14',
            '5,-1,18',
            '5,-1,16',
            '5,-1,20',
            '5,-1,17',
            '5,-1,13',
            '5,-1,15',
            '6,-1,19',
            '6,-1,15',
            '6,-1,14',
            '6,-1,18',
            '-5,0,11',
            '-5,0,14',
            '-5,0,13',
            '-5,0,17',
            '-5,0,19',
            '-6,0,14',
            '-4,0,17',
            '-4,0,11',
            '-4,0,19',
            '-4,0,18',
            '-4,0,12',
            '-4,0,20',
            '-4,0,10',
            '-4,0,9',
            '-4,0,15',
            '-4,0,14',
            '-4,0,13',
            '-3,0,8',
            '-3,0,9',
            '-3,0,19',
            '-3,0,17',
            '-3,0,7',
            '-3,0,10',
            '-3,0,20',
            '-3,0,11',
            '-3,0,18',
            '-3,0,14',
            '-3,0,16',
            '-3,0,13',
            '-3,0,15',
            '-3,0,12',
            '-2,0,9',
            '-2,0,18',
            '-2,0,13',
            '-2,0,7',
            '-2,0,14',
            '-2,0,15',
            '-2,0,8',
            '-2,0,16',
            '-2,0,17',
            '-2,0,11',
            '-2,0,19',
            '-2,0,22',
            '-2,0,12',
            '-2,0,20',
            '-2,0,6',
            '-2,0,10',
            '-2,0,21',
            '-1,0,6',
            '-1,0,7',
            '-1,0,10',
            '-1,0,13',
            '-1,0,12',
            '-1,0,11',
            '-1,0,14',
            '-1,0,8',
            '-1,0,22',
            '-1,0,21',
            '-1,0,5',
            '-1,0,15',
            '-1,0,18',
            '-1,0,19',
            '-1,0,4',
            '-1,0,16',
            '-1,0,17',
            '-1,0,20',
            '-1,0,3',
            '-1,0,23',
            '0,0,15',
            '0,0,17',
            '0,0,18',
            '0,0,7',
            '0,0,16',
            '0,0,19',
            '0,0,22',
            '0,0,6',
            '0,0,20',
            '0,0,21',
            '0,0,5',
            '0,0,10',
            '0,0,4',
            '0,0,23',
            '0,0,8',
            '0,0,13',
            '0,0,3',
            '0,0,2',
            '0,0,9',
            '1,0,16',
            '1,0,18',
            '1,0,8',
            '1,0,7',
            '1,0,17',
            '1,0,6',
            '1,0,19',
            '1,0,21',
            '1,0,22',
            '1,0,9',
            '1,0,20',
            '1,0,10',
            '2,0,9',
            '2,0,20',
            '2,0,17',
            '2,0,10',
            '2,0,12',
            '2,0,8',
            '2,0,19',
            '2,0,18',
            '2,0,21',
            '2,0,22',
            '2,0,11',
            '2,0,13',
            '2,0,16',
            '2,0,15',
            '3,0,20',
            '3,0,13',
            '3,0,16',
            '3,0,19',
            '3,0,17',
            '3,0,21',
            '3,0,18',
            '3,0,12',
            '3,0,14',
            '3,0,22',
            '3,0,8',
            '4,0,13',
            '4,0,17',
            '4,0,20',
            '4,0,12',
            '4,0,21',
            '4,0,19',
            '4,0,16',
            '4,0,18',
            '4,0,14',
            '4,0,15',
            '5,0,17',
            '5,0,19',
            '5,0,18',
            '5,0,14',
            '5,0,15',
            '5,0,13',
            '5,0,16',
            '5,0,12',
            '6,0,14',
            '6,0,15',
            '6,0,18',
            '-5,1,20',
            '-4,1,20',
            '-4,1,15',
            '-4,1,19',
            '-4,1,17',
            '-4,1,16',
            '-4,1,18',
            '-4,1,14',
            '-3,1,15',
            '-3,1,19',
            '-3,1,14',
            '-3,1,17',
            '-3,1,18',
            '-3,1,20',
            '-3,1,16',
            '-3,1,13',
            '-3,1,12',
            '-3,1,7',
            '-2,1,11',
            '-2,1,20',
            '-2,1,13',
            '-2,1,19',
            '-2,1,14',
            '-2,1,15',
            '-2,1,16',
            '-2,1,12',
            '-2,1,18',
            '-2,1,17',
            '-2,1,21',
            '-2,1,22',
            '-2,1,6',
            '-2,1,7',
            '-1,1,7',
            '-1,1,14',
            '-1,1,15',
            '-1,1,13',
            '-1,1,11',
            '-1,1,21',
            '-1,1,12',
            '-1,1,22',
            '-1,1,20',
            '-1,1,23',
            '-1,1,8',
            '0,1,20',
            '0,1,6',
            '0,1,8',
            '0,1,7',
            '0,1,13',
            '0,1,22',
            '0,1,21',
            '0,1,23',
            '0,1,19',
            '0,1,18',
            '0,1,17',
            '1,1,6',
            '1,1,7',
            '1,1,17',
            '1,1,8',
            '1,1,18',
            '1,1,9',
            '1,1,19',
            '1,1,20',
            '1,1,22',
            '1,1,21',
            '1,1,12',
            '2,1,22',
            '2,1,12',
            '2,1,20',
            '2,1,13',
            '2,1,21',
            '2,1,19',
            '2,1,17',
            '2,1,9',
            '2,1,8',
            '2,1,18',
            '3,1,13',
            '3,1,12',
            '3,1,19',
            '3,1,20',
            '3,1,21',
            '3,1,22',
            '3,1,17',
            '3,1,14',
            '3,1,18',
            '3,1,16',
            '3,1,15',
            '4,1,12',
            '4,1,13',
            '4,1,14',
            '4,1,20',
            '4,1,19',
            '4,1,15',
            '4,1,21',
            '4,1,18',
            '4,1,16',
            '4,1,17',
            '5,1,18',
            '5,1,14',
            '5,1,17',
            '5,1,12',
            '5,1,15',
            '5,1,13',
            '5,1,16',
            '5,1,19',
            '6,1,13',
            '-4,3,20',
            '-3,3,20',
            '-3,3,22',
            '-3,3,21',
            '-3,3,23',
            '-3,3,24',
            '-3,3,19',
            '-2,3,24',
            '-2,3,20',
            '-2,3,23',
            '-2,3,22',
            '4,3,17',
            '4,3,16',
            '3,3,16',
            '-3,2,14',
            '-3,2,19',
            '-3,2,13',
            '-3,2,15',
            '-3,2,20',
            '-3,2,17',
            '-3,2,16',
            '-3,2,22',
            '-3,2,18',
            '-3,2,12',
            '-3,2,23',
            '-3,2,21',
            '-3,2,24',
            '-4,2,15',
            '-4,2,14',
            '-4,2,19',
            '-4,2,13',
            '-4,2,16',
            '-4,2,20',
            '-4,2,21',
            '-2,2,17',
            '-2,2,15',
            '-2,2,16',
            '-2,2,12',
            '-2,2,13',
            '-2,2,14',
            '-2,2,22',
            '-2,2,20',
            '-2,2,23',
            '-2,2,21',
            '-2,2,18',
            '-2,2,24',
            '-2,2,19',
            '-1,2,22',
            '-1,2,14',
            '-1,2,15',
            '-1,2,23',
            '-1,2,21',
            '-1,2,20',
            '0,2,22',
            '0,2,23',
            '0,2,21',
            '0,2,14',
            '1,2,22',
            '1,2,21',
            '1,2,24',
            '1,2,23',
            '1,2,20',
            '2,2,20',
            '2,2,21',
            '2,2,22',
            '2,2,23',
            '3,2,20',
            '3,2,21',
            '3,2,15',
            '3,2,16',
            '3,2,14',
            '3,2,19',
            '3,2,17',
            '3,2,22',
            '4,2,16',
            '4,2,15',
            '4,2,20',
            '4,2,19',
            '4,2,17',
            '4,2,21',
            '4,2,14',
            '4,2,18',
            '5,2,14',
            '5,2,16',
            '5,2,18',
            '5,2,13',
            '5,2,15',
            '5,2,12',
            '1,-7,18',
            '2,-7,18',
            '4,-7,17',
            '4,-7,16',
            '5,-7,17',
          ],
        },
      },
    },
    BRANCHING: {
      BRANCH: {
        ORDER: '1',
        SEG: [
          {
            S_COORDS: '0.0 ,0.0 ,0.0',
            DIA: '0.25',
          },
          {
            S_COORDS: '0.0 ,0.0 ,1.0',
            DIA: '0.25',
          },
        ],
      },
    },
  },
};
