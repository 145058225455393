import React, { useState } from 'react';
import Icon from '../Icon';

const Dropdown = ({ options = [], value = null, name, label, onChange = () => {}, onPureChange = () => {}, leadingIcon, error, warning }) => {
  const [isFocused, setIsFocused] = useState(false);
  const _handleSelect = (value) => {
    onChange({ name, value });
    onPureChange(value);

    setIsFocused(false);
    window.removeEventListener('click', _handleBlur);
  }

  const _handleFocus = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFocused(true);
    requestAnimationFrame(() => window.addEventListener('click', _handleBlur));
  }

  const _handleBlur = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFocused(false);
    window.removeEventListener('click', _handleBlur);
  }

  const selection = options.find(option => option.value === value);

  return (
    <div className={`dropdown-input-wrapper ${isFocused ? 'focused' : 'focused'} ${error ? 'error' : ''} ${warning ? 'warning' : ''}`}>
      <div onClick={_handleFocus} className={`dropdown-content-wrapper ${isFocused ? 'focused' : ''} ${error ? 'error' : ''} ${warning ? 'warning' : ''}`}>
        { leadingIcon && <Icon icon={leadingIcon} />}
        <span className={`label ${selection ? 'selected' : ''}`}>{ selection ? selection.label : label }</span>
        { (error || warning) && <span className={`alert ${error ? 'error' : ''} ${warning ? 'warning' : ''}`}>{ error || warning }</span> }
        <div className="arrow">
          <Icon icon={isFocused ? 'sort-up' : 'sort-down'} />
        </div>
      </div>
      <Options options={options} visible={isFocused} value={value} onSelect={_handleSelect} />
    </div>
  )
};

const Options = ({ options = [], visible, value, onSelect = () => {} }) => {
  return (
    <div className={`options-dropdown-wrapper ${visible ? 'visible' : 'hidden'}`}>
      {
        options.map(option => <Option active={value === option.value} onSelect={onSelect} {...option} key={option.value} />)
      }
    </div>
  )
}

const Option = ({ value, label, onSelect, active }) => {
  const _handleSelect = () => onSelect(value);
  return (
    <div className={`option-wrapper ${active ? 'active' : ''}`} onClick={_handleSelect}>
      <span className="label">{ label }</span>
    </div>
  )
}

export default Dropdown;