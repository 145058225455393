import { useState, useEffect, useRef } from 'react';

const useActionStack = (onUndo) => {
  /*
   { action: string, target, payload: { from: Object, to: Object }}
  */
  const [stack, setStack] = useState([]);
  const persistentStack = useRef([]);

  const _handleAction = (action, target, from, to) => {
    const _stack = [...persistentStack.current, { action, target, from, to }]
    console.log('ACTION LOGGED', action, target, from, to, _stack)
    saveStack(_stack);
  }

  const _handleUndo = () => {
    console.log('Undoing');
    const _stack = [...persistentStack.current];
    const action = _stack.pop();
    saveStack(_stack);

    if (!action) return;
    onUndo(action.action, action.target, action.from, action.to);
  }

  const saveStack = (stack) => {
    setStack(stack);
    persistentStack.current = stack;
  }

  const _handleKey = (e) => {
    if (e.key === 'z' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      _handleUndo();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', _handleKey);
    return () => window.removeEventListener('keydown', _handleKey);
  }, [stack.length]);

  return { isUndoAvailable: stack.length > 0, stack, onAction: _handleAction, onUndo: _handleUndo }
}

export default useActionStack;
