import React, { Fragment } from 'react';
import Icon from './Icon';
import Button from './inputs/Button';
import { useHistory } from 'react-router-dom';

const Finished = ({ callback = () => {}, done, title, action = {} }) => {
  const history = useHistory();
  
  const _handleBack = () => history.push(`/validation/${window.location.pathname.split('/')[2]}`);

  return (
    <div className="finished-wrapper">
      <Icon icon='rtms' />
      <h1>{ title || (!done ? 'Process finished' : 'TSE job started') }</h1>
      {
        !done && <Button primary label='Start TSE job' onClick={callback} { ...action } />
      }
      <Button label='Back to the map' onClick={_handleBack} />
    </div>
  )
};

const FinishedWrapper = ({ children, finished, onStart, done, ...props }) => {
  return (
    <Fragment>
      { finished && <Finished callback={onStart} done={done} {...props} /> }
      { children }
    </Fragment>
  )
}

export default FinishedWrapper;
