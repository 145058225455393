import * as THREE from "three";

const vertexShader = (
`uniform vec3 defaultColor;
varying vec3 vColor;
varying vec3 vPosition;

void main() {
  vColor = vec3( 1.0 );

  #ifdef USE_COLOR
  	vColor.xyz *= color.xyz;
  #endif
  #ifndef USE_COLOR
    vColor.xyz *= defaultColor.xyz;
  #endif

  vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
  gl_PointSize = 1.0;
  gl_Position = projectionMatrix * mvPosition;
  vPosition = position;
}`
);
const fragmentShader = (
`varying vec3 vColor;
varying vec3 vPosition;
uniform vec3 bottomLeft;
uniform vec3 topRight;

float insideBox3D(vec3 v, vec3 bottomLeft, vec3 topRight) {
  vec3 s = step(bottomLeft, v) - step(topRight, v);
  return s.x * s.y * s.z; 
}

void main() {
  if (insideBox3D(vPosition, bottomLeft, topRight) == 1.0) { discard; }

  gl_FragColor = vec4( vColor, 1.0 );
}`
);

export default class FilterPointsMaterial extends THREE.ShaderMaterial {
  constructor() {
    const bottomLeft = new THREE.Vector3(0, 0, 0);
    const topRight = new THREE.Vector3(0, 0, 0);
    const color = new THREE.Color(0xffffff);

    super({
      uniforms: { bottomLeft: { value: bottomLeft }, topRight: { value: topRight }, defaultColor: { value: color } },
      vertexShader,
      fragmentShader,
    });
  }

  set color(color) {
    this.uniforms.defaultColor.value.setHex(color);
  }

  set bottomLeft(bottomLeft) {
    this.uniforms.bottomLeft.value = bottomLeft;
  }

  set topRight(topRight) {
    this.uniforms.topRight.value = topRight;
  }
}
