import React, { useState, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom';
import { Route } from '../layout'
import { MobileOverlay } from '../components';
import { useUser } from '../providers/user';

import Uikit from './Uikit';
import Login from './Login';
import Home from './Home';
import NotFound from './NotFound';

import Validation from './Validation';
import Completed from './Completed';

const Navigation = () => {
  const { token } = useUser();
  return (
    <Fragment>
      <MobileOverlay />
      <Router>
        <Switch>
          <Route title='Uikit' path='/uikit' component={Uikit} />
          <Route title='Login' path='/login' component={Login} />
          {
            token && (
              <Fragment>
                <Route title='Validation' path='/validation/:MA?' component={Validation} />
                <Route title='Completed' path='/cma/:MA?' component={Completed} />
                
                <Route exact path='/' component={Home} />
                <Route title='Not found' path='*' component={NotFound} />
              </Fragment>
            )
          }
          <Redirect from='*' to='/login' />
        </Switch>
      </Router>
    </Fragment>
  )
};

export default Navigation;
