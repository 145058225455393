import React, { useEffect } from 'react';
import Popup from './Popup';
import { useConfig } from '../providers/config';

const Matching = ({ source = {}, matches = [], selected, onSelect = () => {}, onCurrentClick }) => {
  useEffect(() => {
    if (matches.length > 0) onSelect(matches?.[0]?.id)
  }, [source?.id, matches?.length])
  return (
    <div className="matching-wrapper">
      <div className="source-wrapper">
        <span className="title">Current tree</span>
        <Tree { ...source } primary onSelect={onCurrentClick} />
      </div>
      <div className="matches-wrapper">
        <span className="title">Matches</span>
        <div className="match-container">
          {
            matches?.sort((a, b) => b.probability - a.probability).map(match => <Tree isMaven active={match.id === selected} { ...match } onSelect={onSelect} />)
          }
          <NoMatch active={!selected || !matches?.find(match => match.id === selected)} onSelect={onSelect} />
        </div>
        {/* <div className="matches">
        </div> */}
      </div>
    </div>
  )
}

const NoMatch = ({ active, onSelect }) => {
  const _handleSelect = () => onSelect(null);
  return (
    <div className="tree-outer-wrapper no-match" onClick={_handleSelect}>
      <div className={`tree-wrapper ${active ? 'active' : ''} selectable`}>
        <span className="title">No Match Found</span>
      </div>
    </div>
  )
}


/**
 * - ID
 * - STATUS
 * - PROBABILITY
 */
const round = val => Math.round(val * 100) / 100;
const Tree = ({ active, primary, id, status, onSelect, height, girth_1_0m, girth_1_3m, probability, girthDifference, distance, common_nm, isMaven }) => {
  const _handleClick = () => onSelect?.(id);
  const { getConfig } = useConfig();
  return (
    <div className="tree-outer-wrapper">
      <Popup title={typeof(probability) !== 'undefined' && `Girth difference: ${round((1 - girthDifference) * 100)}% [BR] Distance: ${round(distance)} m`} direction={'top'}>
        <div
          className={`tree-wrapper ${primary ? 'primary' : ''} ${active ? 'active' : ''} ${onSelect ? 'selectable' : ''}`}
          style={(isMaven && !active) ? { borderColor: getConfig('colors.maven') } : null}
          onClick={_handleClick}
        >
          <div className="header">
            <span className="title">{ id }</span>
            { (typeof(probability) !== 'undefined') && <span className="probability">{round(probability * 100)}%</span> }
          </div>
          <Info label='Height' value={height} />
          <Info label='Scientific name' value={common_nm} />
          <Info label='Girth – 1.0 m' value={`${round(girth_1_0m) || 'NA'} m`} />
        </div>
      </Popup>
    </div>
  )
}

const Info = ({ label, value }) => {
  return (
    <div className="detail-wrapper">
      <span className="label">{ label }</span>
      <span className="value">{ value || '-' }</span>
    </div>
  )
}

export default Matching;
