import React, { useState } from 'react';
import { Skeleton } from '../layout';
import { TextInput, Button } from '../components/inputs';
import { withRouter } from 'react-router-dom';
import { useUser } from '../providers/user';

const LoginPage = ({ history }) => {
  const { setApiToken } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const _handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(`${window._env_.REACT_APP_SERVER_ENDPOINT || process.env.REACT_APP_SERVER_ENDPOINT}/login`, { 
        method: 'POST',
        body: JSON.stringify({
          email,
          password
        }),
        headers: { 'Content-Type': 'application/json' }
      });
      const data = await res.text()

      if (res.status === 500) {
        setPassword('');
        setError('Internal server error');
      }
      else if (res.status !== 200 || !data) {
        setPassword('');
        setError('Wrong username or password.')
      } else {
  
        setApiToken(data);
        history.push('/');

      }
    } catch (e) {
      console.log(e);

    }

    // history.push('/');
  }

  const _handlePassword = (val) => {
    setPassword(val);
    setError('');
  }
  return (
    <Skeleton header={{
      title: 'Login'
    }}>
      <div className="login-wrapper">
        {/* <video muted autoPlay src="/login-background.mp4" loop></video> */}
        <form onSubmit={_handleSubmit}>
          <TextInput value={email} onPureChange={setEmail} leadingIcon='user' label='Email' />
          <TextInput value={password} error={error} onPureChange={_handlePassword} leadingIcon='key' type='password' label='Password' />
          <Button label='Login' primary />
        </form>
      </div>
    </Skeleton>
  )
}

export default withRouter(LoginPage);