import generateStyles from './generateStyles';

/**
 * Generate layer object
 */
const generateLayer = (layer, sources) => {
  if (!layer) throw new Error(`[MAP] [ERROR]: Failed to generate layer: layer is not defined`)

  if (layer.type === 'line' || layer.geojson) return  ({ ...layer, paint: generateStyles(layer), color: undefined });

  if (!sources[layer.source]) throw new Error(`[MAP] [ERROR]: Failed to generate layer: missing source for layer: ${layer.id} (${layer.source})`);

  if (layer.type !== 'fill-outline') {
    const paint = generateStyles(layer);
    layer = ({ ...layer, paint, color: undefined, opacity: undefined, 'source-layer': sources[layer.source] });
    return layer;
  } else {
    const fillLayer = ({ ...layer, id: layer.id + '-fill', type: 'fill', paint: generateStyles({ ...layer, type: 'fill' }), color: undefined, opacity: undefined, 'source-layer': sources[layer.source] });
    const lineLayer = ({ ...layer, id: layer.id + '-line', type: 'line', paint: generateStyles({ ...layer, type: 'line' }), color: undefined, opacity: undefined, 'source-layer': sources[layer.source] });
    return [fillLayer, lineLayer]
  }
}

export default generateLayer;