import React, { useEffect } from 'react';
import Icon from './Icon';
import Popup from './Popup';
import { useTheme } from '../providers/theme';
import { useUser } from '../providers/user';
import { useModal } from '../providers/modal';
import { withRouter } from 'react-router-dom';

import { Preferences } from './modals';
import Help from './Help';

const METAKEYS = {
  'ALT': 'altKey',
  'CTRL': 'ctrlKey',
  'CMD': 'metaKey'
};

const GENERATE_ACTIONS = ({ presentModal = () => {}, user, history, dismissModal, setApiToken }) => [
  user && {
    icon: 'bell',
    title: 'Notifications',
  },
  user && {
    icon: 'cog',
    title: 'User preferences',
    action: () => presentModal({ title: 'User preferences', content: <Preferences history={history} dismissModal={dismissModal} /> }),
    accelerator: ['CMD+Comma', 'CTRL+KeyU']
  },
  // {
  //   icon: 'question',
  //   title: 'Help',
  //   accelerator: 'F1',
  //   action: () => presentModal({ title: 'Help', content: <Help /> }),
  // },
  user && {
    icon: 'sign-out-alt',
    title: 'Sign out',
    action: () => {
      // TODO: implement logout functionality
      setApiToken(null);
    }
  },
].filter(_ => !!_);

const Action = ({ icon, title, action = () => {} }) => {
  return (
    <Popup title={title}>
      <div title={title} className="action-wrapper regular-button-states" onClick={action}>
        <Icon icon={icon || 'coffee'} />
      </div>
    </Popup>
  )
};

const Header = ({ title = 'PAGE TITLE', user = null, history }) => {
  const { presentModal, dismissModal } = useModal();
  const { isDark } = useTheme();
  const { setApiToken } = useUser();

  const actions = GENERATE_ACTIONS({ presentModal, user, history, dismissModal, setApiToken });

  const _checkAccelerator = (action, e) => {
    if (Array.isArray(action.accelerator)) return action.accelerator.forEach(accelerator => _checkAccelerator({ ...action, accelerator }, e))
    const keys = action.accelerator.split('+');
    const meta = keys.map(key => METAKEYS[key]).filter(meta => !!meta)[0];
    const keyCode = keys.filter(key => !METAKEYS[key])[0];
    if (e.code === keyCode && (meta ? e[meta] : true)) {
      e.preventDefault();
      e.stopPropagation();
      action.action();
    }
  }
  const _handleKeyDown = (e) => {
    const { key } = e;
    actions.filter(action => !!action.accelerator).forEach(action => _checkAccelerator(action, e));
  }

  useEffect(() => {
    window.addEventListener('keydown', _handleKeyDown);
    return () => window.removeEventListener('keydown', _handleKeyDown);
  }, []);

  const _handleHome = () => history.push('/');

  return (
    <nav className={isDark ? 'dark' : 'light'}>
      <div className="logo-wrapper regular-button-states" onClick={_handleHome}>
        <Icon icon='nparks' />
        <Icon icon='rtms' />  
        <div className="caption">
          <h1>RTMS</h1>
          <hr/>
          <h2>Remote Tree Measurement System</h2>
        </div>
      </div>  
      <div className="title-wrapper">
        { title }
      </div>
      <div className="actions-wrapper">
        {
          actions.map(action => <Action key={`${action.title}-${action.icon}`} { ...action } />)
        }
      </div>
    </nav>
  )
}

export default withRouter(Header);
