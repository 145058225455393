import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import Button from '../inputs/Button';
import Icon from '../Icon';

import mapbox from 'mapbox-gl';
import { withTheme } from '../../providers/theme';
import { withUser } from '../../providers/user';

// Components
import MapControls from './MapControls';

// Helpers
import generateLayer from './generateLayer';

// Contants
import { STYLES, GEOM_COLUMNS, BOUNDS, COLUMNS, ID_COLUMNS } from './config';

const TOKEN =
  process.env.NODE_ENV !== 'development'
    ? window.API_KEY
    : process.env.REACT_APP_SERVER_TOKEN;

// Access token
mapbox.accessToken =
  window._env_.REACT_APP_MAPBOX_ACCESS_TOKEN ||
  process.env.REACT_APP_MAPBOX_TOKEN;

class Map extends PureComponent {
  state = {
    lng: 103.86,
    lat: 1.35,
    zoom: 10,
    isCtrlPressed: false,
  };

  _resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      this.updateRatio();
    }
  });

  _popupVisible = false;
  _popupLayer = null;
  _popup = new mapbox.Popup({
    closeOnClick: false,
    closeButton: false,
  });
  _loaded = false;

  _start = null;

  // Refs
  _map;
  _container;

  _sources = {};
  _sourceVisible = {};
  _layers = [];
  _layerVisible = {};

  _position = {};

  _handleKey = (e) => {
    if (e.key === 'A') console.warn(e.key, e.shiftKey, !this.state.isCtrlPressed);
    if (e.key === 'A' && e.shiftKey) this.setState({ isCtrlPressed: !this.state.isCtrlPressed });
  };

  _handleAddToggle = () => this.setState({ isCtrlPressed: !this.state.isCtrlPressed });

  //Map methods
  getCenter = () => this._map.getCenter();
  getZoom = () => this._map.getZoom();

  getPopup = () => this._popup;
  getMap = () => this._map;

  getBounds = (features = []) => {
    // TODO!
  };

  /**
   * Center map on coordinates
   * @param {[[lng, lat], ...]} coords - The bbox of the features
   */
  focusOnBBox = (coords = []) => {
    if (!this._map) return setTimeout(() => this.focusOnBBox(coords), 500);
    // Getting center
    const bounds = new mapbox.LngLatBounds();
    Array.groupByTwo(coords.slice().flat(100)).forEach(
      (coords) => coords.every((coord) => !!coord) && bounds.extend(coords)
    );
    this._map?.fitBounds(bounds, { padding: 96 });
  };

  focusOnPoint = (coords, level = 16) => {
    if (!this._map) return setTimeout(() => this.focusOnPoint(coords, level), 500);
    // Getting center
    const bounds = new mapbox.LngLatBounds();
    Array.groupByTwo(coords.slice().flat(100)).forEach(
      (coords) => coords.every((coord) => !!coord) && bounds.extend(coords)
    );
    this._map?.flyTo({ center: bounds.getCenter(), zoom: level });
  }

  // Get map style based on config
  getStyle = () => {
    if (this.props.satellite) return STYLES.satellite;
    if (this.props.elevated) return STYLES.elevated;
    if (this.props.theme.isDark) return STYLES.dark;
    return STYLES.light;
  };

  /**
   * Toggle layer visibilities
   * @param {*} layer
   */
  handleLayerVisibility = (layers = this.props.layerVisible) => {
    Object.keys(layers).forEach((layerId) => {
      if (this._layers[layerId]) {
        if (!layers[layerId] && layerId === this._popupLayer) this._handlePopupClose();
        this.toggleLayer(layerId, layers[layerId]);
      }
    });
  };

  handleSourceVisibility = (sources = this.props.sourceVisible) => {
    Object.keys(sources).forEach((sourceId) => {
      // console.log(sourceId, sources[sourceId]);
      this.toggleSource(sourceId, sources[sourceId]);
    });
  };

  toggleLayer = (layerId, visible) => {
    this._layerVisible[layerId] = visible;
    this._map.setLayoutProperty(
      layerId,
      'visibility',
      visible ? 'visible' : 'none'
    );
  };

  toggleSource = (sourceId, visible) => {
    this._sourceVisible[sourceId] = visible;

    Object.keys(this._layers).forEach((key) => {
      let layer = this._layers[key];
      if (layer.source === sourceId) {
        let layerVisible = visible && (this._layerVisible[layer.id] || this._layerVisible[layer.id] === undefined);
        
        this._map.setLayoutProperty(
          layer.id,
          'visibility',
          layerVisible ? 'visible' : 'none'
        );
      }
    });
  };

  // Data methods
  clearSources() {
    this._sources = [];
  }

  /**
   * Add source to table
   * @param ID source the id of the source
   * @param ID table - the path to the table
   */
  addSource = (source, table, data) => {
    if (this._sources[source])
      return console.log(
        `[MAP] [WARNING]: Tried to add same source multiple times: ${source}`
      );
    this._sources[source] = table;

    if (data) return this._map.addSource(source, {
      data,
      type: 'geojson'
    });
    // const url = `${process.env.REACT_APP_SERVER_ENDPOINT}/v1/mvt/${table}/{z}/{x}/{y}?key=${process.env.REACT_APP_SERVER_TOKEN}&geom_column=${GEOM_COLUMNS[table]}&columns=${COLUMNS[table]}${ID_COLUMNS[table] ? '&id_column=' + ID_COLUMNS[table] : ''}`;
    const url = `${
      window._env_.REACT_APP_SERVER_ENDPOINT ||
      process.env.REACT_APP_SERVER_ENDPOINT
    }/v1/mvt/${table}/{z}/{x}/{y}?key=${this.props.user?.token}&geom_column=${
      GEOM_COLUMNS[table]
    }&columns=${COLUMNS[table]}${
      ID_COLUMNS[table] ? '&id_column=' + ID_COLUMNS[table] : ''
    }`;

    this._map.addSource(source, {
      type: 'vector',
      tiles: [url],
      minzoom: BOUNDS[table][0],
      maxzoom: BOUNDS[table][1],
    });
  };

  killCodes = {};

  setDataForSource = (sourceId, data) => {
    if (this.killCodes[sourceId]) clearTimeout(this.killCodes[sourceId]);
    if (!this._loaded) this.killCodes[sourceId] = setTimeout(() => this.setDataForSource(sourceId, data), 500);
    const source = this._map?.getSource(sourceId);
    source?.setData?.(data);
  }

  /**
   * Parse raw layer input
   * @param { the raw layer input } layers
   */
  parseLayers = (layers) =>
    layers.forEach((layer) => {
      this.addLayer(layer);
    });

  /**
   * Add layer to map
   * @param inputLayer the layer input object
   * @param id the id of the layer
   */
  addLayer = (layer) => {
    const { below } = layer;
    layer = generateLayer(layer, this._sources);

    // If layer is an array recurse over it
    if (Array.isArray(layer)) {
      return layer.forEach((layer) => this.addLayerToMap(layer, below));
    } else {
      this.addLayerToMap(layer, below);
    }
  };
  
  updateLayerFilter = layer => {
    if (!layer.filter) return;
    this._map.setFilter(layer.id, ['all', layer.filter]);
  }
  updateLayerColor = layer => {
    const { paint } = generateLayer(layer, this._sources);
    Object.keys(paint).forEach(colorKey => this._map.setPaintProperty(layer.id, colorKey, paint[colorKey]));
  }

  addLayerToMap(layer, below) {
    this._layers[layer.id] = layer;
    // Setting map local variable
    const map = this._map;

    // Add layer to map
    map.addLayer(layer, below);

    if (layer.filter) map.setFilter(layer.id, ['all', layer.filter]);

    if (layer.onClick || layer.popup || layer.clickable) {
      map.on('click', layer.id, this._handleClick(layer));

      // Change the cursor over the layer
      map.on('mouseenter', layer.id, () => {
        if (this.state.isCtrlPressed) return;
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', layer.id, () => {
        if (this.state.isCtrlPressed) return;
        map.getCanvas().style.cursor = '';
      });

      // Handling hover state
      let hoverId = null;

      map.on('mousemove', layer.id, (e) => {
        const currentHoverId = e.features[0].id;
        if (hoverId !== currentHoverId)
          this._handleLayerHover(false, layer, hoverId);
        hoverId = currentHoverId;
        if (hoverId) this._handleLayerHover(true, layer, hoverId);
      });
      map.on('mouseleave', layer.id, (e) => {
        this._handleLayerHover(false, layer, hoverId);
        hoverId = null;
      });
    }
  }

  _resizeTimeout;
  updateRatio = () => {
    clearTimeout(this._resizeTimeout);
    this._resizeTimeout = setTimeout(() => this._map?.resize(), 100);
    // requestAnimationFrame(() => this._map?.resize());
  }

  /**
   * Setting hover feature state
   * @param {boolean} hover the next hover state
   * @param {any} layer the layer object
   * @param {string} id the feature id
   */
  _handleLayerHover = (hover, layer, id) =>
    this._map.setFeatureState(
      {
        sourceLayer: layer['source-layer'],
        source: layer.source,
        id,
      },
      { hover: hover }
    );

  /**
   * Setting active featurestate for all layers
   */
  _prevActives = {};
  _handleActive = () => {
    const { active = {} } = this.props;
    Object.keys(this._layers).map((layerId) => {
      const layer = this._layers[layerId];
      if (this._prevActives[layerId])
        this._map.setFeatureState(
          {
            sourceLayer: layer['source-layer'],
            source: layer.source,
            id: this._prevActives[layerId],
          },
          { active: false }
        );
      if (active[layerId])
        this._map.setFeatureState(
          {
            sourceLayer: layer['source-layer'],
            source: layer.source,
            id: active[layerId],
          },
          { active: true }
        );
    });

    this._prevActives = { ...active };
  };

  showPopup = (id, centre, feature) => {

    const layer = this.props.layers.find(item => item.id === id);
    if (layer) {
      const Popup = layer.popup;
      this._popupVisible = true;
      this._popupLayer = id;
      this.getPopup().setLngLat(centre).setHTML(ReactDOMServer.renderToString(<Popup feature={feature} onClose={this._handlePopupClose} />));
      const close = document.querySelector('#popup-close-wrapper');
      close.removeEventListener('click', this._handlePopupClose);
      close.addEventListener('click', this._handlePopupClose);
    }

  };

  /**
   * Layer click callback
   * @param {*} layer
   */
  _handleClick = ({ popup: Popup, onClick, centerOnClick, id, clickable }) => (e) => {
    if (this.state.isCtrlPressed) return;
    if (e.defaultPrevented) return;
    e.preventDefault();

    // console.log(e);

    // if (id !== 'mas') e.preventDefault();
    // The target feature
    const feature = e.features[0];
    const coordinates = Array.groupByTwo(
      feature.geometry.coordinates.slice().flat(100)
    );

    // Getting center
    const bounds = new mapbox.LngLatBounds();
    coordinates.forEach(
      (coords) => coords.every((coord) => !!coord) && bounds.extend(coords)
    );
    const centre = bounds.getCenter();

    const coord = e.lngLat;

    // fit to feature
    if (centerOnClick) this._map.fitBounds(bounds, { padding: 96 });

    // Calling onClick method
    if (clickable) this.props.onSelect(centre, feature, id);
    if (onClick) onClick(centre, feature, id);
    if (Popup) {
      this.showPopup(id, centre, feature);
    }
  };

  _handlePopupClose = () => {
    // This is very bad, but working
    this.getPopup().setLngLat({ lng: 0, lat: 0 });
    this._popupVisible = false;
    document.querySelector('#popup-close-wrapper').removeEventListener('click', this._handlePopupClose);
  }

  /**
   * Mapbox's style loaded, add sources and layers
   * PRIVATE
   */
  _handleStyleLoad = () => {
    // Sources
    // console.log('[MAP] [INFO]: Map loaded, it took: ', new Date().getTime() - this._start, 'ms')
    const sources = this.props.sources || {};
    this.clearSources();
    sources.forEach((source) => this.addSource(source.id, source.source, source.data));
    // console.log('[MAP] [INFO]: Source parsing done, it took: ', new Date().getTime() - this._start, 'ms')

    this.parseLayers(this.props.layers || {});
    // console.log('[MAP] [INFO]: Layer parsing done: ', this._layers, ' it took: ', new Date().getTime() - this._start, 'ms')
    this.handleLayerVisibility();
    // console.log('[MAP] [INFO]: Layer visibility done, it took: ', new Date().getTime() - this._start, 'ms')
    this._handleActive();

    this._map.on('click', (e) => {
      if (e.defaultPrevented) return;
      this.props.onPureClick?.(e);
      if (this.state.isCtrlPressed && this.props.onAdd) {
        this.setState({ isCtrlPressed: false });
        this.props.onAdd(e);
      }
    });

    this._map.on('zoom', this._handleZoomEnd)

    this.getPopup().addTo(this._map);
    this._loaded = true;
  };

  _handleZoomEnd = () => {
    if (!this._popupVisible) return;
    if (this.getZoom() < 15) this._handlePopupClose();
  }

  updateStyle = () => {
    this._map.setStyle(this.getStyle());
  };

  componentDidMount = () => {
    // window.addEventListener('keydown', this._handleKey);
    // window.addEventListener('keyup', this._handleKey(false));
    requestAnimationFrame(() => {
      if (!this._container) return console.log('[MAP] [ERROR]: missing container');
      this._start = new Date().getTime();
      this._resizeObserver.observe(this._container);
      // this._map = new mapbox.Map({
      //   container: this._container,
      //   style: this.getStyle(),
      //   center: [this.state.lng, this.state.lat],
      //   zoom: this.state.zoom,
      //   minZoom: 10,
      // });

      this._map = new mapbox.Map({
        container: this._container,
        style: this.getStyle(),
        center: [this.state.lng, this.state.lat],
        zoom: this.state.zoom,
        minZoom: 10,
        dragRotate: false,
        boxZoom: false,
      });
      const scale = new mapbox.ScaleControl({
        maxWidth: 80,
        unit: 'metric',
      });
      this._map.addControl(scale);
      scale.onAdd(this._map);

      this._map.on('style.load', this._handleStyleLoad);
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.theme.isDark !== this.props.theme.isDark || this.props.satellite !== prevProps.satellite || this.props.elevated !== prevProps.elevated) this.updateStyle();
    if (JSON.stringify(prevProps.active) !== JSON.stringify(this.props.active)) {
      this._handleActive();
    }
    if (JSON.stringify(prevProps.sourceVisible) !==JSON.stringify(this.props.sourceVisible)) this.handleSourceVisibility();
    if (JSON.stringify(prevProps.layerVisible) !== JSON.stringify(this.props.layerVisible)) this.handleLayerVisibility();
    if (prevState.isCtrlPressed !== this.state.isCtrlPressed) this._map.getCanvas().style.cursor = (this.state.isCtrlPressed && !!this.props.onAdd) ? 'crosshair' : '';
    this.props.layers.forEach(layer => {
      const prevLayer = prevProps.layers.find(lyr => lyr.id === layer.id);
      if (JSON.stringify(prevLayer?.filter) !== JSON.stringify(layer?.filter)) this.updateLayerFilter(layer);
      if (JSON.stringify(prevLayer?.color) !== JSON.stringify(layer?.color)) this.updateLayerColor(layer);
    })
  };

  componentWillUnmount = () => {
    // window.removeEventListener('keydown', this._handleKey);
    // window.removeEventListener('keyup', this._handleKey(false));
  };

  _handleZoom = (direction = 1) => {
    this._map.flyTo({
      center: this.getCenter(),
      zoom: this.getZoom() + (direction / Math.abs(direction)) * 0.5,
    });
  };

  _handleZoomIn = () => this._handleZoom();
  _handleZoomOut = () => this._handleZoom(-1);

  _handleFitToBounds = () => this.props.onFocusReset?.(this.getCenter());

  render = () => (
    <div className='map-outer-wrapper'>
      <MapControls
        fitToBounds={this._handleFitToBounds}
        zoomIn={this._handleZoomIn}
        zoomOut={this._handleZoomOut}
        isAdding={this.state.isCtrlPressed}
        isAddVisible={!!this.props.onAdd}
        onAddToggle={this._handleAddToggle}
        onUnselect={this.props.onUnselect}
      />
      <div ref={(el) => (this._container = el)} className='map-wrapper'></div>
      <div className='powered'>
        <span>powered by</span>
        <Icon icon='gh' />
      </div>
      <div className='map-actions-wrapper'>
        <div className='action-wrapper'>
          {this.props.secondary && <Button {...this.props.secondary} />}
        </div>
        <div className='action-wrapper'>
          {this.props.action && <Button {...this.props.action} />}
        </div>
      </div>
    </div>
  );

  static defaultProps = {
    onMove: () => {},
  };
}

Map.defaultProps = {
  sources: [],
  sourceVisible: {},
  layers: [],
  layerVisible: {},
  onSelect: () => {},
  action: null,
  secondary: null,
};

export default withUser(withTheme(Map));
