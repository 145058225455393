import React, { createContext, useContext, useState, useEffect } from 'react';
import { Icon } from '../components';
export const Context = createContext({ });

export const useModal = () => useContext(Context);

export const withModal = (Component) => props => <Context.Consumer>{theme => <Component { ...props } theme={theme} />}</Context.Consumer>;

export const Provider = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState(null);
  /**
   * Present new modal
   * @param { title: the title of the modal, content: the content of the modal, should be a react component } content? 
   */
  const presentModal = (content) => {
    setContent(content);
    setIsVisible(true);
  }

  /**
   * Dismiss modal
   */
  const dismissModal = () => setIsVisible(false);
  
  /** Handling keyboard event */
  const _handleKeyDown = (e) => (e.key === 'Escape') && setIsVisible(false);
  
  useEffect(() => {
    window.addEventListener('keydown', _handleKeyDown);
    return () => window.removeEventListener('keydown', _handleKeyDown);
  }, []);
  return (
    <Context.Provider value={{ isVisible, presentModal, dismissModal }}>
      <Modal { ...{ isVisible, ...content, dismissModal }} />
      { props.children }
    </Context.Provider>
  )
};

const Modal = ({ isVisible, title = 'MODAL TITLE', content = null, dismissModal = () => {} }) => {
  return (
    <div className={`modal-outer-wrapper ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="backdrop" onClick={dismissModal} />
      <div className="modal-wrapper">
        <div className="header">
          <span>{ title }</span>
          <div className="close-wrapper" onClick={dismissModal}>
            <Icon icon='times' />
          </div>
        </div>
        <div className="content-wrapper">
          { content }
        </div>
      </div>
    </div>
  )
}