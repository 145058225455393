import React, { useState, useRef } from 'react';
import Icon from '../components/Icon';

const SNAPPING = 0.1;
const MAPPING = {
  horizontal: 'x',
  vertical: 'y'
}

export const Grid = ({ children, tabcontent, infocontent, defaultLayout = { x: .2, y: .2 } }) => {
  const [layout, setLayout] = useState(defaultLayout);
  const previousLayout = useRef({ x: 0, y: 0 });
  const isSetting = useRef({ horizontal: false, vertical: false });
  const wrapper = useRef(null);

  const _handleMouseMove = ({ clientX, clientY }) => {
    if (!wrapper.current) return;
    // Getting bounding box of parent
    const { x: xOffset, y: yOffset, width, height } = wrapper.current.getBoundingClientRect();
    
    // Calculating relative positions, clamping values between 0-1, and aplying snappoints
    const [x, y] = [1 - (clientX - xOffset) / width, 1 - (clientY  - yOffset) / height]
      .map(val => Math.min(1, Math.max(0, val)))
      .map(val => val < SNAPPING ? 0 : (val > (1 - SNAPPING) ? 1 : val));

    // Generating temp layout
    const _layout = { x, y };

    const { horizontal, vertical } = isSetting.current;

    // Copying previous layout
    const layout = { ...previousLayout.current };
   
    // updating layout
    Object.keys(MAPPING).forEach(key => (isSetting.current[key]) && (layout[MAPPING[key]] = _layout[MAPPING[key]]));
    
    // Setting layout
    setLayout(layout);
  }

  const _handleSegmentToggle = (direction) => {
    previousLayout.current = layout;
    const key = MAPPING[direction];
    setLayout({ ...layout, [key]: layout[key] > 0 ? 0 : direction !== 'horizontal' ? .4 : .2 })
  }

  const _handleMouseDown = ({ horizontal = false, vertical = false }) => {
    isSetting.current = { horizontal, vertical }
    previousLayout.current = layout;
    window.addEventListener('mousemove', _handleMouseMove)
    window.addEventListener('mouseup', _handleMouseUp)
  }
  const _handleMouseUp = () => {
    isSetting.current = { horizontal: false, vertical: false }
    window.removeEventListener('mousemove', _handleMouseMove)
    window.removeEventListener('mouseup', _handleMouseUp)
  }
  return (
    <div className="grid-wrapper" ref={wrapper}>
      <div className="top-segment">
        <div className="main-content">
          { children }
        </div>
        { tabcontent && <Segment onToggle={_handleSegmentToggle} direction='horizontal' mouseDown={_handleMouseDown} layout={layout} content={tabcontent} />}
      </div>
      { infocontent && <Segment onToggle={_handleSegmentToggle} direction='vertical' mouseDown={_handleMouseDown} layout={layout} content={infocontent} />}
    </div>
  )
}

const DIMENSIONS = {
  horizontal: 'width',
  vertical: 'height'
};

const Segment = ({ direction, mouseDown = () => {}, layout, content, onToggle = () => {} }) => {
  const size = layout[MAPPING[direction]];
  const _handleMouseDown = () => mouseDown({ vertical: direction === 'vertical', horizontal: direction === 'horizontal' });
  const _handleDouble = () => onToggle(direction)
  const style = { [DIMENSIONS[direction]]: `${size * 100}%` }
  return (
    <div className={`segment-wrapper ${direction}`} style={style}>
      <div
        className="handler-wrapper"
        onMouseDown={_handleMouseDown}
        onDoubleClickCapture={_handleDouble}
      >
        <div className="handler" />
        <div className="handler-arrow">
          <Icon icon='grip-lines-vertical' />
        </div>
      </div>
      { content }
    </div>
  )
}

const GridHOC = (Component = null, gridProps = {}) => (props) => {
  return (
    <Grid { ...gridProps}>
      { Component && <Component { ...props } />}
    </Grid>
  )
}

export default GridHOC;