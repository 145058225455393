import React from 'react';
import Icon from '../Icon';

const Button = ({ label, primary, destructive, onClick = () => {}, name, disabled, color, leadingIcon, icon, id }) => {
  return (
    <button id={id || null} className={`button-wrapper regular-button-states ${primary ? 'primary' : ''} ${destructive ? 'destructive' : ''} ${disabled ? 'disabled' : ''}`} onClick={onClick}>
      { leadingIcon && <Icon icon={leadingIcon} />}
      <span className="label">{label}</span>
      { icon && <Icon icon={icon} />}
    </button>
  )
}

export default Button;
