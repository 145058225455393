import React, { useRef, useEffect } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

const CarouselView = ({ carouselData }) => {
  if (carouselData.images && carouselData.images.length) {
    return (
      <div className="carouselParent">
        <Carousel
          className="carouselView"
          showArrows={true}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          swipeable={false}
          autoPlay={false}
          infiniteLoop={true}
        >
          {carouselData.images.map((image, index) => (
            <div key={`carouselKey_${index}`} className="carouselItem">
              <div className="carouselData">
                <TransformWrapper
                  defaultScale={1}
                  defaultPositionX={200}
                  defaultPositionY={100}>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                      <TransformComponent>
                        <Image src={image.src} />
                      </TransformComponent>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    )
  } else {
    return <h1>NO IMAGES</h1>
  }
}

const Image = ({ src }) => {
  const canvasref = useRef(null);
  const imageref = useRef(null);
  useEffect(() => {
    _handleLoad();
  }, []);
  const _handleLoad = (e) => {
    const ctx = canvasref.current?.getContext("2d");
    if (!ctx) return;
    ctx?.setTransform(
      0, 1,
      -1,0,
      2000, 0
    );
    ctx?.drawImage(imageref.current, 0, 0, 2000, 2000);
  }
  const _handleError = () => {
    const ctx = canvasref.current?.getContext("2d");
    if (!ctx) return;
    ctx.font = "30px Poppins";
    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.fillText("Image not found", canvasref.current.width/2, canvasref.current.height/2);
  }
  return (
    <div className="image-wrapper">
      <canvas key={src} ref={canvasref} width="2000" height="2000" />
      <img
        onLoad={_handleLoad}
        onError={_handleError}
        src={src} 
        alt={`Tree ${src}`} 
        ref={imageref}
      />
    </div>
  )
}

export default CarouselView
