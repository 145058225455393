import React from 'react';
import { Provider as ThemeProvider } from './theme';
import { Provider as ModalProvider } from './modal';
import { Provider as PopupProvider } from './alert';
import { Provider as ConfigProvider } from './config';
import { Provider as ErrorProvider } from './error';
import { Provider as DataProvider } from './data';
import { Provider as UserProvider } from './user';

const Providers = ({ children }) => {
  return (
    <UserProvider>
      <ThemeProvider>
        {/* <ErrorProvider> */}
          <ConfigProvider>
            <DataProvider>
              <ModalProvider>
                <PopupProvider>
                  { children }
                </PopupProvider>
              </ModalProvider>
            </DataProvider>
          </ConfigProvider>
        {/* </ErrorProvider> */}
      </ThemeProvider>
    </UserProvider>
  )
}

export default Providers;
