const pairTrees = (source = [], target = [], { girthDifference = 15, maxDistance = 3 } = {}) => {
  return source.map(tree => {
    const [lon, lat] = tree.location.coordinates;
    return {
      tree,
      matches: filterByDistance(target, [lon, lat], maxDistance, tree.girth_1_0m, girthDifference, tree).sort((a, b) => b.probability - a.probability)
    }
  });
}

const handleProbability = ([lon, lat], girth = 0, maxDistance, maxGirthDifference, targetTree) => (tree) => {
  const distance = getDistance(lon, lat, ...JSON.parse(tree.geom).coordinates)
  
  tree.grth_size = parseFloat(tree.grth_size);
  
  // Calculating girth probability
  const girthDifference = Math.abs((girth || 0) - (tree.grth_size || 0));
  const girthProbability = (1 - Math.min(1, girthDifference / maxGirthDifference));
  
  // Calculating distance probability
  const distanceProbability = (1 - Math.min(1, distance / maxDistance));

  return ({ tree, distance, girthDifference, probability: girthProbability * distanceProbability, girthProbability });
}

const filterByDistance = (trees = [], center = [0, 0], maxDistance = 15, girth, girthDifference, tree) => trees?.map(handleProbability(center, girth, maxDistance, girthDifference, tree)).filter(tree => tree.probability > 0) || [];

export const getDistance = (lat1, lon1, lat2, lon2) => {
	const R = 6371e3; // metres
  const φ1 = lat1 * Math.PI/180; // φ, λ in radians
  const φ2 = lat2 * Math.PI/180;
  const Δφ = (lat2-lat1) * Math.PI/180;
  const Δλ = (lon2-lon1) * Math.PI/180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  const d = R * c; // in metres

  return d;
}

export const generateLines = (trees) => {
  return trees.filter(_ => !!_).map(({ tree, matches }) => matches.map(({ tree: match, probability }) => {
    const maven = JSON.parse(match.geom).coordinates;
    const rtms = tree.location.coordinates;
    const id = parseInt(`${tree.id}${match.id}`);
    return { coordinates: [maven, rtms], id, probability, maven: match.id, rtms: tree.id };
  })).flat();
}

export default pairTrees;