import React from 'react';
import Icon from './Icon';
import Progress from './Progress';

const ProgressControls = ({ progress, actions = [], max, value, disabled }) => {
  const currentValue = `00000000000${Math.max(0, value)}`.slice(-String(max).length);
  const label = `${currentValue} / ${max}`;

  return (
    <div className={`progress-controls-wrapper ${disabled ? 'disabled' : ''}`}>
      <Progress { ...{ value: max ? ((value / max) || 0) : 1, label }} />
      <div className="actions-wrapper">
        {
          actions.filter(_ => !!_).map((action, index) => action === 'separator' ? <Separator key={`separator-${index}`} /> : <Action { ...action } key={action.id} />)
        }
      </div>
    </div>
  )
}

const Action = ({ icon, label, action, disabled, id, color = '' }) => {
  return (
    <div className={`action-wrapper ${disabled ? 'disabled' : ''} ${color}`} onClick={action}>
      <Icon icon={icon} />
      { label && <span className="label">{ label }</span> }
    </div>
  )
}
const Separator = () => {
  return (
    <div className='separator'>
    </div>
  )
}

export default ProgressControls;
