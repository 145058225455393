import React from 'react';

import { LoaderWrapper } from '../layout';

const Potree = window.Potree;
const THREE = window.THREE;
const TWEEN = window.TWEEN;

THREE.Color.prototype.lerpColors = function( color1, color2, alpha ) {
  this.r = color1.r + ( color2.r - color1.r ) * alpha;
  this.g = color1.g + ( color2.g - color1.g ) * alpha;
  this.b = color1.b + ( color2.b - color1.b ) * alpha;

  return this;
}

class PanoramicView extends React.Component {
  constructor(props) {
    super(props);

    this.layers = {
      panorama: new THREE.Group(),
      vector: new THREE.Group(),
      connections: new THREE.Group(),
      // model: new THREE.Group(),
      // pointcloud: new THREE.Group(),
      // panoramaPoints: new THREE.Group(), 
    }

    this.state = { isLoading: false };

    window._panoramic = this;

    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.updateAspectRatio();
      }
    });
  }

  setLoading = (isLoading) => {
    this.setState({ isLoading });
    if (this.props.setLoading) this.props.setLoading(isLoading);
  }
  handleTreeClick = tree => this.props.onTreeClick ? this.props.onTreeClick(tree) : void 0;
  
  componentDidMount() {
    this.setLoading(true);

    this.resizeObserver.observe(this.containerEl);

    // Potree viewer setup
    this.viewer = new Potree.Viewer(this.containerEl, { useDefaultRenderLoop: false });
    this.viewer.useHQ = true;
    this.viewer.setEDLEnabled(true);
    this.viewer.setFOV(60);
    this.viewer.setPointBudget(1);
    this.viewer.setBackground(null);
    this.viewer.renderer.domElement.style.outline = 'none';

    this.renderer = this.viewer.renderer;
    this.scene = this.viewer.scene.scene;
    Object.values(this.layers).forEach((layer) => this.scene.add(layer));

    this.axesHelper = new THREE.AxesHelper(5);
    this.axesHelper.visible = false;
    this.scene.add(this.axesHelper);

    this.viewer.setControls(this.viewer.fpControls);
    this.viewer.fpControls.rotationSpeed = -50;
    this.viewer.fpControls.keys = {
			FORWARD: [],
			BACKWARD: [],
			LEFT: [],
			RIGHT: [],
			UP: [],
			DOWN: [4]
		};
    this.view = this.viewer.scene.view;
    this.viewPosition = this.viewer.scene.view.position;

    this.camera = this.viewer.scene.cameraP;
    this.camera.far = 100;
    this.camera.updateProjectionMatrix();

    this.pointsVisible = false;

    // Background scene
    this.bgScene = new THREE.Scene();
    this.bgScene.background = new THREE.Color(this.props.background);

    this.bgRenderer = new THREE.WebGLRenderer({ alpha: true, canvas: this.bgCanvasEl });
    this.bgRenderer.setPixelRatio(window.devicePixelRatio);
    this.updateAspectRatio();

    this.bgCamera = new THREE.PerspectiveCamera(
      60,
      this.aspectRatio,
      0.1,
      1000,
    );
    this.bgCamera.updateProjectionMatrix();

    // Misc
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this._mouse = {};
    window.addEventListener('resize', this.handleResize);
    // const colladaLoader = new ColladaLoader();
    // colladaLoader.setCrossOrigin("anonymous");
    // colladaLoader.setPath(`${location.origin}/res/model`);
    this.textureLoader = new THREE.TextureLoader();
    this.textureLoader.setCrossOrigin('anonymous');

    // Render
    requestAnimationFrame(this.repaint);

    this.updateFromProps({});

    global.panoramic = this;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);

    this.resizeObserver.disconnect();

    if (this.panorama) {
      this.panorama.children.forEach(obj => {
        obj.material.map.dispose();
        obj.material.dispose();
      });
    }
    if (this.points) {
      this.points.geometry.dispose();
      this.points.material.dispose();
      this.scene.remove(this.points);
    }
  }

  updateAspectRatio = () => {
    if (!this.containerEl) return;

    this.aspectRatio = this.containerEl.clientWidth / this.containerEl.clientHeight;
    if (this.renderer) this.renderer.setSize(this.containerEl.clientWidth, this.containerEl.clientHeight)
    if (this.bgRenderer) this.bgRenderer.setSize(this.containerEl.clientWidth, this.containerEl.clientHeight)
    if (this.bgCamera) {
      this.bgCamera.aspect = this.aspectRatio;
      this.bgCamera.updateProjectionMatrix();

      this.camera.aspect = this.aspectRatio;
      this.camera.updateProjectionMatrix();
    }
  }

  handleResize = () => {
    this.updateAspectRatio();
    requestAnimationFrame(this.repaint);
  }

  handleWheel = (event) => {
    const fov = THREE.Math.clamp(
      this.bgCamera.fov + event.deltaY * 0.05,
      10,
      120
    );

    this.bgCamera.fov = fov;
    this.bgCamera.updateProjectionMatrix();
    this.viewer.setFOV(fov);

    requestAnimationFrame(this.repaint);
  }

  renderInterval({ duration, interval }) {
    const id = setInterval(() => requestAnimationFrame(this.repaint), interval);
    setTimeout(() => clearInterval(id), duration);
  }

  handleKeyPress = (e) => {
    if (!this.pointcloud) return;

    const i = Number(e.key);
    if (i === 1) this.pointcloud.material.activeAttributeName = 'rgba';
    else if (i === 2) this.pointcloud.material.activeAttributeName = 'intensity gradient';
    else if (i === 8) this.pointcloud.material.gradient = Potree.Gradients.GRAYSCALE;
    else if (i === 9) this.pointcloud.material.gradient = rgbScale;
    else if (e.key === 'p') {
      this.pointsVisible = !this.pointsVisible;
      if (this.sphere) this.sphere.visible = !this.pointsVisible;
      if (this.points) this.points.visible = this.pointsVisible;
      if (this.viewer) this.viewer.setPointBudget(this.pointsVisible ? 6_000_000 : 1);
    }

    this.renderInterval({ duration: 2000, interval: 100 });
  }

  handleMouseMove = (e) => {
    const rect = this.containerEl.getBoundingClientRect();
    const w = this.containerEl.clientWidth;
    const h = this.containerEl.clientHeight;
    this.mouse.x = ((e.clientX - rect.left) / w) * 2 - 1;
    this.mouse.y = -((e.clientY - rect.top) / h) * 2 + 1;

    requestAnimationFrame(this.repaint);
  }

  handleMouseDown = (e) => {
    this._mouse.x = e.clientX;
    this._mouse.y = e.clientY;
  }

  handleMouseUp = (e) => {
    if (Math.abs(e.clientX - this._mouse.x) > 10 || Math.abs(e.clientY - this._mouse.y) > 10) return;

    const intersects = this.getIntersectedObjects();
    // const panoramaPoint = this.getIntersectedPanoramaPoint(intersects);
    const tree = this.getIntersectedTree(intersects);
    if (tree) this.handleTreeClick(tree);
    // if (panoramaPoint) this.displayTLSPanorama(panoramaPoint);
  }

  getIntersectedObjects = () => {
    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(
      this.layers.vector.children,
      true
    );

    return intersects;
  }

  getIntersectedTree = (intersects) => {
    if (!this.trees) return;

    // Select objects
    if (!intersects) intersects = this.getIntersectedObjects();

    const treeIntersect = intersects.find(_ => _.object.name.includes('tree'));
    if (treeIntersect) return this.trees.find((t) => t.id === treeIntersect.object.name.split('-').pop());

    return null;
  };

  displayPanorama = (images) => {
    let distance = 500;
    this.newPanorama = new THREE.Group();
    let panoramaLoadingId = Math.random();
    this.panoramaLoadingId = panoramaLoadingId;
    this.setLoading(true);
    return Promise.all(
      images.map((image) => {
        const vecs = transform(image, distance);

        const geom = new THREE.Geometry();
        geom.vertices.push(vecs[0]);
        geom.vertices.push(vecs[1]);
        geom.vertices.push(vecs[2]);
        geom.vertices.push(vecs[3]);

        geom.faces.push(new THREE.Face3(3, 1, 0));
        geom.faces.push(new THREE.Face3(3, 0, 2));
        geom.faceVertexUvs[0].push([
          new THREE.Vector2(1, 0),
          new THREE.Vector2(1, 1),
          new THREE.Vector2(0, 1),
        ]);
        geom.faceVertexUvs[0].push([
          new THREE.Vector2(1, 0),
          new THREE.Vector2(0, 1),
          new THREE.Vector2(0, 0),
        ]);
        geom.uvsNeedUpdate = true;

        return new Promise((resolve, reject) => {
          THREE.ImageUtils.crossOrigin = '';
          const texture = this.textureLoader.load(image.url, resolve);
          const material = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.DoubleSide,
            opacity: 1,
            transparent: true,
          });

          const mesh = new THREE.Mesh(geom, material);

          if (this.panoramaLoadingId !== panoramaLoadingId) return;

          this.newPanorama.add(mesh);
        });
      })
    ).then(() => {
      if (this.panoramaLoadingId !== panoramaLoadingId) return;

      this.bgScene.add(this.newPanorama);

      this.viewPosition.set(...this.currentPoint.position.coordinates);
      if (this.panorama) {
        this.panorama.children.forEach(obj => {
          obj.material.map.dispose();
          obj.material.dispose();
        });
        this.bgScene.remove(this.panorama);
      }

      this.setLoading(false);
      requestAnimationFrame(this.repaint);

      this.panorama = this.newPanorama;
      this.newPanorama = null;
    });
  };

  getTreeColor(treeFeature, vector) {
    if (!treeFeature.properties) treeFeature.properties = {};

    if (treeFeature.status === 'deleted' && vector) {
      vector.visible = false;
      return new THREE.Color(0xffffff);
    } else if (vector) {
      vector.visible = true;
    }

    if (this.props.selTree && treeFeature.id === this.props.selTree.id) return new THREE.Color(this.props.settings.TREE.COLORS.CIRCLE.active);

    if (!treeFeature.geometry) console.error('No tree coordinates were given', treeFeature);

    // Maven trees
    if (treeFeature.geometry.coordinates.length === 2) {
      if (treeFeature.sent_to_field) return new THREE.Color(this.props.getConfig(`colors.${this.props.getConfig('statuses.sent_to_field')?.color}`));
      else return new THREE.Color(this.props.getConfig(`colors.${this.props.getConfig('statuses.maven')?.color}`));
    }
    if (!this.props.getConfig(`statuses.${treeFeature.status}`)) console.error('UNHANDLED TREE STATUS: ', treeFeature.status);

    return new THREE.Color(this.props.getConfig(`colors.${this.props.getConfig(`statuses.${treeFeature.status}`)?.color}`));
  }

  colorTree = (tree, color) => {
    if (!color) color = this.getTreeColor(tree);

    if (tree.status === 'deleted') tree.vector.visible = false;

    tree.vector.material.color = color;
    tree.vector.material.needsUpdate = true;
  };

  repaint = (timestamp) => {
    TWEEN.update();

    // Tree hover
    const intersects = this.getIntersectedObjects();
    // const panoramaPoint = this.getIntersectedPanoramaPoint(intersects);
    const tree = this.getIntersectedTree(intersects);

    if (this.mouse.x !== 0 || this.mouse.y !== 0) {
      if (tree) {
        this.setState({ cursor: 'pointer' });

        if (!this.currentTree || tree.id !== this.currentTree.id) {
          if (this.currentHoveredTree && this.currentHoveredTree.id !== tree.id) {
            this.colorTree(this.currentHoveredTree);
          }
          this.currentHoveredTree = tree;

          this.colorTree(tree, this.props.settings.TREE.COLORS.CIRCLE.hover);

          // this.config.handleHover(tree.id);
        }
      } else if (this.currentHoveredTree) {
        this.colorTree(this.currentHoveredTree);
        this.currentHoveredTree = null;
      } else {
        // this.config.handleHover(null);
      }

      if (!tree) this.setState({ cursor: 'grab' });
    }

    this.viewer.update(this.viewer.clock.getDelta(), timestamp);
    this.viewer.render();

    // Copy camera position to bg
    this.bgCamera.rotation.copy(this.camera.rotation);
    this.bgRenderer.render(this.bgScene, this.bgCamera);

    // requestAnimationFrame(this.repaint);
  }

  generateConnections = (connections) => {
    this.layers.connections.children.forEach(e => this.layers.vector.remove(e));

    connections.forEach((connection) => {
      const maven = this.trees.find(tree => tree.id === connection.properties.maven);
      const rtms = this.trees.find(tree => tree.id === connection.properties.rtms);

      const lineGeom = new THREE.BufferGeometry().setFromPoints([maven.vector.position.clone(), rtms.vector.position.clone()]);
      const material = new THREE.LineBasicMaterial({
        color: connection.id === this.props.activeConnection ? 0x00FFFF : (new THREE.Color()).lerpColors(new THREE.Color(0xff2288), new THREE.Color(0x77ffbb), (connection.properties.probability - 0.1) / 0.6),
        side: THREE.DoubleSide,
      });

      const line = new THREE.Line(lineGeom, material);
      this.layers.connections.add(line);
    });


    requestAnimationFrame(this.repaint);
  }

  setTrees = (trees) => {
    this.trees = JSON.parse(JSON.stringify(trees));

    // const geometry = new THREE.SphereGeometry(0.3, 128, 128);
    // const material = new THREE.MeshBasicMaterial({ color: 0x00ffff });
    // const sphere = new THREE.Mesh(geometry, material);
    // sphere.position.set(trees[2].geometry.coordinates[0], trees[2].geometry.coordinates[1], trees[2].geometry.coordinates[2]);
    // this.sphere = sphere;
    // this.sphere.visible = false;
    this.layers.vector.children.forEach(e => this.layers.vector.remove(e));
    // this.state.layers.vector.add(sphere);

    const ringGeom = new THREE.RingGeometry(
      this.props.settings.TREE.SIZES.CIRCLE.OUTER_RADIUS,
      this.props.settings.TREE.SIZES.CIRCLE.INNER_RADIUS,
      64
    );
    const lineGeom = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 5)]);

    const threeDTreeCoords = trees.filter(t => t.geometry.coordinates.length === 3).map(t => new THREE.Vector3(...t.geometry.coordinates));
    // const fallbackZ = threeDTrees.reduce((sum, tree) => sum + tree.geometry.coordinates[2], 0) / threeDTrees.length;
    // const fallbackZ = median(threeDTrees.map(t => t.geometry.coordinates[2]));

    this.trees.forEach((treeObject) => {
      const { geometry } = treeObject;
      // treeObject.properties.lasPath = getLASUrl(treeObject);
      let fallbackZ;

      if (geometry.coordinates.length === 2) {
        const pos = new THREE.Vector2(...geometry.coordinates);
        fallbackZ = threeDTreeCoords.reduce(({ closestDistance, z }, pos2) => {
          // It is more efficient to just calculate the distance squared and we don't need actual distance to calculate which point is the closest one
          const distance = pos.distanceToSquared(new THREE.Vector2(pos2.x, pos2.y));

          if (!closestDistance) return { closestDistance: distance, z: pos2.z };
          else if (distance < closestDistance) return { closestDistance: distance, z: pos2.z };
          else return { closestDistance, z };
        }).z;
      }

      // Vector
      const material = new THREE.MeshBasicMaterial({
        color: 0x000000,
        side: THREE.DoubleSide,
      });

      let object;
      // if (geometry.coordinates.length === 2) object = new THREE.Line(lineGeom, material);
      // else object = new THREE.Mesh(ringGeom, material);
      object = new THREE.Mesh(ringGeom, material);

      object.material.color = this.getTreeColor(treeObject, object);

      object.material.needsUpdate = true;
      if (geometry.coordinates.length === 2) object.position.set(...geometry.coordinates, fallbackZ);
      else object.position.set(...geometry.coordinates);
      object.name = `tree-${treeObject.id}`;

      this.layers.vector.add(object);
      treeObject.vector = object;
    });
  };

  updateViewPoint = () => {
    const { capturePoint, selTree, images, cameras } = this.props;

    const processedImages = images.map((i) => {
      const cam = cameras.find((c) => c.camera_idx === i.camera_idx);
      return {
        ...i,
        originVec: i.origin.coordinates,
        directionVec: i.direction.coordinates,
        upVec: i.up.coordinates,
        width: cam.Nx,
        height: cam.Ny,
        cameraMatrix: [
          [cam.fx, 0, cam.Cx],
          [0, cam.fy, cam.Cy],
          [0, 0, 1],
        ],
        url: (window._env_.REACT_APP_PANORAMA || process.env.REACT_APP_PANORAMA).replace('__PATH__', i.path),
      };
    });

    this.currentPoint = capturePoint;
    this.panoramaP = this.displayPanorama(processedImages);
    if (selTree) this.viewTree(selTree.id, this.panoramaP);
  }

  viewTree = (treeId, promise) => {
    if (!this.trees) return setTimeout(() => this.viewTree(treeId, promise), 100);

    let tree = this.trees.find((t) => t.id === treeId);

    if (!tree) throw new Error(`Tree '${treeId}' not found. `);

    this.currentTree = tree;
    this.colorTree(tree, this.props.settings.TREE.COLORS.CIRCLE.active);

    this.axesHelper.position.copy(this.currentTree.vector.position);

    if (promise) {
      promise.then(() => {
        this.view.lookAt(this.currentTree.vector.position);
        requestAnimationFrame(this.repaint);
      });
    } else {
      this.view.lookAt(this.currentTree.vector.position);

      requestAnimationFrame(this.repaint);
    }
  };

  updateFromProps(prevProps) {
    this.axesHelper.visible = !!this.props.isNew;

    if (prevProps.background !== this.props.background) {
      this.bgScene.background = new THREE.Color(this.props.background || 0x000000);
      requestAnimationFrame(this.repaint);
    }
    if ((!prevProps.trees && this.props.trees) || (prevProps.trees && this.props.trees && prevProps.trees.length !== this.props.trees.length)) this.setTrees(this.props.trees);
    else if (prevProps.trees && this.props.trees && prevProps.trees.length === this.props.trees.length) {
      this.trees.forEach((tree) => {
        const appTree = this.props.trees.find(t => t.id === tree.id);
        tree.vector.material.color = this.getTreeColor(appTree, tree.vector);
        tree.vector.material.needsUpdate = true;
      });
    }
    if (prevProps.connections !== this.props.connections) this.generateConnections(this.props.connections);
    if (prevProps.activeConnections !== this.props.activeConnections) this.generateConnections(this.props.connections);
    if (JSON.stringify(prevProps.capturePoint) !== JSON.stringify(this.props.capturePoint)) this.updateViewPoint();
    if (prevProps.selTree !== this.props.selTree) {
      if (prevProps.selTree && this.props.selTree && prevProps.selTree.id !== this.props.selTree.id) {
        const oldSelTree = this.trees.find(tree => tree.id === prevProps.selTree.id);
        this.colorTree(oldSelTree);
        if (JSON.stringify(prevProps.capturePoint) === JSON.stringify(this.props.capturePoint)) this.viewTree(this.props.selTree.id);
      } else if (prevProps.selTree && this.props.selTree && prevProps.selTree.id === this.props.selTree.id) {
        const tree = this.trees.find(tree => tree.id === prevProps.selTree.id);
        tree.vector.position.set(...this.props.selTree.geometry.coordinates);
        this.axesHelper.position.copy(tree.vector.position);
      } else if (this.props.selTree) {
        if (JSON.stringify(prevProps.capturePoint) === JSON.stringify(this.props.capturePoint)) this.viewTree(this.props.selTree.id);
      }
      requestAnimationFrame(this.repaint);
    }
    if (prevProps.pointcloud !== this.props.pointcloud) {
      if (this.points) {
        this.points.geometry.dispose();
        this.points.material.dispose();
        this.scene.remove(this.points);
      }

      if (this.props.pointcloud) {
        this.pointcloud = this.props.pointcloud;
        const points = new THREE.Points(this.props.pointcloud.geometry, this.props.pointcloud.createMaterial(THREE, 0.005));
        points.position.set(this.props.pointcloud.pc.mins[0], this.props.pointcloud.pc.mins[1], this.props.pointcloud.pc.mins[2]);
        this.points = points; 
        this.points.up.set(0, 0, 1);

        this.points.visible = this.pointsVisible;

        this.scene.add(this.points);
      }
      requestAnimationFrame(this.repaint);
    }
  }

  componentDidUpdate(prevProps) {
    this.updateFromProps(prevProps);
  }

  render() {
    return (
      <LoaderWrapper loading={(this.state.isLoading || !this.props.images?.length) && !this.props.blockLoader}>
        <div 
          className='panoramicview-container' 
          ref={e => this.containerEl = e}
          onKeyPress={this.handleKeyPress}
          onMouseMove={this.handleMouseMove}
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onWheel={this.handleWheel}
          style={{ cursor: this.state.cursor || 'initial', position: 'relative', width: '100%', height: '100%', outline: 'none' }}
        >
          <canvas style={{ position: 'absolute', outline: 'none' }} ref={e => this.bgCanvasEl = e} />
        </div>
      </LoaderWrapper>
    )
  }
}

PanoramicView.defaultProps = {
  background: [0.5, 0.5, 0.5],
  settings: {
    ANIMATION_DURATION: 300,
    TREE: {
      SIZES: {
        CIRCLE: {
          INNER_RADIUS: 0.15,
          OUTER_RADIUS: 0.3,
        },
      },
      COLORS: {
        CIRCLE: {
          hover: new THREE.Color(0xffffff),
          active: new THREE.Color(0x00FFFF),
        },
      },
    },
    POINTS: {
      SIZE: 0.01,
    },
  }
}

/*

  Props:

  onTreeClick(Tree)
  setLoading(bool)

  background: [float, float, float]

  settings:
    {
      ANIMATION_DURATION: 300,
      TREE: {
        SIZES: {
          CIRCLE: {
            INNER_RADIUS: 0.15,
            OUTER_RADIUS: 0.3,
          },
        },
        COLORS: {
          CIRCLE: {
            hover: new THREE.Color(0xffffff),
            active: new THREE.Color(0x00FFFF),
          },
        },
      },
      POINTS: {
        SIZE: 0.01,
      },
    }

*/

// 3x3 X 1x3
const multiplyMatrix = (t1, t2) => [
  t1[0] * t2[0] + t1[3] * t2[1] + t1[6] * t2[2],
  t1[1] * t2[0] + t1[4] * t2[1] + t1[7] * t2[2],
  t1[2] * t2[0] + t1[5] * t2[1] + t1[8] * t2[2],
];

const rgbScale = [
  [0, new THREE.Color(0, 0, 1)],
  [0.25, new THREE.Color(0, 1, 0)],
  [0.5, new THREE.Color(1, 1, 0)],
  [0.75, new THREE.Color(1, 0, 0)],
  [1, new THREE.Color(1, 1, 1)],
];

const transform = (
    { cameraMatrix, originVec, directionVec, upVec, width, height },
    distance
) => {
  {
    const _cameraMatrix = cameraMatrix;
    cameraMatrix = new THREE.Matrix3();
    cameraMatrix.set(
        ..._cameraMatrix[0],
        ..._cameraMatrix[1],
        ..._cameraMatrix[2]
    );
  }
  originVec = new THREE.Vector3(...originVec);
  directionVec = new THREE.Vector3(...directionVec);
  upVec = new THREE.Vector3(...upVec);

  const r2 = upVec.clone().negate();
  const r1 = directionVec.cross(upVec);
  const r3 = r1.clone().cross(r2);
  const matrix = new THREE.Matrix3();
  matrix.set(r1.x, r2.x, r3.x, r1.y, r2.y, r3.y, r1.z, r2.z, r3.z);
  matrix.transpose();

  const vecs = [];
  for (let y = 0; y < height; y += height - 1) {
    for (let x = 0; x < width; x += width - 1) {
      const t1 = new THREE.Matrix3().getInverse(cameraMatrix).toArray();
      const t2 = [x, y, 1.0];
      let p = multiplyMatrix(t1, t2);
      p = p.map((n) => n * (distance / p[2]));
      p = multiplyMatrix(matrix.clone().transpose().toArray(), p);
      vecs.push(new THREE.Vector3(...p));
    }
  }
  return vecs;
};

const median = (values) => {
  if(values.length ===0) return 0;

  values.sort(function(a,b){
    return a-b;
  });

  const half = Math.floor(values.length / 2);

  if (values.length % 2)
    return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

export default PanoramicView;