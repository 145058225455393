import React from 'react';
import Info from './Info';

const SemanticInfoInfo = ({ content, ...props }) => {
  return (
    <div>
       <Info content={content} />
    </div>
  )
}

export default SemanticInfoInfo;