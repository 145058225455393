import React from 'react';

const Toggle = ({ label, value, onChange = () => {}, onPureChange = () => {}, name, disabled }) => {
  const _handleChange = () => {
    onPureChange(!value);
    onChange({ name, value: !value })
  }

  return (
    <div className={`toggle-input-wrapper regular-button-states ${disabled ? 'disabled' : ''} ${value ? 'active' : 'inactive'}`} onClick={_handleChange}>
      <span className="label">{label}</span>
      <div className="toggle-wrapper">
        <div className="knob"></div>
      </div>
    </div>
  )
}

export default Toggle;
