import React, { useState, useEffect, memo } from 'react';
import { Map, MapControls, Controls, Table, Info, PanoramicView } from '../../components';
import { withRouter } from 'react-router';
import { Grid } from '../../layout';
import { useData } from '../../providers/data';
import { useTheme } from '../../providers/theme';
import { useConfig } from '../../providers/config';
import { useAlert } from '../../providers/alert';
import TreePopup from "../../components/TreePopup";



const generateTableConfig = (selection, search = '') => ({
  filter: (data) => selection ? (data.status !== 'deleted') : !data.current_manual_step && (data.managed_area.toLowerCase().includes(search.toLowerCase())),
  columns: selection
    ? [
      {
        title: 'ID',
        key: 'id',
        resolver: 'id',
        sortable: true
      },
      {
        title: 'Managed area',
        key: 'MA',
        resolver: 'managed_area',
        format: 'CODE',
        sortable: true
      },
      {
        title: 'Status',
        resolver: 'status',
        key: 'status',
        format: 'STATUS',
        sortable: true
      },
      {
        title: 'Comment',
        key: 'comment',
        resolver: ({comment}) => comment || '-',
        sortable: true
      },
    ] : [
      {
        title: 'Location name',
        key: 'location',
        resolver: 'description',
        sortable: true
      },
      {
        title: 'Area code',
        key: 'code',
        resolver: 'code',
        format: 'CODE',
        sortable: true
      },
      {
        title: 'Status',
        key: 'status',
        // resolver: (ma) => {​​​​​​​
        //   if (ma.location_proposal === 'error' || ma.semantic_extraction === 'error') return 'ERROR';
        //   if (ma.location_proposal === 'todo' || ma.semantic_extraction === 'todo') return 'PROCESSING';
        //   if (ma.location_proposal === 'started' || ma.semantic_extraction === 'started') return 'PROCESSING';
        //   else return ma.current_manual_step;
        // },​​​​​​​
        resolver: ma => {
          if (ma.location_proposal === 'error' || ma.semantic_extraction === 'error') return 'ERROR';
          if (ma.location_proposal === 'todo' || ma.semantic_extraction === 'todo' || ma.location_proposal === 'started' || ma.semantic_extraction === 'started' || ma.processing) return 'PROCESSING';
          return ma.current_manual_step || 'completed';
        },
        format: 'STATUS',
        sortable: true
      },
    ]
});

const MAP_SOURCES = [
  { id: 'trees', source: 'trees', name: 'Trees' },
  { id: 'mas', source: 'managed_areas', name: 'Managed Areas' },
];

const ValidationMap = ({ location, history, managedArea = {}, pipelines, trees, mapRef, match }) => {
  const { presentAlert, dismissAlert } = useAlert();
  const [maSearch, setMASearch] = useState('');
  const [selectedTree, setSelectedTree] = useState(null);
  const { isDark } = useTheme();

  // // Panoramic view config
  // const [isPanoramaVisible, setPanoramaVisible] = useState(false);
  // const [panoramaImages, setPanoramaImages] = useState([]);
  // const [capturePoint, setCapturePoint] = useState({ position: { coordinates: [0, 0, 0] } });


  // const _handlePanorama = async () => {
  //   const panoramaImages = await getCapturePoint(selectedTree);
  //   setPanoramaImages(panoramaImages);
  //   setCapturePoint({ position: { coordinates: panoramaImages?.[0]?.origin.coordinates } });
  // }

  // useEffect(() => {
  //   if (selectedTree?.id) _handlePanorama();
  // }, [selectedTree?.id])

  const _handleTreeSelect = (centerCoord, tree, layerId) => {
    tree.location = { coordinates: [centerCoord.lng, centerCoord.lat] };

    setSelectedTree(tree);
  }
  const _handleMASelect = (centerCoord, layer, layerId) => {
    history.push(`/cma/${layer.id}`);
    setSelectedTree(null);
  }

  // useEffect(() => {
  //   if (!match.params.MA && maSearch) setMASearch('');
  // }, [match.params.MA]);

  const default_layers = [
    {
      id: 'mas',
      source: 'mas',
      type: 'fill',
      color: '#082',
      onClick: _handleMASelect,
      opacity: 0.32,
      below: 'completed'
    },
  ];

  const popup = ({ feature, ...props }) => {
    requestAnimationFrame(() => {
      const _handleDetails = () => history.push(`/cma/tree/${feature.id}`);

      const button = document.querySelector('#tree-details-handler');
      button.removeEventListener('click', _handleDetails);
      button.addEventListener('click', _handleDetails);
    })
    return <TreePopup tree={feature} {...props} isDetailsVisible />
  }

  const generateFilter = (status) => [
    'case',
    ['in', ['get', 'id'], ['literal', [].map(tree => parseInt(tree))]],
    false,
    status === 'maven' || ["==", ["get", "status"], status]
  ];

  const filter_layers = [
    // {
    //   id: 'maven',
    //   source: 'maven',
    //   type:'circle',
    //   onClick: _handleTreeSelect,
    //   popup,
    // },
    {
      id: 'completed',
      source: 'trees',
      type:'circle',
      filter: ["==", ["get", "status"], 'completed'],
      onClick: _handleTreeSelect,
      popup,
    }
  ];

  // const selectedMA = selection[0]?.id || selection[1];
  // const managedArea = selection[0];
  // const { pipelines } = useData();

  const { getConfig } = useConfig();
  const [isSatellite, setIsSatellite] = useState(false);
  const [sourceVisible, setSourceVisible] = useState({
    mas: true,
    las: false,
    trees: true,
    maven: true,
  });

  const filterLayers = filter_layers.map(layer => {
    const config = getConfig(`statuses.${layer.id}`);
    const filter = generateFilter(layer.id);
    return { ...layer, filter, label: config?.label || 'Unnamed', color: getConfig(`colors.${config?.color}`) || '#08f' };
  });

  const layers = [...filterLayers, ...default_layers];
  const [layerVisible, setLayerVisible] = useState(layers.reduce((prev, curr) => ({ ...prev, [curr['id']]: true }), {}));


  const infoContent = [
    // {
    //   title: 'Selected managed area',
    //   key: 'MA',
    //   data: [
    //     {
    //       label: 'Code',
    //       value: selection[0]?.code || ' - ',
    //       id: selection[0]?.code,
    //     },
    //     {
    //       label: 'Name',
    //       value: selection[0]?.description || ' - ',
    //       id: selectedMA,
    //     },
    //     {
    //       label: 'Type',
    //       value: '–',
    //       id: 'sublocation',
    //     },
    //   ],
    // },
    // selectedTree && {
    //   title: 'Selected tree',
    //   key: 'tree',
    //   data: [
    //     {
    //       label: 'ID',
    //       value: selectedTree?.id,
    //       id: selectedTree?.id,
    //     },
    //     {
    //       label: 'Status',
    //       value: selectedTree?.properties?.status || 'MAVEN II',
    //       id: selectedTree?.properties?.status,
    //     },
    //     {
    //       label: 'Height',
    //       value: selectedTree?.properties?.height + ' m',
    //       id: 'height',
    //     },
    //     {
    //       label: 'Girth',
    //       value: selectedTree?.properties?.girth_1_0m || selectedTree?.properties?.grth_size + ' m',
    //       id: 'girth',
    //     },
    //   ],
    // },
  ].filter(_ => !!_);

  const controls = [
    {
      id: 'map-controller',
      label: 'Basic',
      component: <MapControls
        layers={filterLayers}
        layersVisible={layerVisible}
        onLayerVisibleChange={setLayerVisible}
        showLayerFilters
        sources={MAP_SOURCES}
        sourcesVisible={sourceVisible}
        onSourceVisibleChange={setSourceVisible}
        showSourceFilters
        showViewOptions
        isSatellite={isSatellite}
        setIsSatellite={setIsSatellite}
        maSearch={maSearch}
        setMASearch={setMASearch}
      />
    },
    {
      id: 'info',
      label: 'Info',
      component: <Info content={infoContent}/>
    },
  ];

  useEffect(() => {
    window._openTreeDetails = (tree) => alert(tree);
  }, [])

  const _handleEntryClick = (entry, index) => !match.params.MA && history.push(`/cma/${entry.id}`);

  return (
    <Grid
      defaultLayout={{ x: .0, y: .0 }}
      tabcontent={<Controls tabs={controls}/>}
      infocontent={
        <Table
          key='MAP'
          data={(match.params.MA ? trees : pipelines) || []}
          config={generateTableConfig(match.params.MA, maSearch)}
          onClick={_handleEntryClick}
          preventAutoFocus
        />
      }
    >
      <div className="validation-overview-wrapper">
        {/* {
          (isPanoramaVisible && selectedTree) && (
            <div className="panorama-map-container">
              <PanoramicView 
                background={isDark ? 0x000000 : 0xf8f8f8}
                capturePoint={capturePoint}
                images={panoramaImages || []}
                cameras={panoramaImages || []}
                onTreeClick={_handleTreeSelect}
                getConfig={getConfig}
              />
            </div>
          )
        } */}
        <Map
          key='validationMap'
          _ref={mapRef}
          sources={MAP_SOURCES}
          sourceVisible={sourceVisible}
          layers={layers}
          layerVisible={{ 
            mas: true,
            maven: layerVisible.maven && sourceVisible.trees,
            completed: layerVisible.completed && sourceVisible.trees,
          }}
          active={{
            mas: match.params.MA,
            ...filterLayers.reduce((prev, { id }) => ({ ...prev, [id]: selectedTree?.id }), {})
          }}
          satellite={isSatellite}
          // onFocusReset={resetFocus}
        />
      </div>
    </Grid>
  )
}

export default memo(withRouter(ValidationMap));