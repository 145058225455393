import React, { useState, useEffect, useRef } from 'react';
import { PanoramicView, ProgressControls, TreeMover, Finished } from '../../components';
import { Button } from '../../components/inputs';
import * as THREE from 'three';
import proj4 from 'proj4';
import { LoaderWrapper } from '../../layout';
import { validationActions } from '../../core/progressActions';
import { useLocation, useHistory } from 'react-router-dom';
import { useModal } from '../../providers/modal';
import { useTheme } from '../../providers/theme';
import { useConfig } from '../../providers/config';
import LASLoader from '../../utils/LASLoader';
import { useActionStack } from '../../core';

// Creating las loader
const loadingManager = new THREE.LoadingManager();
const loader = new LASLoader(loadingManager);

// Generate url
const generateUrl = (id) => window.location.pathname.split('/').slice(0, 4).join('/') + '/' + id;

const LocationValidation = ({ selection = [], match, trees, updateTree, getCapturePoint, loaded, startPostValidation = () => {}, setCurrentTreeId }) => {
  const { getConfig } = useConfig();
  const location = useLocation();
  const history = useHistory();

  const isNew = location.search?.includes('new');

  const init = useRef(false);
  const [finished, setFinished] = useState(false);

  // Position of the tree
  const [position, setPosition] = useState([]);
  const prevPosition = useRef([]);
  const positionTimeout = useRef(null);

  // index of the current tree
  const [currentIndex, setCurrentIndex] = useState(0);

  // Las data for pointcloud
  const [las, setLas] = useState(null);
  const [lasError, setLasError] = useState(null);

  const [panoramicLoading, setPanoramicLoading] = useState(false);

  // Panoramic view config
  const [panoramaImages, setPanoramaImages] = useState([]);
  const [capturePoint, setCapturePoint] = useState({ position: { coordinates: [0, 0, 0] } });

  // Using theming
  const { isDark } = useTheme();

  // Getting modal methods
  const { presentModal, dismissModal } = useModal();
  
  // Getting selected tree
  const selectedTree = match.params.tree;
  
  // Filtering tree array to unvalidated trees
  const todos = trees.filter(tree => tree.status === 'location_validation_todo').sort((a, b) => parseFloat(b.location_confidence || 0) - parseFloat(a.location_confidence || 0)).map((tree, index ) => ({ ...tree, index }));
  const treeList = trees.map(tree => ({ ...tree, geometry: { ...tree.geometry, coordinates: proj4("EPSG:4326", "EPSG:3414", tree?.location?.coordinates?.slice(0) || [])}}));

  // Getting currentTree based on the offset index
  const currentTree = isNew ? todos.find(tree => tree.id === match.params.tree) : todos[currentIndex % todos.length];

  const _handlePosition = (pos, preventActionstack) => {
    if (preventActionstack) prevPosition.current = pos
    else positionTimeout.current = setTimeout(() => _handlePositionAction(pos), 240);
    setPosition(pos);
  }
  const _handlePositionAction = (pos) => {
    const prev = [...prevPosition.current];
    prevPosition.current = pos;
    if (prev.length === 0) return;

    onAction('POSITION', currentTree?.id, prev, pos);
  }

  // Loading the las file
  const _handleLasLoad = () => {
    if (isNew) return;
    setLas(null);
    setLasError(null);
    const currentId = currentTree?.id;
    const url = `${(window._env_.REACT_APP_LOCATION_LAS || process.env.REACT_APP_LOCATION_LAS).replace('__MA__', selection[0].code).replace('__TREE__', currentTree?.id)}`;
    loader.load(url, pointcloud => {
      if (currentId !== currentTree?.id) return;
      setLas(pointcloud);
      setLasError(false);
    }, () => void 0, () => {
      setLasError(true);
    });
  }

  const _handleUndo = (action, target, from, to) => {
    if (action === 'SKIP') setCurrentIndex(from);
    if (action === 'UPDATE') updateTree(target, from);
    if (action === 'POSITION') setPosition(from);
  };
  const { onAction, onUndo, isUndoAvailable } = useActionStack(_handleUndo);

  /**
   * Handling component init
   * setting offset based on url tree param, or updating url
   */
  const _handleInit = () => {
    init.current = true;
    if (!selectedTree) window.history.pushState(null, null, generateUrl(currentTree?.id));
    else {
      const index = todos.findIndex(tree => String(tree.id) === String(selectedTree));
      if (index >= 0) setCurrentIndex(index);
    }
  }

  // Loading panorama config
  const _handlePanorama = async () => {
    const panoramaImages = await getCapturePoint(currentTree);
    setPanoramaImages(panoramaImages);
    setCapturePoint({ position: { coordinates: panoramaImages?.[0]?.origin.coordinates } });
  }
 
  // Habdling state update based on selected tree and MA data
  useEffect(() => {
    if (currentTree?.id) setCurrentTreeId(currentTree?.id);
    if (!selection[0]?.code) return;
    if (currentTree?.id && !init.current) _handleInit();
    else if (currentTree?.id && init.current) window.history.pushState(null, null, generateUrl(currentTree?.id));
    if (currentTree?.id) _handleLasLoad();
    if (currentTree?.location) {
      const location = currentTree?.location?.coordinates.slice(0);
      const position = proj4("EPSG:4326", "EPSG:3414", location || []);
      _handlePosition(position, true);
    }

    // Panorama
    if (currentTree?.id) _handlePanorama();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTree?.id, selection[0]?.code])

  // Tree update methods
  const _handleUpdate = (tree) => {
    onAction('UPDATE', tree?.id || currentTree?.id, { status: 'location_validation_todo' }, { status: 'location_validation_done' });
    updateTree(tree?.id || currentTree?.id, { status: 'location_validation_done', location: position });
    if (isNew) history.push(`/validation/${selection[0]?.id}?tree=${tree?.id || currentTree?.id}`);
  }
  const _handleRemove = (tree) => {
    onAction('UPDATE', tree?.id || currentTree?.id, { status: 'location_validation_todo' }, { status: 'deleted' });
    updateTree(tree?.id || currentTree?.id, { status: 'deleted' });
    if (isNew) history.push(`/validation/${selection[0]?.id}`)
  }
  const _handleField = (tree, comment) => {
    if (!tree) return;
    onAction('UPDATE', tree?.id || currentTree?.id, { status: 'location_validation_todo', comment: null }, { status: 'sent_to_field' });
    updateTree(tree.id, { status: 'sent_to_field', comment });
  }
  const _handleSkip = (direction = 1) => () => {
    const nextIndex = (currentIndex + direction + trees.length) % trees.length;
    onAction('SKIP', null, currentIndex, nextIndex);
    setCurrentIndex(nextIndex);
  }

  const _handleTreeSelect = ({ id }) => {
    const index = todos.findIndex(tree => tree.id === id);
    if (todos[index]) setCurrentIndex(index);
  }

  const _handleTSEJob = () => {
    startPostValidation(selection[0]?.code, 'location_validation');
    setFinished(true);
  }

  return (
    <LoaderWrapper loading={!loaded}>
      <Finished finished={todos.length === 0 && loaded} onStart={_handleTSEJob} done={finished}>
        <div className="location-validation-wrapper">
          <div className="viewers">
            <div className="panorama-view-wrapper">
              <PanoramicView 
                trees={treeList}
                selTree={!!currentTree && { ...currentTree, geometry: { ...currentTree?.geometry, coordinates: position }}}
                pointcloud={isNew ? false : las}
                background={isDark ? 0x000000 : 0xf8f8f8}
                capturePoint={capturePoint}
                images={panoramaImages}
                cameras={panoramaImages}
                blockLoader={!loaded}
                onTreeClick={_handleTreeSelect}
                getConfig={getConfig}
                isNew={isNew}
                setLoading={setPanoramicLoading}
              />
            </div>
            <div className="vertical-stack">
              <TreeMover 
                treeLoaded={loaded}
                pointcloud={isNew ? false : las}
                error={lasError}
                background={isDark ? 0x000000 : 0xf8f8f8}
                position={position} 
                onPositionChange={_handlePosition}
                isNew={isNew}
              />
            </div>
          </div>
          { 
            isNew && (
              <div className="location-actions-wrapper">
                <div className="action-wrapper">
                  <Button label='Cancel' onClick={_handleRemove} /> 
                </div>
                <div className="action-wrapper">
                  <Button label='Save' primary onClick={_handleUpdate}/> 
                </div>
              </div>
            )
          }
          <ProgressControls 
            disabled={isNew}
            max={trees?.length} value={trees.length - todos.length} 
            actions={validationActions({
              onUndo,
              isUndoAvailable,
              tree: currentTree, 
              updateTree: _handleUpdate, 
              removeTree: _handleRemove, 
              sendToField: _handleField,
              onSkip: _handleSkip, 
              numberOfTrees: todos.length, 
              dismissModal, 
              presentModal,
              disabled: panoramicLoading || lasError === null,
            })}
          />
        </div>
      </Finished>
    </LoaderWrapper>
  )
}

export default LocationValidation;