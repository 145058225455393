import React, { useState, useEffect, memo } from 'react';
import {
  Map,
  MapControls,
  Controls,
  Table,
  Info,
  PanoramicView,
} from '../../components';
import { withRouter } from 'react-router';
import { Grid } from '../../layout';
import { useData } from '../../providers/data';
import { useTheme } from '../../providers/theme';
import { useConfig } from '../../providers/config';
import { useAlert } from '../../providers/alert';
import TreePopup from '../../components/TreePopup';

const generateMapConfig = (selection, handleRemove, search = '', selectedTree) => ({
  filter: (data) =>
    (selection || !search)
      ? data.status !== 'deleted'
      : data.managed_area.toLowerCase().includes(search.toLowerCase()),
  columns: selection
    ? [
        {
          title: 'ID',
          key: 'id',
          resolver: 'id',
          sortable: true,
        },
        {
          title: 'Managed area',
          key: 'MA',
          resolver: 'managed_area',
          format: 'CODE',
          sortable: true,
        },
        {
          title: 'Status',
          resolver: 'status',
          key: 'status',
          format: 'STATUS',
          sortable: true,
        },
        {
          title: 'Comment',
          key: 'comment',
          resolver: ({ comment }) => comment || '-',
          sortable: true,
        },
        // {
        //   title: '',
        //   key: 'remove',
        //   onClick: handleRemove,
        //   icon: 'trash',
        //   format: 'ICONBUTTON',
        // },
      ]
    : [
        {
          title: 'Location name',
          key: 'location',
          resolver: 'description',
          sortable: true,
        },
        {
          title: 'Area code',
          key: 'code',
          resolver: 'code',
          format: 'CODE',
          sortable: true,
        },
        {
          title: 'Status',
          key: 'status',
          // resolver: (ma) => {​​​​​​​
          //   if (ma.location_proposal === 'error' || ma.semantic_extraction === 'error') return 'ERROR';
          //   if (ma.location_proposal === 'todo' || ma.semantic_extraction === 'todo') return 'PROCESSING';
          //   if (ma.location_proposal === 'started' || ma.semantic_extraction === 'started') return 'PROCESSING';
          //   else return ma.current_manual_step;
          // },​​​​​​​
          resolver: (ma) => {
            if (
              ma.location_proposal === 'error' ||
              ma.semantic_extraction === 'error'
            )
              return 'ERROR';
            if (
              ma.location_proposal === 'todo' ||
              ma.semantic_extraction === 'todo' ||
              ma.location_proposal === 'started' ||
              ma.semantic_extraction === 'started' ||
              ma.processing
            )
              return 'PROCESSING';
            return ma.current_manual_step || 'completed';
          },
          format: 'STATUS',
          sortable: true,
        },
      ],
  selection: (entry) => {
    return selectedTree && entry && (parseInt(selectedTree.id) === parseInt(entry.id));
  }
});

const steps = {
  location_validation: 'location',
  semantic_validation: 'semantics',
  db_match: 'dbmatch',
};

const ValidationMap = ({
  location,
  match,
  history,
  mapRef,
  selection,
  trees = [],
  updateTree,
  onTreeAdd,
  resetFocus,
  focusOnMA,
  deletedTrees,
  onDelete,
  getCapturePoint,
  setCurrentTreeId,
}) => {
  const { presentAlert, dismissAlert } = useAlert();
  const [maSearch, setMASearch] = useState('');
  const [selectedTree, setSelectedTree] = useState(null);
  const { isDark } = useTheme();

  // Panoramic view config
  const [isPanoramaVisible, setPanoramaVisible] = useState(false);
  const [panoramaImages, setPanoramaImages] = useState([]);
  const [capturePoint, setCapturePoint] = useState({
    position: { coordinates: [0, 0, 0] },
  });

  const _handlePanorama = async () => {
    const panoramaImages = await getCapturePoint(selectedTree);
    setPanoramaImages(panoramaImages);
    setCapturePoint({
      position: { coordinates: panoramaImages?.[0]?.origin.coordinates },
    });
  };

  useEffect(() => {
    if (selectedTree?.id) _handlePanorama();
  }, [selectedTree?.id]);

  const sources = [
    { id: 'trees', source: 'trees', name: 'Trees' },
    { id: 'mas', source: 'managed_areas', name: 'Managed Areas' },
    // {id: 'las', source: 'gh_singapore.tiles', name: 'Scan tiles'},
    { id: 'maven', source: 'maven_trees' },
  ];

  const _handleTreeSelect = (centerCoord, tree, layerId) => {
    tree.location = { coordinates: [centerCoord.lng, centerCoord.lat] };
    setSelectedTree(tree);
  };
  const _handleMASelect = (centerCoord, layer, layerId) => {
    history.push(`/validation/${layer.id}`);
    setSelectedTree(null);
  };

  useEffect(() => {
    if (!match.params.MA && maSearch) setMASearch('');
  }, [match.params.MA]);

  const default_layers = [
    // {
    //   id: 'las',
    //   source: 'las',
    //   type: 'fill-outline',
    //   color: '#082',
    //   opacity: .02,
    // },
    {
      id: 'mas',
      source: 'mas',
      type: 'fill',
      color: '#082',
      onClick: _handleMASelect,
      opacity: 0.32,
      below: 'maven',
    },
  ];

  const popup = ({ feature, ...props }) => {
    return <TreePopup tree={feature} {...props} />;
  };

  const generateFilter = (status) => [
    'case',
    [
      'in',
      ['get', 'id'],
      ['literal', deletedTrees.map((tree) => parseInt(tree))],
    ],
    false,
    status === 'maven' || ['==', ['get', 'status'], status],
  ];

  const filter_layers = [
    {
      id: 'maven',
      source: 'maven',
      type: 'circle',
      onClick: _handleTreeSelect,
      popup,
    },
    {
      id: 'sent_to_field',
      source: 'trees',
      type: 'circle',
      filter: ['==', ['get', 'status'], 'sent_to_field'],
      onClick: _handleTreeSelect,
      popup,
    },
    {
      id: 'location_validation_todo',
      source: 'trees',
      type: 'circle',
      filter: ['==', ['get', 'status'], 'location_validation_todo'],
      onClick: _handleTreeSelect,
      popup,
    },
    {
      id: 'location_validation_done',
      source: 'trees',
      type: 'circle',
      filter: ['==', ['get', 'status'], 'location_validation_done'],
      onClick: _handleTreeSelect,
      popup,
    },
    {
      id: 'semantic_validation_todo',
      source: 'trees',
      type: 'circle',
      onClick: _handleTreeSelect,
      popup,
    },
    {
      id: 'semantic_validation_done',
      source: 'trees',
      type: 'circle',
      filter: ['==', ['get', 'status'], 'semantic_validation_done'],
      onClick: _handleTreeSelect,
      popup,
    },
    {
      id: 'db_match_todo',
      source: 'trees',
      type: 'circle',
      filter: ['==', ['get', 'status'], 'db_match_todo'],
      onClick: _handleTreeSelect,
      popup,
    },
    {
      id: 'completed',
      source: 'trees',
      type: 'circle',
      filter: ['==', ['get', 'status'], 'completed'],
      onClick: _handleTreeSelect,
      popup,
    },
  ];

  const selectedMA = selection[0]?.id || selection[1];
  const managedArea = selection[0];
  const { pipelines } = useData();
  const { getConfig } = useConfig();
  const [isSatellite, setIsSatellite] = useState(false);
  const [sourceVisible, setSourceVisible] = useState({
    mas: true,
    las: false,
    trees: true,
    maven: true,
  });

  const filterLayers = filter_layers.map((layer) => {
    const config = getConfig(`statuses.${layer.id}`);
    const filter = generateFilter(layer.id);
    return {
      ...layer,
      filter,
      label: config?.label || 'Unnamed',
      color: getConfig(`colors.${config?.color}`) || '#08f',
    };
  });

  const layers = [...filterLayers, ...default_layers];
  const [layerVisible, setLayerVisible] = useState(
    layers.reduce((prev, curr) => ({ ...prev, [curr['id']]: true }), {})
  );

  const _handleStart = () => {
    const step = managedArea.current_manual_step;
    if (step)
      history.push(`${location.pathname}/${steps[step]}/${selectedTree?.id || ''}`.replace('//', '/'));
  };

  const _handleEntryClick = (entry, index) =>
    !selection[1] && history.push(`/validation/${entry.id}`);
  const _handleUnselect = () => history.push(`/validation`);

  const _handleAdd = (e) => onTreeAdd(e, selection[0]);

  const controls = [
    {
      id: 'map-controller',
      label: 'Basic',
      component: (
        <MapControls
          layers={filterLayers}
          layersVisible={layerVisible}
          onLayerVisibleChange={setLayerVisible}
          showLayerFilters
          sources={sources}
          sourcesVisible={sourceVisible}
          onSourceVisibleChange={setSourceVisible}
          showSourceFilters
          showViewOptions
          isSatellite={isSatellite}
          setIsSatellite={setIsSatellite}
          isPanoramaVisible={isPanoramaVisible}
          setPanoramaVisible={setPanoramaVisible}
          maSearch={maSearch}
          setMASearch={setMASearch}
        />
      ),
    },
    // {
    //   id: 'info',
    //   label: 'Info',
    //   disabled: !selectedMA && !selectedTree,
    //   component: <Info content={infoContent} />,
    // },
  ];

  useEffect(() => {
    focusOnMA?.();
  }, []);

  const _handleTreeRemove = (tree) => {
    // ({ id }) => id && updateTree(id, { status: 'deleted' }),
    presentAlert({
      title: 'Confirm delete',
      subtitle: 'This action can not be undone',
      actions: [
        {
          label: 'Cancel',
          onClick: dismissAlert,
        },
        {
          label: 'Confirm',
          destructive: true,
          onClick: () => {
            dismissAlert();
            onDelete(tree?.id);
            updateTree(tree.id, { status: 'deleted' });
          },
        },
      ],
    });
  };

  const isActionAvailable =
    managedArea &&
    Object.keys(steps).includes(managedArea?.current_manual_step) &&
    !(
      managedArea.location_proposal === 'error' ||
      managedArea.semantic_extraction === 'error' ||
      managedArea.location_proposal === 'todo' ||
      managedArea.semantic_extraction === 'todo' ||
      managedArea.location_proposal === 'started' ||
      managedArea.semantic_extraction === 'started'
    );

  useEffect(() => {
    setCurrentTreeId(null);
    const search = window.location.search;
    const searchParams = new URLSearchParams(search.slice(1, search.length));
    const treeId = searchParams.get('tree');
    if (!trees.length || !treeId) return;
    const tree = trees.find(tree => tree.id === treeId);
    console.log('TREE', tree);
    if (tree) {
      setTimeout(() => {
        mapRef.current?.focusOnPoint(tree?.geometry?.coordinates || [], 16);
        setSelectedTree(tree);
      }, 500)
    }
  }, [trees.length])
  
  const ma = pipelines.find(pipeline => pipeline.managed_area.toLowerCase() === maSearch.toLowerCase());
  useEffect(() => {
    if (ma) history.push(`/validation/${ma.id}`)
  }, [ma, history]);

  const _handleTreeEntryClick = (entry, index) => {
    setSelectedTree(entry);

    const centre = { lat: entry.location.coordinates[1], lng: entry.location.coordinates[0] };
    const feature = { id: entry.id, properties: entry };
    mapRef.current?.showPopup(entry.status, centre, feature);
    mapRef.current?.focusOnPoint(entry?.location?.coordinates || [], 20);
  }

  return (
    <Grid
      defaultLayout={{ x: 0.0, y: 0.0 }}
      tabcontent={<Controls tabs={controls} />}
      infocontent={
        <Table
          key='MAP'
          data={(selection[1] ? trees : pipelines) || []}
          config={generateMapConfig(selection[1], _handleTreeRemove, maSearch, selectedTree)}
          onClick={selection[1] ? _handleTreeEntryClick : _handleEntryClick}
        />
      }
    >
      <div className='validation-overview-wrapper'>
        {isPanoramaVisible && selectedTree && (
          <div className='panorama-map-container'>
            <PanoramicView
              background={isDark ? 0x000000 : 0xf8f8f8}
              capturePoint={capturePoint}
              images={panoramaImages || []}
              cameras={panoramaImages || []}
              onTreeClick={_handleTreeSelect}
              getConfig={getConfig}
            />
          </div>
        )}
        <Map
          key='validationMap'
          _ref={mapRef}
          sources={sources}
          sourceVisible={sourceVisible}
          layers={layers}
          onUnselect={selection[1] && _handleUnselect}
          layerVisible={{
            ...layerVisible,
            mas: layerVisible.mas && sourceVisible.mas,
            maven: layerVisible.maven && sourceVisible.trees,
            sent_to_field: layerVisible.sent_to_field && sourceVisible.trees,
            location_validation_todo:
              layerVisible.location_validation_todo && sourceVisible.trees,
            location_validation_done:
              layerVisible.location_validation_done && sourceVisible.trees,
            semantic_validation_todo:
              layerVisible.semantic_validation_todo && sourceVisible.trees,
            semantic_validation_done:
              layerVisible.semantic_validation_done && sourceVisible.trees,
            db_match_todo: layerVisible.db_match_todo && sourceVisible.trees,
            completed: layerVisible.completed && sourceVisible.trees,
          }}
          active={{
            mas: selectedMA,
            ...filterLayers.reduce(
              (prev, { id }) => ({ ...prev, [id]: selectedTree?.id }),
              {}
            ),
          }}
          satellite={isSatellite}
          onAdd={
            selection[0]?.current_manual_step === 'location_validation' &&
            _handleAdd
          }
          onFocusReset={resetFocus}
          action={
            isActionAvailable && {
              label: 'Start',
              primary: true,
              onClick: _handleStart,
            }
          }
        />
      </div>
    </Grid>
  );
};

export default memo(withRouter(ValidationMap));
