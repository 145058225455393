import React, { useState } from 'react'
import { Canvas, extend, useThree } from 'react-three-fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { voxelJSON } from '../../core/dummy';
import Trunk from './Trunk'
import Crown from './Crown'

extend({ OrbitControls })

const Scene = ({ data, ...props }) => {
  const {
    camera,
    gl: { domElement },
  } = useThree()

  return (
    <>
      <Trunk data={data.B_VOXELS} {...props} />
      <Crown data={data.C_VOXELS} {...props} />
      <orbitControls
        args={[camera, domElement]}
        enablePan={false}
        enableRotate={false}
      />
    </>
  )
}

const VoxelXML = ({ xml = voxelJSON, backgroundColor }) => {
  const [pressed, setPressed] = useState(false)
  const [movement, setMovement] = useState({ x: 0, y: 0 })

  return (
    <Canvas
      camera={{ position: [0, 0, 150] }}
      onPointerDown={() => setPressed(true)}
      onPointerUp={() => setPressed(false)}
      onPointerMove={e => pressed && setMovement({ x: e.movementX / 10, y: e.movementY / 10 })}
      onCreated={({ gl }) => gl.setClearColor(backgroundColor || 'white')}>
      <ambientLight />
      <pointLight position={[150, 150, 150]} intensity={0.55} />
      <Scene
        data={xml.TREE_PARA.GROWTH_SPACE}
        pressed={pressed}
        movement={movement}
      />
    </Canvas>
  )
}

export default VoxelXML
