import React, { Fragment } from 'react'
import * as THREE from 'three';
import { LoaderWrapper } from '../../layout';

import MovableTree from './MovableTree';

const TreeMover = ({ position, onPositionChange = () => {}, pointcloud = {}, background, treeLoaded = false, isNew = false, error = false }) => {
  const _handlePositionSet = (position) => onPositionChange(position.map((pos, i) => pos + (pointcloud?.pc?.mins?.[i] || 0)))

  const pos = new THREE.Vector3(
    position[0] - (pointcloud?.pc?.mins?.[0] || 0),
    position[1] - (pointcloud?.pc?.mins?.[1] || 0),
    position[2] - (pointcloud?.pc?.mins?.[2] || 0),
  );

  return (
    <LoaderWrapper loading={treeLoaded && pointcloud !== false && !pointcloud && !error}>
      <div className="tree-mover-wrapper">
        <MovableTree 
          position={pos}
          background={background}
          setPosition={_handlePositionSet}
          pointcloud={pointcloud}
          isNew={isNew}
          error={error}
        />
        <MovableTree 
          position={pos}
          background={background}
          setPosition={_handlePositionSet}
          pointcloud={pointcloud}
          isNew={isNew}
          // error={error}
          isUp
        />
      </div>
    </LoaderWrapper>
  )
}

export default TreeMover;
