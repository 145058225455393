export const STYLES = {
  dark: 'mapbox://styles/mateh/ckgs2g6pe0swg19o10t7w1qpp',
  light: 'mapbox://styles/mapbox/light-v10',
  satellite: 'mapbox://styles/mapbox/satellite-streets-v11',
  elevated: 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y',
}

export const GEOM_COLUMNS = {
  'managed_areas': 'frontend_aoi',
  'trees': 'location',
  'maven_trees': 'frontend_geom',
  'tiles': 'boundary',
}

export const BOUNDS = {
  'managed_areas': [10, 20],
  'trees': [15, 40],
  'maven_trees': [15, 40],
  'tiles': [12, 20],
}

export const COLUMNS = {
  'managed_areas': 'code',
  'trees': 'id,status,girth_1_0m,location_confidence,height,comment',
  'maven_trees': 'id,eve_id,height,grth_size,spsc_nm,common_nm,tree_id',
  'tiles': 'id',
}

export const ID_COLUMNS = {
  'managed_areas': 'id',
  'trees': 'id',
  'maven_trees': 'id',
  'tiles': 'id'
}
